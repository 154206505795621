import React from 'react';

import CardModal from '../../Components/CardModal';
import { useTranslation } from '@hooks';
import Label from '@components/Label';
import moment from 'moment';

const AlertModal = ({ data = {}, okCallback, unidentifiedCount, ...restProps }) => {
    const t = useTranslation('AlertModal');

    return (
        <CardModal
            header={{
                title: (
                    <div className="position-relative">
                        <div className="pnt-txt txt-bold h5-n text-center">{t('Worker Emergency Notification')}</div>
                        <div className="alert-modal-unidentified">
                            <div className="icon-alert icon-filter-red" style={{ width: '20px' }} />
                            <div className="text-danger font-weight-bolder font-size-lg">{unidentifiedCount}</div>
                        </div>
                    </div>
                ),
            }}
            bodyClassName="pt-1"
            buttonClassName="justify-content-center"
            okButton={{
                text: t('Action'),
                className: 'btn-brand btn-icon px-5',
            }}
            cancelButton={{
                text: t('Action Later'),
                className: 'btn-gray btn-icon px-5',
            }}
            okCallback={() => okCallback(data?.logNum)}
            {...restProps}
        >
            <div className="pt-0 pb-3 text-center">{t('Worker emergency has been detected.')}</div>
            <div className="pnt-txt txt-bold h6">{`[${t('Outbreak Information')}]`}</div>
            <div className="flx-row border-top pt-2">
                <div className="flx-col flx-1">
                    <Label
                        name={t('Name') + ': '}
                        value={data?.targetName}
                        labelValueClassName="flx-row txt-bold min-w-none"
                    />
                    <Label
                        name={t('Company') + ': '}
                        value={data?.companyName}
                        labelValueClassName="flx-row txt-bold min-w-none"
                    />
                    <Label
                        name={t('Phone') + ': '}
                        value={data?.phoneNumber}
                        labelValueClassName="flx-row txt-bold min-w-none"
                    />
                </div>
                <div className="flx-col flx-1">
                    <Label
                        name={t('Location') + ': '}
                        value={<div className="color-danger font-size-md">{data?.fcName}</div>}
                        labelValueClassName="flx-row txt-bold min-w-none"
                    />
                    <Label
                        name={t('Status') + ': '}
                        value={
                            <div className="color-danger font-size-md">
                                {t(data?.interfaceCommandType, 'WorkerStatusColumn')}
                            </div>
                        }
                        labelValueClassName="flx-row txt-bold min-w-none"
                    />
                    <Label
                        name={t('Date and Time') + ': '}
                        value={
                            <div className="font-size-md">
                                {data?.regDate ? moment(data.regDate * 1000).format('YYYY-MM-DD HH:mm:ss') : null}
                            </div>
                        }
                        labelValueClassName="flx-row txt-bold min-w-none"
                    />
                </div>
            </div>
        </CardModal>
    );
};

export default AlertModal;
