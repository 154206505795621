import React, { useEffect, useRef, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useAsync } from '@hooks';
import { fetchAlertInfo } from '@api/alert';
import {
    createAlertDescription,
    fetchCurrentStatistics,
    fetchLogNotificationEmptyDescription,
} from '@api/smartSafeWorker';

import AlertModal from 'Components/MainPages/WorkerSafetyDashboard/Components/AlertModal';
import ActionModal from 'Components/MainPages/Components/ActionModal';
import { WorkStatusDispatchContext } from 'Components/MainPages/WorkerSafetyDashboard';
import { setFloorInfo, setTotalStatus } from 'Components/MainPages/WorkerSafetyDashboard/workerStatusReducer';
import { setSosAlert } from '@reducer/Notification';

import Overview from './Components/Overview';
import WorkerStatusList from './Components/WorkerStatusList';
import WorkerStatusMap from './Components/WorkerStatusMap';
import StatusByFloor from './Components/StatusByFloor';

const INTERVAL_TIME = 5 * 1000;

const IndoorCoalStorageSiteStatus = () => {
    const intervalRef = useRef(null);

    const dispatch = useContext(WorkStatusDispatchContext);
    const storeDispatch = useDispatch();
    const { sosAlert, alarmProcessed } = useSelector(state => state.Notification);

    const [notificationList, setNotificationList] = useState([]);
    const [actionModalData, setActionModalData] = useState({});

    const [actionModal, setActionModal] = useState(false);
    const toggleActionModal = () => {
        setActionModal(!actionModal);
    };

    useAsync({
        promise: fetchLogNotificationEmptyDescription,
        immediate: true,
        resolve: res => {
            setNotificationList(res);
        },
        reject: err => {
            console.error(err);
        },
    });

    const { promise: getAlertInfo } = useAsync({
        promise: fetchAlertInfo,
        resolve: res => {
            if (!!res?.rows.length) {
                const currentAlert = res.rows[0];
                setNotificationList(prev => {
                    return [currentAlert, ...prev];
                });
            }
        },
    });

    const { promise: getFloorWorker } = useAsync({
        promise: fetchCurrentStatistics,
        resolve: res => {
            // 이름 역순으로 정렬
            const { floorWorkersInGeofences, ...totalStatus } = res;
            dispatch(
                setFloorInfo(
                    floorWorkersInGeofences.sort((a, b) => {
                        const first = Object.keys(b)[0];
                        const second = Object.keys(a)[0];
                        if (first < second) {
                            return -1;
                        }
                        if (first > second) {
                            return 1;
                        }

                        return 0;
                    }),
                ),
            );
            dispatch(setTotalStatus(totalStatus));
        },
        reject: err => {
            console.error(err);
        },
    });

    const { promise: postAlertDescription } = useAsync({
        promise: createAlertDescription,
        resolve: res => {
            const { logNum } = res;
            setNotificationList(prev => {
                return prev.filter(notification => notification.logNum !== logNum);
            });
        },
    });

    useEffect(() => {
        getFloorWorker();

        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            getFloorWorker();
        }, INTERVAL_TIME);
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        const { logNum } = sosAlert;
        if (logNum) {
            getAlertInfo({
                logNum,
            });
        }
        return () => {
            storeDispatch(setSosAlert({}));
        };
    }, [sosAlert.logNum]);

    const alertModalOkCallback = logNum => {
        toggleActionModal();
        setActionModalData(notificationList.find(notification => notification.logNum === logNum));
    };

    const actionModalOkCallback = message => {
        postAlertDescription({
            logNum: actionModalData.logNum,
            description: message,
        });
        toggleActionModal();
    };

    // socket으로 완료 들어오면 알람 끄기
    useEffect(() => {
        if (typeof alarmProcessed === 'object') {
            setNotificationList(prev => {
                return prev.filter(notification => notification.logNum !== alarmProcessed.logNum);
            });
        }
    }, [alarmProcessed]);

    return (
        <>
            <div className="work-status">
                <Overview />
                <WorkerStatusList />
                <WorkerStatusMap />
                <StatusByFloor />
            </div>
            <AlertModal
                data={notificationList[0]}
                unidentifiedCount={notificationList.length}
                initModal={!!notificationList.length}
                okCallback={alertModalOkCallback}
                removeCancel
                popupTop
            />
            <ActionModal
                data={actionModalData}
                initModal={actionModal}
                okCallback={actionModalOkCallback}
                removeCancel
            />
        </>
    );
};

export default IndoorCoalStorageSiteStatus;
