import React from 'react';
import { Col, Row } from 'reactstrap';

import { Card } from '@components';
import useTranslation from '@hooks/useTranslation';

import PersonnelOverview from './Components/PersonnelOverview';
import SafetyOverview from './Components/SafetyOverview';
import EnclosedOverview from './Components/EnclosedOverview';

const Overview = () => {
    const t = useTranslation('Work Status');

    return (
        <Card
            className={'overview'}
            header={{
                title: t('Overview'),
            }}
        >
            <Row xl={12}>
                <Col xl={4}>
                    <PersonnelOverview />
                </Col>
                <Col xl={4}>
                    <EnclosedOverview />
                </Col>
                <Col xl={4}>
                    <SafetyOverview />
                </Col>
            </Row>
        </Card>
    );
};

export default Overview;
