import React, { useContext } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import useTranslation from '@hooks/useTranslation';

import { nullToZero } from 'Components/MainPages/util/common/util';
import { WorkStatusContext } from 'Components/MainPages/WorkerSafetyDashboard';

import workerImg from 'assets/images/worker.png';
import { makeTooltipStyles, Wrapper, ImageSurface } from './styled';

const PersonnelOverview = () => {
    const t = useTranslation('Overview');
    const {
        totalStatus: { currentWorkerCount, mappingWorkerCount },
    } = useContext(WorkStatusContext);

    return (
        <Wrapper>
            <div className={'img-container'}>
                <div className={'recharts-surface'}>
                    <ImageSurface img={workerImg} />
                </div>
            </div>
            <div className="pl-0 ml-2 status-container">
                <div className="pnt-txt txt-border txt-bold p-1 title-plate">{t('Status of workers')}</div>
                <div className={'flx-row'}>
                    <div className="d-flex flx-col align-items-center gap-0">
                        <span className={'txt-overview-main'}>{nullToZero(currentWorkerCount)}</span>
                        <div className={'txt-overview-sub'} id={'working'}>
                            {t('Worker Count')}
                        </div>
                        <UncontrolledTooltip
                            hideArrow={true}
                            style={makeTooltipStyles({ textAlign: 'left' })}
                            target={'working'}
                            placement={'top'}
                        >
                            {t('Worker Count')}
                        </UncontrolledTooltip>
                    </div>
                    <div className="txt-slash">/</div>
                    <div className="d-flex flx-col align-items-center gap-0">
                        <span className={'txt-overview-main'}>{nullToZero(mappingWorkerCount)}</span>
                        <div className={'txt-overview-sub'} id={'enteredWorkers'}>
                            {t('Entered Workers')}
                        </div>
                        <UncontrolledTooltip
                            hideArrow={true}
                            style={makeTooltipStyles({ textAlign: 'left' })}
                            target={'enteredWorkers'}
                            placement={'top'}
                        >
                            {t('Entered Workers')}
                        </UncontrolledTooltip>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default PersonnelOverview;
