import React, { useState, useRef, useEffect } from 'react';

import { Input } from 'reactstrap';

import { Label, ConfirmModal } from '@components';
import { useTranslation } from '@hooks';
import CardModal from './CardModal';
import moment from 'moment';

const ActionModal = ({ initModal, okCallback, callbackParam, cancelCallback, data = {}, ...restProps }) => {
    const t = useTranslation('ActionModal');

    const inputRef = useRef(null);

    const [validationFailModal, setValidationFailModal] = useState(false);

    const handleValidationFailModal = () => {
        setValidationFailModal(prev => !prev);
    };

    const [contents, setContents] = useState('');
    const handleContentsChange = event => {
        const { value } = event.target;
        if (value.length <= 255) {
            setContents(value);
        }
    };

    const handleOkCallback = param => {
        if (!contents.trim()) {
            handleValidationFailModal();
            return;
        }
        if (typeof okCallback === 'function') {
            okCallback(param);
        }
    };
    const handleCancelCallback = param => {
        if (data?.description) {
            setContents(data.description);
        } else {
            setContents('');
        }
        cancelCallback(param);
    };

    useEffect(() => {
        if (data?.description) {
            setContents(data.description);
        } else {
            setContents('');
        }
    }, [data]);

    useEffect(() => {
        if (initModal) {
            inputRef.current.focus();
        }
    }, [initModal]);

    return (
        <>
            <CardModal
                initModal={initModal}
                header={{
                    title: <div className="pnt-txt txt-bold h5-n">{t('Action')}</div>,
                }}
                okButton={{
                    text: data && data.action ? t('Modify', 'Button') : t('Ok', 'Button'),
                    icon: 'icon-check',
                    className: `${data && data.action ? 'btn-secondary' : 'btn-brand'} btn-icon`,
                }}
                okCallback={handleOkCallback}
                callbackParam={callbackParam ? callbackParam : contents}
                cancelCallback={handleCancelCallback}
                cancelButton={{
                    text: t('Cancel', 'Button'),
                    icon: 'icon-close',
                    className: 'btn-gray btn-icon',
                }}
                {...restProps}
            >
                <div className="flx-col">
                    <div className="d-flex">
                        <Label
                            name={`${t('Worker Name')}: `}
                            value={data?.targetName}
                            labelGroupClassName="flx-1"
                            labelValueClassName="label-dot min-w-none"
                        />
                        <Label
                            name={`${t('Company')}: `}
                            value={data?.companyName}
                            labelGroupClassName="flx-1 w-100"
                            labelValueClassName="label-dot min-w-none"
                        />
                    </div>
                    <div className="d-flex pb-2">
                        <Label
                            name={`${t('Status')}: `}
                            value={
                                <div className="color-danger">
                                    {data?.interfaceCommandType
                                        ? t(data?.interfaceCommandType, 'WorkerStatusColumn')
                                        : null}
                                </div>
                            }
                            labelGroupClassName="flx-1 w-100"
                            labelValueClassName="label-dot min-w-none"
                        />
                        <Label
                            name={`${t('Date and Time')}: `}
                            value={data?.regDate ? moment(data.regDate * 1000).format('YYYY-MM-DD HH:mm:ss') : null}
                            labelGroupClassName="flx-1 w-100"
                            labelValueClassName="label-dot min-w-none"
                        />
                    </div>
                    <Label name={t('Contents Input')} labelValueClassName="label-dot txt-bold" />
                    <div className="input-textarea">
                        <Input
                            innerRef={inputRef}
                            type="textarea"
                            name="contents"
                            id="contents"
                            value={contents}
                            className={'checkbox-container'}
                            style={{ height: '10rem', resize: 'none' }}
                            onChange={handleContentsChange}
                        />
                    </div>
                </div>
            </CardModal>
            <ConfirmModal
                header={{ title: t('Notification') }}
                confirmText={t('Please enter the action details.')}
                initModal={validationFailModal}
                toggleModal={handleValidationFailModal}
                removeCancel
            />
        </>
    );
};

export default ActionModal;
