import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import L from 'leaflet';
import { FeatureGroup, Marker, Popup } from 'react-leaflet';

import cx from 'classnames';

import { useAsync, useTranslation } from '@hooks';
import { fetchFloorGeofence } from '@api/smartSafeWorker';
import { Button, Card, Checkbox, AniMarker, Map, RotatedImageOverlay, Label, Select as ReactSelect } from '@components';

import FloorSelect from 'Components/MainPages/Components/FloorSelect';
import SelectedWorker from './Components/SelectedWorker';
import GeofenceLayer from './Components/GeofenceLayer';

import {
    setSelectedFloor,
    clearFacilityAndFloor,
} from 'Components/MainPages/WorkerSafetyDashboard/workerStatusReducer';
import { WorkStatusContext, WorkStatusDispatchContext } from 'Components/MainPages/WorkerSafetyDashboard';

const INTERVAL_TIME = 5 * 1000;
const ONE_MINUTE = 60 * 1000;

const WorkerStatusMap = ({ monitoring }) => {
    const t = useTranslation('Work Status');

    const [rotationMinute, setRotationMinute] = useState(5);

    const { selectedFloor, selectedWorkArea, equipmentList, selectedWorker, peopleRealTimeLog } = useContext(
        WorkStatusContext,
    );

    const history = useHistory();
    const dispatch = useContext(WorkStatusDispatchContext);
    const { smartSafeFloorList } = useSelector(state => state.FloorInfo);

    const mapRef = useRef(null);
    const popupCloseRef = useRef(null);
    const intervalRef = useRef(null);
    const floorRotationRef = useRef(null);

    const [showGeofence, setShowGeofence] = useState(true);
    const [zoom, setZoom] = useState(null);
    const [selectedGeofenceOrFacility, setSelectedGeofenceOrFacility] = useState(null);
    const [selectedGeofenceList, setSelectedGeofenceList] = useState([]);

    const { promise: getFloorGeofence } = useAsync({
        promise: fetchFloorGeofence,
        resolve: res => {
            const { floorId, geofencingTargets, floorRotationMinute } = res;
            setRotationMinute(floorRotationMinute);
            if (floorId === selectedFloor.floorId) {
                setSelectedGeofenceList(geofencingTargets);
            }
        },
    });

    // 다른층을 선택했을 때 구역명 삭제
    useEffect(() => {
        if (selectedGeofenceOrFacility && selectedGeofenceList) {
            const foundFc = selectedGeofenceList.find(fc => {
                return fc.fcName === selectedGeofenceOrFacility.fcName;
            });
            if (!foundFc) {
                setSelectedGeofenceOrFacility(null);
            }
        }
    }, [selectedGeofenceList, selectedGeofenceOrFacility]);

    useEffect(() => {
        if (!selectedFloor.floorId) {
            return;
        }
        const { floorId } = selectedFloor;

        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        getFloorGeofence({ floorId });
        intervalRef.current = setInterval(() => {
            getFloorGeofence({ floorId });
        }, INTERVAL_TIME);

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, [selectedFloor.floorId]);

    // 층 로테이션 로직
    useEffect(() => {
        if (monitoring && smartSafeFloorList.length) {
            if (smartSafeFloorList.length) {
                if (floorRotationRef.current) {
                    clearInterval(floorRotationRef.current);
                }
                floorRotationRef.current = setInterval(() => {
                    let idx = smartSafeFloorList.findIndex(floor => floor.floorId === selectedFloor.floorId) + 1;
                    if (idx >= smartSafeFloorList.length) {
                        idx = 0;
                    }
                    dispatch(setSelectedFloor(smartSafeFloorList[idx]));
                }, rotationMinute * ONE_MINUTE);
            }
        }
        return () => {
            if (floorRotationRef.current) {
                clearInterval(floorRotationRef.current);
                floorRotationRef.current = null;
            }
        };
    }, [monitoring, selectedFloor, smartSafeFloorList, rotationMinute]);

    useEffect(() => {
        if (smartSafeFloorList.length > 0) {
            dispatch(setSelectedFloor(smartSafeFloorList[0]));
        } else {
            dispatch(clearFacilityAndFloor());
        }
    }, [smartSafeFloorList]);

    //선택된 시설 또는 작업구역으로 맵 뷰 이동
    useEffect(() => {
        const map = mapRef.current.leafletElement;

        if (selectedWorkArea && selectedWorkArea.llList) {
            map.fitBounds(selectedWorkArea.llList);
        } else {
            map.eachLayer(layer => {
                if (layer instanceof L.ImageOverlay) {
                    map.fitBounds(layer.getBounds());
                    return false;
                }
            });
        }
    }, [selectedWorkArea]);

    useEffect(() => {
        const map = mapRef.current.leafletElement;

        if (selectedGeofenceOrFacility) {
            const geofence = selectedGeofenceOrFacility.geofenceLatLngs;
            map.fitBounds(geofence);
        } else {
            map.eachLayer(layer => {
                if (layer instanceof L.ImageOverlay) {
                    map.fitBounds(layer.getBounds());
                    return false;
                }
            });
        }
    }, [selectedGeofenceOrFacility]);

    const handleSearchResultClick = selected => {
        if (selected) {
            setSelectedGeofenceOrFacility(selected);
        } else {
            setSelectedGeofenceOrFacility(null);
        }
    };

    const closePopup = () => {
        if (!popupCloseRef.current) return;

        popupCloseRef.current.leafletElement._close();
    };

    return (
        <Card bodyClassName="p-1" className="map-container worker-status-map">
            <Map
                tile={false}
                style={{ backgroundColor: '#C6C6E7' }}
                onZoomend={e => {
                    setZoom(e.target.getZoom());
                }}
                ref={mapRef}
                closePopupOnClick={false}
            >
                <div
                    className={cx(monitoring && 'd-none', 'flx-row gap-1')}
                    style={{ flexWrap: 'wrap', margin: '8px 41px 8px 8px' }}
                >
                    <div style={{ width: '10rem' }}>
                        <FloorSelect
                            styles={{ customControlStyles: { zIndex: 10 } }}
                            value={selectedFloor && selectedFloor.floorId}
                            onChange={selected => dispatch(setSelectedFloor(selected))}
                            disabled={!smartSafeFloorList.length}
                        />
                    </div>

                    {!monitoring && (
                        <>
                            <div className={'checkbox-container'} style={{ zIndex: 10 }}>
                                <Checkbox
                                    className={'form-h-small'}
                                    id={'workAreView'}
                                    name={t('Work Area View')}
                                    checked={showGeofence}
                                    handleChecked={() => setShowGeofence(!showGeofence)}
                                    disabled={!smartSafeFloorList.length}
                                />
                            </div>

                            <div style={{ width: '18rem' }}>
                                <ReactSelect
                                    valueKey={'fcName'}
                                    labelKey={'fcName'}
                                    name={'facilityAndGeofence'}
                                    value={selectedGeofenceOrFacility}
                                    options={selectedGeofenceList}
                                    onChange={handleSearchResultClick}
                                    isSearchable={true}
                                    isClearable={true}
                                    placeholder={t("Enter 'Zone'", 'FloorStatus')}
                                    customControlStyles={{ width: '100%', zIndex: 10 }}
                                    customOptionStyles={{ width: '100%', zIndex: 10 }}
                                    customMenuStyles={{ width: '100%' }}
                                    disabled={!smartSafeFloorList.length}
                                />
                            </div>
                        </>
                    )}
                </div>

                {smartSafeFloorList.length ? (
                    <>
                        {selectedFloor && selectedFloor.imgURL && (
                            <RotatedImageOverlay
                                key={selectedFloor.floorId}
                                url={selectedFloor.imgURL}
                                deg={selectedFloor.deg}
                                bounds={[
                                    [selectedFloor.neLat, selectedFloor.neLng],
                                    [selectedFloor.swLat, selectedFloor.swLng],
                                ]}
                                onLoad={e => {
                                    const { target: layer } = e;
                                    layer._map.fitBounds(layer.getBounds());
                                }}
                            />
                        )}

                        {(monitoring || showGeofence) && <GeofenceLayer geofenceList={selectedGeofenceList} />}

                        <FeatureGroup>
                            {peopleRealTimeLog &&
                                peopleRealTimeLog.map(log => {
                                    let className = '';
                                    if (
                                        (log.states['SOS'].level && log.states['SOS'].level !== 'NORMAL') ||
                                        (log.states['MAN_DOWN'].level && log.states['MAN_DOWN'].level !== 'NORMAL') ||
                                        (log.states['BIOSIGNAL_ABNORMALITY'].level &&
                                            log.states['BIOSIGNAL_ABNORMALITY'].level !== 'NORMAL')
                                    ) {
                                        className = 'sos';
                                    } else if (
                                        log.states['RTLS_ERROR'].level !== 'NORMAL' ||
                                        log.states['BEACON_ERROR'].level !== 'NORMAL' ||
                                        log.states['SENSING_ERROR'].level !== 'NORMAL'
                                    ) {
                                        className = 'equipment';
                                    }
                                    return (
                                        <AniMarker
                                            key={log.target.targetNum}
                                            targetInfo={log}
                                            zoom={zoom}
                                            onClick={() => {
                                                if (
                                                    !selectedWorker ||
                                                    log.target.targetNum !== history.location.pathname.split('/')[2]
                                                ) {
                                                    history.push(`/dashboard/status/${log.target.targetNum}`);
                                                }
                                            }}
                                            className={className}
                                        >
                                            <Popup ref={popupCloseRef} getOptions={{ closeOnClick: false }}>
                                                <Label
                                                    name={`${t('Name')}:`}
                                                    value={log.target.targetName}
                                                    labelGroupClassName={'label-height-sm'}
                                                    labelValueClassName={'label-dot color-gray'}
                                                />
                                                <Label
                                                    name={`${t('Mobile')}:`}
                                                    value={log.target.phoneNum}
                                                    labelGroupClassName={'label-height-sm'}
                                                    labelValueClassName={'label-dot color-gray'}
                                                />
                                                <Label
                                                    name={`${t('Unit')}:`}
                                                    value={log.target.workerComName}
                                                    labelGroupClassName={'label-height-sm'}
                                                    labelValueClassName={'label-dot  color-gray'}
                                                />
                                                {log.sensorItems &&
                                                    log.sensorItems.map(({ sensingType, value, measurementUnit }) => {
                                                        return (
                                                            <Label
                                                                name={`${t(sensingType, 'RealTimeSensorMonitoring')}:`}
                                                                value={`${value}${measurementUnit}`}
                                                                labelGroupClassName={'label-height-sm'}
                                                                labelValueClassName={'label-dot color-gray'}
                                                            />
                                                        );
                                                    })}
                                                <div className={'flx-row'}>
                                                    <Button
                                                        className={'btn-icon-only btn-lightgray w-10 mr-1'}
                                                        iconClassName={'icon-close'}
                                                        onClick={closePopup}
                                                    />
                                                </div>
                                            </Popup>
                                        </AniMarker>
                                    );
                                })}
                        </FeatureGroup>

                        <FeatureGroup>
                            {equipmentList.map(equipment => {
                                let imgStr = `<img class="marker-img" src="${equipment.imgUrl}">`;
                                if (!equipment.imgUrl) {
                                    imgStr = '<div class="marker-img replace-img"><div></div></div>';
                                }

                                return (
                                    <Marker
                                        key={equipment.facilityNum}
                                        position={[equipment.lat, equipment.lng]}
                                        icon={L.divIcon({
                                            className: 'category-marker',
                                            html: `${imgStr}<div class="marker-label">${equipment.facilityName}</div>`,
                                        })}
                                    />
                                );
                            })}
                        </FeatureGroup>

                        {!!selectedWorker && !monitoring && <SelectedWorker />}
                    </>
                ) : (
                    <div className={'map-custom'}>
                        <div className={'pnt-txt preview-txt'}>
                            {t('There are no registered floor of the worksite.')}
                        </div>
                        <div className={'pnt-txt preview-txt'}>
                            {t('Please register the floor first for the worksite')}
                        </div>
                    </div>
                )}
            </Map>
        </Card>
    );
};

export default WorkerStatusMap;
