import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownToggle, DropdownMenu, Col, Row, UncontrolledButtonDropdown, Container } from 'reactstrap';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logOut } from '../../../../reducers/UserInfo';
import useAsync from '../../../../util/hooks/useAsync';
import styled from 'styled-components';
import { fetchUserInfo } from '../../../../api/login';
import userImg from '../../../../assets/images/unknown_user.png';
import { useTranslation } from 'react-i18next';
import Button from '../../../Common/Button';
import background from '../../../../assets/images/background_nav.webp';
import ProfileModal from './UserBoxPopup/ProfileModal';
import AppSettingModal from './UserBoxPopup/AppSettingModal';
import LanguageSelectModal from './UserBoxPopup/LanguageSelectModal';
import { getUncheckedAlarmCountApi } from '../../../../api/smartSafeWorkManagement';
import LogoutModal from './UserBoxPopup/LogoutModal';
import { deleteUnmappingDevicesApi } from '../../../../api/asset';
import ConfirmModal from '../../../Common/ConfirmModal';
import { fetchNumOfUnreadAlert } from '../../../../api/alert';

export const ModalTitle = styled.span`
    font-weight: bold;
    font-size: 1rem;
`;

const UserBox = () => {
    const dispatch = useDispatch();
    const { languageOption } = useSelector(state => state.AppInfo);
    const { t } = useTranslation();
    const [unreadAlert, setUnreadAlert] = useState();
    const [userInfo, setUserInfo] = useState({});

    const [profileModal, setProfileModal] = useState(false);
    const toggleProfileModal = () => {
        setProfileModal(!profileModal);
    };

    const [appSettingModal, setAppSettingModal] = useState(false);
    const toggleAppSettingModal = () => {
        setAppSettingModal(!appSettingModal);
    };

    const [languageSelectModal, setLanguageSelectModal] = useState(false);
    const toggleLanguageSelectModal = () => {
        setLanguageSelectModal(!languageSelectModal);
    };

    const [logoutModal, setLogoutModal] = useState(false);
    const toggleLogoutModal = () => {
        setLogoutModal(!logoutModal);
    };

    const [unmappingModal, setUnmappingModal] = useState(false);
    const toggleUnmappingModal = () => {
        setUnmappingModal(!unmappingModal);
    };

    const [unmappingResultModal, setUnmappingResultModal] = useState({
        success: false,
        fail: false,
    });
    const toggleUnmappingSuccessModal = () => {
        setUnmappingResultModal({
            success: !unmappingResultModal.success,
            fail: false,
        });
    };
    const toggleUnmappingFailModal = () => {
        setUnmappingResultModal({
            success: false,
            fail: !unmappingResultModal.fail,
        });
    };

    const handleLogout = useCallback(() => {
        dispatch(logOut());
        // dispatch(setOauthInfo({ oAuthInfo: {} }));
    }, []);

    useEffect(() => {
        getUserInfo();
    }, []);

    const { promise: getNumOfUnreadAlert } = useAsync({
        promise: fetchNumOfUnreadAlert,
        resolve: res => {
            if (typeof res === 'object') {
                setUnreadAlert(res.count);
            } else {
                setUnreadAlert(res);
            }
        },
        reject: error => {
            console.log(error);
        },
    });

    const { promise: getUserInfo } = useAsync({
        promise: fetchUserInfo,
        resolve: ({ userID, userName, userEmail }) => {
            setUserInfo({ userID, userName, userEmail });
        },
        reject: error => {
            console.log(error);
        },
    });

    const { promise: deleteUnmappingDevices } = useAsync({
        promise: deleteUnmappingDevicesApi,
        resolve: () => toggleUnmappingSuccessModal(),
        reject: () => toggleUnmappingFailModal(),
    });

    const handleClick = () => {
        getNumOfUnreadAlert();
    };

    return (
        <>
            <div className="header-btn-lg ml-3 pl-1">
                <div className="widget-content">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left">
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color="link" className="p-0 border-0" onClick={handleClick}>
                                    <img width={28} className="rounded-circle" src={userImg} alt="" />
                                    <div className="flx-col gap-0">
                                        <div className="widget-heading">{userInfo.userName}</div>
                                        {/*<div className="widget-sub-heading">manager</div>*/}
                                    </div>
                                    <FontAwesomeIcon className="widget-btn ml-2 opacity-8" icon={faAngleDown} />
                                </DropdownToggle>
                                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                                    <div className="dropdown-menu-header">
                                        <div className="dropdown-menu-header-inner p-0">
                                            <div
                                                className="menu-header-image opacity-2"
                                                style={{
                                                    backgroundImage: 'url(' + background + ')',
                                                }}
                                            />
                                            <div className="menu-header-content text-left">
                                                <div className="widget-content p-0">
                                                    <div
                                                        className="widget-content-wrapper"
                                                        // style={{ padding: '1rem 1.2rem 1rem 1.4rem' }}
                                                    >
                                                        {/*<div className="widget-content-left mr-3">*/}
                                                        {/*    <img*/}
                                                        {/*        width={42}*/}
                                                        {/*        className="rounded-circle"*/}
                                                        {/*        src={userImg}*/}
                                                        {/*        alt=""*/}
                                                        {/*    />*/}
                                                        {/*</div>*/}
                                                        <div className="widget-content-left">
                                                            <div className="profile-txt">
                                                                <span className="user-name">{userInfo.userName}</span>
                                                                <span className="unread-msg">
                                                                    {t('UserBox;There are')} <b>{unreadAlert}</b>{' '}
                                                                    {t('UserBox;unread alerts')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-right mr-2">
                                                            <Button
                                                                className={'btn-brand btn-shadow'}
                                                                onClick={toggleLogoutModal}
                                                            >
                                                                {t('UserBox;Logout')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profile-setting">
                                        <Row className="no-gutters">
                                            <Col sm onClick={toggleProfileModal} className={'setting-item'}>
                                                <span className="icon-profile-setting" />
                                                <span className="setting-name">{t('UserBox;View profile')}</span>
                                            </Col>
                                            {/*<Col sm onClick={toggleAppSettingModal} className={'setting-item'}>*/}
                                            {/*    <span className="icon-app-setting" />*/}
                                            {/*    <span className="setting-name">{t('UserBox;Application setting')}</span>*/}
                                            {/*</Col>*/}
                                            {!!languageOption && (
                                                <Col sm onClick={toggleLanguageSelectModal} className={'setting-item'}>
                                                    <span className="icon-language-setting" />
                                                    <span className="setting-name">
                                                        {t('UserBox;Language setting')}
                                                    </span>
                                                </Col>
                                            )}

                                            {/*<Col sm onClick={toggleUnmappingModal} className={'setting-item'}>*/}
                                            {/*    <span className="icon-tools" />*/}
                                            {/*    <span className="setting-name">{t('UserBox;Unmapping All')}</span>*/}
                                            {/*</Col>*/}
                                        </Row>
                                    </div>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>

                        <div className="widget-content-left ml-3 header-user-info">
                            {/* <div className="widget-heading">{userInfo.userName}</div> */}
                            {/*<div className="widget-subheading">VP People Manager</div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <ProfileModal initModal={profileModal} toggleModal={toggleProfileModal} userInfo={userInfo} />
            <AppSettingModal initModal={appSettingModal} toggleModal={toggleAppSettingModal} />
            <LanguageSelectModal initModal={languageSelectModal} toggleModal={toggleLanguageSelectModal} />
            <LogoutModal initModal={logoutModal} toggleModal={toggleLogoutModal} handleLogout={handleLogout} />
            <ConfirmModal
                initModal={unmappingModal}
                header={{ title: t('ConfirmModal;Confirm') }}
                toggleModal={toggleUnmappingModal}
                okCallback={() => deleteUnmappingDevices({ roleName: 'worker' })}
                cancelButton={{ text: t('Button;Cancel') }}
                confirmText={
                    <Container className={'flex-center'} style={{ flexDirection: 'column', padding: '1rem' }}>
                        <Row className={'mb-1'}>
                            {t('UserBox;All the safety devices(Tag, Sensor) will be unmapped.')}
                        </Row>
                        <Row className={'mb-1'}>{t('UserBox;Are you sure you want to continue?')}</Row>
                    </Container>
                }
            />

            <ConfirmModal
                initModal={unmappingResultModal.success}
                header={{ title: t('ConfirmModal;Result') }}
                toggleModal={toggleUnmappingSuccessModal}
                removeCancel
                confirmText={
                    <Container className={'flex-center'} style={{ flexDirection: 'column', padding: '1rem' }}>
                        <Row className={'mb-1'}>
                            {t('UserBox;All the safety devices(Tag, Sensor) are unmapped successfully.')}
                        </Row>
                    </Container>
                }
            />

            <ConfirmModal
                initModal={unmappingResultModal.fail}
                header={{ title: t('ConfirmModal;Result') }}
                toggleModal={toggleUnmappingFailModal}
                removeCancel
                confirmText={
                    <Container className={'flex-center'} style={{ flexDirection: 'column', padding: '1rem' }}>
                        <Row className={'mb-1'}>{t('UserBox;Failed to unmap all the devices(Tag, Sensor).')}</Row>
                        <Row className={'mb-1'}>{t('UserBox;Please try again later.')}</Row>
                    </Container>
                }
            />
        </>
    );
};

export default UserBox;
