import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    showWidget: false,
    loneWorkerView: false,
    selectedWorker: null,
    selectedFloor: {},
    equipmentList: [],
    defaultList: {
        defaultPeopleList: [],
        defaultGeofencePeopleList: {
            common: [],
            enclosed: [],
        },
        defaultCompanyPeopleList: [],
    },
    peopleList: [],
    searchInput: '',
    companyList: [],
    peopleRealTimeLog: [],
    activeTabId: null,
    facilityAndGeofence: [],
    // 추가
    floorInfo: [],
    totalStatus: {
        currentWorkerCount: 0,
        mappingWorkerCount: 0,
        currentEnclosedWorkerCount: 0,
        sosCount: 0,
        manDownCount: 0,
    },
    showList: false,
    selectedPerson: null,
    selectedFcNum: null,
};

const { actions, reducer } = createSlice({
    name: 'workStatus',
    initialState,
    reducers: {
        setShowWidget: (state, action) => {
            state.showWidget = action.payload;
        },
        setSelectedWorker: (state, action) => {
            state.selectedWorker = action.payload;
        },
        setSelectedFloor: (state, action) => {
            state.selectedFloor = action.payload;
        },
        setDefaultPeopleList: (state, action) => {
            state.defaultList = action.payload;
        },
        setPeopleList: (state, action) => {
            state.peopleList = action.payload;
        },
        setSearchInput: (state, action) => {
            state.searchInput = action.payload;
        },
        setActiveTabId: (state, action) => {
            state.activeTabId = action.payload;
        },
        clearFacilityAndFloor: state => {
            state.selectedFloor = {};
            state.facilityAndGeofence = [];
            state.equipmentList = [];
        },
        // 추가
        setFloorInfo: (state, action) => {
            state.floorInfo = action.payload;
        },
        setTotalStatus: (state, action) => {
            state.totalStatus = action.payload;
        },
        setShowList: (state, action) => {
            state.showList = action.payload;
        },
        setSelectedPerson: (state, action) => {
            state.selectedPerson = action.payload;
        },
        setSelectedFcNum: (state, action) => {
            state.selectedFcNum = action.payload;
            state.activeTabId = 'geofence';
        },
    },
});

export const {
    setSelectedWorker,
    setSelectedFloor,
    setPeopleList,
    setDefaultPeopleList,
    setSearchInput,
    setActiveTabId,
    clearFacilityAndFloor,
    setFloorInfo,
    setTotalStatus,
    setShowList,
    setSelectedPerson,
    setSelectedFcNum,
} = actions;

export default reducer;
