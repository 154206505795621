import React from 'react';
import styled from 'styled-components';

import { useTranslation } from '@hooks';

const NoDataContainer = styled.div`
    text-align: center;
    align-items: center;
`;

const NoDataComponent = () => {
    const t = useTranslation('Table');
    return <NoDataContainer>{t('No data')}</NoDataContainer>;
};

export default NoDataComponent;
