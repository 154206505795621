import { createSlice } from '@reduxjs/toolkit';
import { isSelectableFloor } from '../../util/common/util';

const initialState = {
    floorList: [],
    selectableFloorList: [],
    smartSafeFloorList: [],
};

const { actions, reducer } = createSlice({
    name: 'common/floorInfo',
    initialState,
    reducers: {
        setFloorList: (state, action) => {
            if (action.payload.rows) {
                const floorList = action.payload.rows.map(v => ({
                    ...v,
                    bounds: [
                        [v.swLat, v.swLng],
                        [v.neLat, v.neLng],
                    ],
                }));
                state.floorList = floorList;

                state.selectableFloorList = floorList.reduce((acc, floor) => {
                    if (isSelectableFloor(floor, floorList)) {
                        acc.push(floor);
                    }
                    return acc;
                }, []);
            }
        },
        setSmartSafeFloorList: (state, action) => {
            state.smartSafeFloorList = action.payload;
        },
    },
});

export const { setFloorList, setSmartSafeFloorList } = actions;
export default reducer;
