import React, { useContext, useEffect, useMemo, useState } from 'react';
import { fetchAssetCountStatus } from '../../../../api/asset';
import { Col } from 'reactstrap';
import useTranslation from '../../../../util/hooks/useTranslation';
import useMonitor from '../../util/useMonitor';
import { IotItemStatusStateContext, IotItemStatusDispatchContext } from '../index';
import Card from '../../../Common/Card';
import { setIotItemCategoryOptions, setIotItemCountParam, setSelectedIotItemStatus } from '../iotItemStatusReducer';
import { useSelector } from 'react-redux';
import { useSettings } from '../../util/useSettings';
import ItemCount from './ItemCount';

const defaultData = {
    totalCount: 1,
    restrictedCount: 2,
    lostSignalCount: 3,
    lowBatteryCount: 4,
    neverDetectedCount: 5,
};

const CountWrapper = ({ children }) => {
    return <Col>{children}</Col>;
};

const IotItemStatusCount = ({ widgetInfo }) => {
    const t = useTranslation('IotItemStatus');
    const { iotItemCountParam, selectedIotItemStatus, searchInput } = useContext(IotItemStatusStateContext);
    const dispatch = useContext(IotItemStatusDispatchContext);
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const { mode } = useSelector(state => state.DashboardFrame);

    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { categoryCode: parentCategory } = settings;

    const [countData, setCountData] = useState({ prev: defaultData, curr: defaultData });

    const { monitor: iotItemCountStatusMonitor, stop: iotItemCountStatusMonitorStop } = useMonitor({
        config,
        defaultData,
        paramInfo: ['categoryCode'],
        dynamicParam: iotItemCountParam,
        fetchData: fetchAssetCountStatus,
        manualMonitor: true,
        makeData: ({ newData }) => {
            setCountData(prev => ({
                prev: prev.curr,
                curr: newData,
            }));
        },
    });
    useMemo(() => {
        iotItemCountStatusMonitorStop();
        const param = {
            categoryCode: searchInput.categoryCode ? searchInput.categoryCode : parentCategory,

            // 자산 상태별 조회하는 경우
            status: selectedIotItemStatus ? selectedIotItemStatus : '',

            // 검색 조건에 검색된 자산이 존재하는 경우
            target: searchInput.target,
            floorIds: searchInput.floorIds,
        };
        dispatch(setIotItemCountParam(param));
    }, [parentCategory, selectedIotItemStatus, searchInput]);

    useEffect(() => {
        if (categoryList.length) {
            const assetList = categoryList.filter(category => {
                if (category.categoryCode === parentCategory) {
                    return category;
                }
                return category.parentCode === parentCategory;
            });
            dispatch(
                setIotItemCategoryOptions([
                    { categoryCode: '', categoryName: t('All Iot Item'), categoryCodePath: '' },
                    ...assetList,
                ]),
            );
        }
    }, [categoryList, parentCategory]);

    useEffect(() => {
        iotItemCountStatusMonitorStop();
        if (iotItemCountParam.categoryCode && mode === 'monitoring') {
            iotItemCountStatusMonitor();
        }
        return () => {
            iotItemCountStatusMonitorStop();
        };
    }, [iotItemCountParam]);

    const handleLabelClick = selectedStatus => {
        dispatch(setSelectedIotItemStatus(selectedStatus));
    };

    return (
        <Card>
            <div style={{ width: '100%', display: 'flex', alignItems: 'start' }}>
                {countData &&
                    Object.keys(countData.curr).map(key => {
                        return (
                            <CountWrapper key={key}>
                                <ItemCount
                                    parentCategory={parentCategory}
                                    start={countData.prev[key]}
                                    end={countData.curr[key]}
                                    label={key}
                                    onClick={handleLabelClick}
                                    selectedIotItemStatus={selectedIotItemStatus}
                                />
                            </CountWrapper>
                        );
                    })}
            </div>
        </Card>
    );
};

export default IotItemStatusCount;
