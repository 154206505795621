import React, { useState, useEffect, useContext, useCallback } from 'react';
import Draggable from '@components/Draggable';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import Table from '../../../Common/Table';
import { useTranslation } from 'react-i18next';
import { useAssetListFloorDetailColumns } from '../../../../util/grid/column';
import moment from 'moment';
import { MassRealTimeLocationStatusContext } from '../index';

const FloorDetailPopup = ({ modal, toggleModal, widgetRef, mapRef }) => {
    const { t } = useTranslation();
    const { geofenceInfo, realTimeLog, categoryList } = useContext(MassRealTimeLocationStatusContext);
    const [itemList, setItemList] = useState({ pageSize: 4, totalPage: 3, rows: [] });

    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    const [popupSize, setPopupSize] = useState({
        x: 0,
        y: 0,
    });

    const popupRef = useCallback(node => {
        if (node !== null) {
            const { width, height } = node.getBoundingClientRect();

            setPopupSize({
                width,
                height,
            });
        }
    }, []);

    useEffect(() => {
        const widgetPosition = getWidgetPosition();

        const x = (widgetPosition.width - popupSize.width) / 2 - 20;
        const y = widgetPosition.height / 2 - widgetPosition.height / 4 - 100;
        setPosition({
            x: x,
            y: y,
        });
    }, [popupSize]);

    useEffect(() => {
        const targetList =
            realTimeLog &&
            realTimeLog.data.map(target => {
                const { target: targetInfo, floorInOutState } = target;
                const category = categoryList.find(v => targetInfo.categoryCode === v.categoryCode);
                return {
                    targetId: targetInfo.targetId,
                    targetName: targetInfo.targetName,
                    categoryName: category && category.categoryName,
                    entryTime: moment.unix(floorInOutState.inTime).format('YYYY-MM-DD HH:mm:ss'),
                };
            });
        setItemList({ ...itemList, rows: targetList });
    }, [realTimeLog]);

    const getPosition = (el, position = { top: 0, left: 0 }) => {
        if (el) {
            position.top += el.offsetTop;
            position.left += el.offsetLeft;
            getPosition(el.offsetParent, position);
        }
        return position;
    };

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };

        if (widgetRef.current) {
            const widget = widgetRef.current;

            const { offsetWidth: width, offsetHeight: height } = widget;

            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };

    return (
        <Draggable
            key={JSON.stringify(position)}
            backgroundMapElement={mapRef.current?.leafletElement}
            bounds=".leaflet-container"
            defaultPosition={position}
        >
            <Toast isOpen={modal} className={'popup-open floorDetail'} innerRef={popupRef}>
                <ToastHeader toggle={toggleModal}>
                    {t('RealTimeLocationStatus;Information of the Selected Floor')}
                </ToastHeader>
                <ToastBody>
                    <div>
                        {t('RealTimeLocationStatus;Number of Geofence')} :{' '}
                        {geofenceInfo && geofenceInfo.length ? geofenceInfo.length : 0}
                    </div>

                    <div>
                        {t('RealTimeLocationStatus;Total Number of Detected Items on the Selected Floor')} :{' '}
                        {realTimeLog.count ? realTimeLog.count : 0}
                    </div>

                    <Table
                        columns={useAssetListFloorDetailColumns()}
                        data={{
                            rows: itemList.rows,
                            pageSize: 5,
                            totalPage: itemList.rows.length % 5 === 0 ? itemList.rows / 5 : itemList.rows / 5 + 1,
                            totalCount: itemList.rows.length,
                        }}
                        manualPagination={false}
                        autoResetPage={false}
                    />
                </ToastBody>
            </Toast>
        </Draggable>
    );
};
export default FloorDetailPopup;
