import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
    alertList: [],
    activeAlertLength: 0,
    toastConfigValue: {
        useAlert: true,
        limitAlert: 5,
        autoClose: 5000,
    },
    checkedInterfaceCommandType: [],
    selectedAlertInfo: {},
    notification: {
        SOS_ON: {},
        MAN_DOWN_ON: {},
        BIOSIGNAL_ABNORMALITY_ON: {},
    },
    sosAlert: {},
    alarmProcessed: null,
};

const addNotification = (state, notificationInfos) => {
    const { stateGroupType, workerNum, fcData } = notificationInfos;

    if (!state.notification[`${stateGroupType}_ON`][workerNum]) {
        state.notification[`${stateGroupType}_ON`][workerNum] = [];
    }
    state.notification[`${stateGroupType}_ON`][workerNum].push({
        location: fcData,
        type: `${stateGroupType}_ON`,
        ...notificationInfos,
    });
};

const deleteCheckedNotification = (state, notificationLog) => {
    const { stateType, stateGroupType, workerNum } = notificationLog;

    if (stateType.includes('CHANGE_OFF')) {
        if (state.notification[`${stateGroupType}_ON`][workerNum]) {
            delete state.notification[`${stateGroupType}_ON`][workerNum];
        }
    }
};

const { actions, reducer } = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setAlert: {
            reducer: (state, action) => {
                if (state.checkedInterfaceCommandType.includes(action.payload.interfaceCommandType)) {
                    state.alertList.push(action.payload);
                }
            },
            prepare: ({ notificationLog: { targetName, interfaceCommandType, regDate, targetNum } }) => {
                const alertDate = moment(regDate * 1000).format('YYYY-MM-DD HH:mm:ss');
                return {
                    payload: {
                        targetName,
                        interfaceCommandType,
                        alertDate,
                        targetNum,
                    },
                };
            },
        },
        setChangeActiveAlertLength: (state, action) => {
            state.activeAlertLength += action.payload;
            if (action.payload === 1) {
                state.alertList.shift();
            }
        },
        setDeleteAlert: (state, action) => {
            state.alertList.shift();
        },
        setCheckedInterfaceCommandType: (state, action) => {
            state.checkedInterfaceCommandType = action.payload.reduce((acc, cur) => {
                if (cur.isChecked === 'Y') {
                    acc.push(cur.interfaceCommandType);
                }
                return acc;
            }, []);
        },
        setSelectedAlertInfo: (state, action) => {
            state.selectedAlertInfo = action.payload;
        },
        setNotification: (state, action) => {
            const notificationInfos = action.payload;
            const { stateType } = notificationInfos;

            let eventOn;
            if (stateType.includes('CHANGE_ON')) {
                eventOn = true;
            }

            if (eventOn) {
                addNotification(state, notificationInfos);
            } else {
                deleteCheckedNotification(state, notificationInfos);
            }
        },
        deleteNotification: (state, action) => {
            const { targetNum, type } = action.payload;

            if (targetNum === 'tracing') {
                state.notification['SOS_ON'] = {};
                state.notification['MAN_DOWN_ON'] = {};
                state.notification['BIOSIGNAL_ABNORMALITY_ON'] = {};
            } else {
                delete state.notification[type][targetNum];
            }
        },
        setSosAlert: (state, action) => {
            state.sosAlert = action.payload;
        },
        setAlarmProcessed: (state, action) => {
            state.alarmProcessed = action.payload;
        },
    },
});

export const {
    setAlert,
    setChangeActiveAlertLength,
    setDeleteAlert,
    setCheckedInterfaceCommandType,
    setSelectedAlertInfo,
    setNotification,
    deleteNotification,
    setSosAlert,
    setAlarmProcessed,
} = actions;
export default reducer;
