import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import useTranslation from '../../../util/hooks/useTranslation';

import Nav from '../AppNav/VerticalNavWrapper';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { setEnableMobileMenu } from '@reducer/ThemeOptions';

import { layout } from '@components/style';

const AppSidebar = () => {
    const t = useTranslation('Menu');
    const scrollRef = useRef();
    const [setting, setSetting] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const { enableSidebarShadow, enableMobileMenu, backgroundColor } = useSelector(state => state.ThemeOptions);

    const currentLocation = location.pathname.split('/')[1];

    useEffect(() => {
        if (currentLocation === 'status') {
            setSetting(true);
        }
    }, [currentLocation]);

    const toggleMobileSidebar = () => {
        dispatch(setEnableMobileMenu(!enableMobileMenu));
    };

    const handleSetting = () => {
        setSetting(prevState => !prevState);
    };

    return (
        <>
            <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} />
            <ReactCSSTransitionGroup
                component="div"
                className={cx('app-sidebar', 'pnt-sidebar', 'nav-box__img-bg', backgroundColor, {
                    'sidebar-shadow': enableSidebarShadow,
                })}
                transitionName="SidebarAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1500}
                transitionEnter={false}
                transitionLeave={false}
            >
                <div className={'h-100'}>
                    <PerfectScrollbar ref={scrollRef}>
                        <div
                            className="app-sidebar__inner"
                            style={{ padding: `1rem ${layout.layoutSpacerX} ${layout.layoutSpacerX}` }}
                        >
                            <Nav
                                setting={setting}
                                onUpdateSize={() => {
                                    if (scrollRef.current) {
                                        scrollRef.current._container.scrollTop = 0;
                                    }
                                }}
                            />
                        </div>
                    </PerfectScrollbar>
                </div>
                {/*<div className="pnt-sidebar-bottom app-sidebar__inner">*/}
                {/*    <div className="metismenu vertical-nav-menu">*/}
                {/*        <ul className="metismenu-container">*/}
                {/*            <li className="metismenu-item">*/}
                {/*                <div className={'menu-border-box'}>*/}
                {/*                    <a*/}
                {/*                        className="metismenu-link"*/}
                {/*                        href="#"*/}
                {/*                        onClick={e => {*/}
                {/*                            e.preventDefault();*/}
                {/*                            handleSetting();*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*                        {setting ? (*/}
                {/*                            <i*/}
                {/*                                className="metismenu-icon fa fa-home"*/}
                {/*                                style={{ top: '50%', left: '7px' }}*/}
                {/*                            />*/}
                {/*                        ) : (*/}
                {/*                            <i className="metismenu-icon icon-menu-third" />*/}
                {/*                        )}*/}
                {/*                        <span*/}
                {/*                            style={{*/}
                {/*                                maxWidth: '100%',*/}
                {/*                                textOverflow: 'ellipsis',*/}
                {/*                                whiteSpace: 'nowrap',*/}
                {/*                                overflow: 'hidden',*/}
                {/*                                display: 'inline-block',*/}
                {/*                                fontWeight: 'bold',*/}
                {/*                            }}*/}
                {/*                        >*/}
                {/*                            {setting ? t('Back to Home') : t('Settings')}*/}
                {/*                        </span>*/}
                {/*                    </a>*/}
                {/*                </div>*/}
                {/*            </li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </ReactCSSTransitionGroup>
        </>
    );
};

export default AppSidebar;
