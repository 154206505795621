import React, { useContext } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import cx from 'classnames';

import useTranslation from '@hooks/useTranslation';

import { WorkStatusContext } from 'Components/MainPages/WorkerSafetyDashboard';
import { nullToZero } from 'Components/MainPages/util/common/util';

import AlarmOffImg from 'assets/images/alarm_off.png';
import AlarmOnImg from 'assets/images/alarm_on.png';

import { makeTooltipStyles, Wrapper, ImageSurface } from './styled';

const SafetyOverview = () => {
    const t = useTranslation('Overview');
    const {
        totalStatus: { sosCount, manDownCount },
    } = useContext(WorkStatusContext);

    return (
        <Wrapper>
            <div className={'img-container'}>
                <div className={'recharts-surface'}>
                    <ImageSurface img={sosCount + manDownCount ? AlarmOnImg : AlarmOffImg} />
                </div>
            </div>
            <div className="pl-0 ml-2 status-container">
                <div className="pnt-txt txt-border txt-bold p-1 title-plate">{t('Safety Overview', 'Work Status')}</div>
                <div className={'flx-row'}>
                    <div className="d-flex flx-col align-items-center gap-0">
                        <span className={cx('txt-overview-main', !!sosCount && 'text-danger')}>
                            {nullToZero(sosCount)}
                        </span>
                        <div className={'txt-overview-sub'} id={'sos'}>
                            {t('SOS')}
                        </div>
                        <UncontrolledTooltip
                            hideArrow={true}
                            style={makeTooltipStyles()}
                            target={'sos'}
                            placement={'top'}
                        >
                            {t('SOS')}
                        </UncontrolledTooltip>
                    </div>
                    <div className="txt-slash">/</div>
                    <div className="d-flex flx-col align-items-center gap-0">
                        <span className={cx('txt-overview-main', !!manDownCount && 'text-danger')}>
                            {nullToZero(manDownCount)}
                        </span>
                        <div className={'txt-overview-sub'} id={'manDown'}>
                            {t('Man Down')}
                        </div>
                        <UncontrolledTooltip
                            hideArrow={true}
                            style={makeTooltipStyles()}
                            target={'manDown'}
                            placement={'top'}
                        >
                            {t('Man Down')}
                        </UncontrolledTooltip>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default SafetyOverview;
