import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@hooks';
import { DatePicker, Button, Select as ReactSelect, TextInput } from '@components';
import moment from 'moment';
import FilterSearchGroup from 'Components/MainPages/Components/FilterSearchGroup';
import FilterGroup from 'Components/MainPages/Components/FilterSearchGroup/Components/FilterGroup';
import {
    FilterList,
    InputGroup,
    InputWrap,
    SearchWrap,
    SelectGroup,
} from 'Components/MainPages/Components/FilterSearchGroup/Components/Part';
import FilterButtonArea from 'Components/MainPages/Components/FilterSearchGroup/Components/FilterButtonArea';
import SearchGroup from 'Components/MainPages/Components/FilterSearchGroup/Components/SearchGroup';

import cx from 'classnames';

const makeDangerousStatusKeyword = value => {
    const lowerCasedValue = value.toLowerCase();
    if (lowerCasedValue === 'sos') {
        return 'SN';
    }
    if (lowerCasedValue === 'mandown') {
        return 'MN';
    }
};

const Search = ({ getAlarmList }) => {
    const t = useTranslation('Search');
    const columnT = useTranslation('WorkerStatusColumn');

    const statusSearchOptions = useMemo(() => {
        return [
            { value: 'all', label: columnT('All') },
            { value: 'interfaceCommandType', label: columnT('Dangerous Status') },
            { value: 'targetName', label: columnT('Name') },
            { value: 'companyName', label: columnT('Company Name') },
        ];
    }, [columnT]);

    // 현재 오전 6시 이전이면 전날 검색 오전 6시를 default 이상이면 오늘 오전 6시를 default 설정
    const makeStartDate = () => {
        return moment().startOf('day').valueOf();
    };
    const makeEndDate = () => {
        return moment().valueOf();
    };

    const [startDate, setStartDate] = useState(makeStartDate());
    const [endDate, setEndDate] = useState(makeEndDate());
    const [searchInput, setSearchInput] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(statusSearchOptions[0]);

    const [refreshClicked, setRefreshClicked] = useState(true);

    useEffect(() => {
        if (refreshClicked) {
            getAlarmList({ startDate: moment(startDate).unix(), endDate: moment(endDate).unix() });
            setRefreshClicked(false);
        }
    }, [refreshClicked]);

    useEffect(() => {
        if (selectedStatus.value === 'all') {
            setSearchInput('');
        }
    }, [selectedStatus]);

    const handleRefreshClick = () => {
        setSelectedStatus(statusSearchOptions[0]);
        setStartDate(makeStartDate());
        setEndDate(makeEndDate());
        setSearchInput('');

        setRefreshClicked(true);
    };

    const handleSearchInputChange = e => {
        setSearchInput(e.target.value);
    };

    const handleSearchClick = () => {
        if (searchInput) {
            getAlarmList({
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
                opt: selectedStatus.value === 'all' ? null : selectedStatus.value,
                keyword:
                    selectedStatus.value === 'interfaceCommandType'
                        ? makeDangerousStatusKeyword(searchInput)
                        : searchInput,
            });
        } else {
            getAlarmList({
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        }
    };

    const handleTargetStatusSelectChange = selected => {
        setSelectedStatus(selected);
    };

    const handleYesterdayButtonClick = () => {
        const start = moment().startOf('day').subtract(1, 'days').valueOf();
        const end = moment().startOf('day').valueOf();

        setStartDate(start);
        setEndDate(end);
    };

    return (
        <FilterSearchGroup>
            <FilterGroup className={'bg-brand'} label={t('Search', 'Search')}>
                <FilterList>
                    <SelectGroup>
                        <div className={'datePicker-container'}>
                            <DatePicker
                                value={startDate}
                                handleChange={selected => setStartDate(selected)}
                                valueType={'ms'}
                                maxDate={endDate || moment()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                    <div className={'text-center'}> ~ </div>
                    <SelectGroup>
                        <div className={'datePicker-container'}>
                            <DatePicker
                                value={endDate}
                                handleChange={selected => setEndDate(selected)}
                                valueType={'ms'}
                                minDate={startDate}
                                maxDate={moment()}
                                showTimeInput
                            />
                        </div>
                    </SelectGroup>
                </FilterList>
                <FilterButtonArea>
                    <Button className={'btn-secondary btn-yesterday'} onClick={handleYesterdayButtonClick}>
                        {t('Yesterday', 'Button')}
                    </Button>
                    <Button
                        className="btn-danger btn-icon-only"
                        iconClassName={'icon-replay'}
                        onClick={handleRefreshClick}
                    />
                </FilterButtonArea>
            </FilterGroup>
            <SearchGroup>
                <SearchWrap>
                    <SelectGroup>
                        <ReactSelect
                            name={'targetStatus'}
                            placeholder={t('All')}
                            options={statusSearchOptions}
                            onChange={handleTargetStatusSelectChange}
                            value={selectedStatus}
                            customMenuStyles={{ width: '100%' }}
                            customOptionStyles={{ width: '100%' }}
                            customControlStyles={{ width: '100%' }}
                        />
                    </SelectGroup>
                    <InputWrap>
                        <InputGroup>
                            <TextInput
                                size={'80'}
                                placeholder={t('Enter Search Condition')}
                                inputGroupClassName={cx(selectedStatus.value === 'all' && 'form-disable')}
                                name={'workerNameWithPhoneNum'}
                                value={searchInput}
                                handleChange={handleSearchInputChange}
                            />
                        </InputGroup>
                        <Button
                            className={'btn-brand btn-icon'}
                            iconClassName={'icon-search'}
                            onClick={handleSearchClick}
                        >
                            {t('Search', 'Search')}
                        </Button>
                    </InputWrap>
                </SearchWrap>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
