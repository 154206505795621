import React from 'react';
import { Route } from 'react-router-dom';

import { ErrorHandleSwitch } from '../Components/Router';

import AlarmLog from './AlarmLog';

const WorkerSafetyReport = ({ match }) => {
    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/alarm`} component={AlarmLog} />
        </ErrorHandleSwitch>
    );
};

export default WorkerSafetyReport;
