import React, { useEffect, useRef, useContext } from 'react';

import { useAsync } from '@hooks';
import { fetchCurrentStatistics } from '@api/smartSafeWorker';

import Overview from '../IndoorCoalStorageSiteStatus/Components/Overview';
import WorkerStatusMap from '../IndoorCoalStorageSiteStatus/Components/WorkerStatusMap';
import StatusByFloor from '../IndoorCoalStorageSiteStatus/Components/StatusByFloor';

import { WorkStatusDispatchContext } from 'Components/MainPages/WorkerSafetyDashboard';
import { setFloorInfo, setTotalStatus } from '../workerStatusReducer';

const INTERVAL_TIME = 5 * 1000;

const LargeMonitorControl = () => {
    const intervalRef = useRef(null);

    const dispatch = useContext(WorkStatusDispatchContext);

    const { promise: getFloorWorker } = useAsync({
        promise: fetchCurrentStatistics,
        resolve: res => {
            // 이름 역순으로 정렬
            const { floorWorkersInGeofences, ...totalStatus } = res;
            dispatch(
                setFloorInfo(
                    floorWorkersInGeofences.sort((a, b) => {
                        const first = Object.keys(b)[0];
                        const second = Object.keys(a)[0];
                        if (first < second) {
                            return -1;
                        }
                        if (first > second) {
                            return 1;
                        }
                        return 0;
                    }),
                ),
            );
            dispatch(setTotalStatus(totalStatus));
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        getFloorWorker();

        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            getFloorWorker();
        }, INTERVAL_TIME);

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, []);

    return (
        <div className="work-monitor">
            <Overview />
            <WorkerStatusMap monitoring />
            <StatusByFloor />
        </div>
    );
};

export default LargeMonitorControl;
