import ThemeOptions from './ThemeOptions';
import DashboardFrame from './Dashboards/DashboardFrame';
import Dashboards from './Dashboards/Dashboards';
import DashboardEdit from './Dashboards/DashboardEdit';
import CustomMenu from './CustomMenu';
import UserInfo from './UserInfo';
import TagInfo from './TagInfo';
import FloorInfo from './Common/FloorInfo';
import CategoryInfo from './Common/CategoryInfo';
import WorkplaceInfo from './Common/WorkplaceInfo';
import AppConfig from './Common/AppConfig';
import AppInfo from './Common/AppInfo';
import SocketInfo from './SocketInfo';
import ErrorHandler from './ErrorHandler';
import Location from './SocketInfo/Location';
import Notification from './Notification';
import BookMarkedItem from './Common/BookMarkedItem';
import FilterInfo from './FilterInfo';

export default {
    ThemeOptions,
    DashboardFrame,
    Dashboards,
    DashboardEdit,
    CustomMenu,
    UserInfo,
    TagInfo,
    FloorInfo,
    CategoryInfo,
    WorkplaceInfo,
    AppConfig,
    AppInfo,
    SocketInfo,
    ErrorHandler,
    Location,
    Notification,
    BookMarkedItem,
    FilterInfo,
};
