import React from 'react';
import { createPortal } from 'react-dom';
import { useDidMount } from '@hooks';

const withPortal = InputComponent => {
    return function Component(props) {
        const isRendered = useDidMount();
        return isRendered ? (
            <InputComponent {...props} />
        ) : (
            createPortal(<InputComponent {...props} />, document.getElementById('selectContainer'))
        );
    };
};

export default withPortal;
