import React, { useState, useRef, useEffect } from 'react';

import Search from './Components/Search';
import { Card } from '@components';
import Table from '../Components/Table';
import { workerStatusHeader, nullToHyphen } from '../Components/utils';
import { useTranslation, useColumns, useAsync } from '@hooks';
import * as column from 'Components/MainPages/util/grid/columns';
import { fetchWorkerStatus } from '@api/smartSafeWorker';

const INTERVAL_TIME = 5 * 1000;

const RealTimeWorkerStatus = () => {
    const t = useTranslation('WorkerStatus');
    const statusT = useTranslation('WorkerStatusColumn');

    const intervalRef = useRef(null);

    // const [trInfo, setTrInfo] = useState(null);

    // const [actionModal, setActionModal] = useState(false);

    const [tableData, setTableData] = useState({ rows: [], enclosedWorkerCount: 0, workerCount: 0 });
    const { rows, enclosedWorkerCount, workerCount } = tableData;

    const [workerStatus, setWorkerStatus] = useState({});
    const handleWorkerStatus = ({ inEnclosedYn, dangerStatus, keyword, opt }) => {
        setWorkerStatus({
            inEnclosedYn,
            dangerStatus,
            keyword,
            opt,
        });
    };

    const { promise: getWorkerStatus } = useAsync({
        promise: fetchWorkerStatus,
        resolve: res => {
            const { workerStatusList: rows, enclosedWorkerCount, workerCount } = res;
            setTableData({ rows, workerCount, enclosedWorkerCount });
        },
        reject: err => {
            console.error(err);
        },
    });

    // const { promise: postAlertDescription } = useAsync({
    //     promise: createAlertDescription,
    //     resolve: () => {
    //         getWorkerStatus({ ...workerStatus });
    //     },
    // });

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        getWorkerStatus({ ...workerStatus });
        intervalRef.current = setInterval(() => {
            getWorkerStatus({ ...workerStatus });
        }, INTERVAL_TIME);

        return () => {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        };
    }, [workerStatus]);

    // const toggleActionModal = () => {
    //     setActionModal(prevState => !prevState);
    // };

    // const actionButtonClick = trData => {
    //     toggleActionModal();
    //     setTrInfo(trData);
    // };
    //
    // const actionModalOkCallback = message => {
    //     postAlertDescription({
    //         logNum: trInfo.logNum,
    //         description: message,
    //     });
    //     toggleActionModal();
    // };

    const workerStatusColumns = useColumns(
        [
            column.status({
                accessor: 'entryStatus',
                width: 80,
            }),
            column.name({
                accessor: 'targetName',
                Cell: ({ value }) => {
                    return nullToHyphen(value);
                },
                width: 100,
            }),
            column.companyName({
                Cell: ({ value }) => {
                    return nullToHyphen(value);
                },
            }),
            column.phoneNumber({
                Cell: ({ value }) => {
                    return nullToHyphen(value);
                },
                width: 120,
            }),
            column.healthRating({
                Cell: ({ value }) => {
                    return nullToHyphen(value);
                },
                width: 80,
            }),
            column.timeOfEntry({
                Cell: ({ value }) => {
                    return nullToHyphen(value);
                },
            }),
            column.timeOfExist({
                Cell: ({ value }) => {
                    return nullToHyphen(value);
                },
            }),
            column.workshop({
                accessor: 'workLocation',
                Cell: ({ value }) => {
                    return nullToHyphen(value);
                },
            }),
            column.tagId({
                Cell: ({ value }) => {
                    return nullToHyphen(value);
                },
                width: 80,
            }),
            column.dangerousStatus({
                Cell: ({ value }) => {
                    return nullToHyphen(value);
                },
                width: 130,
            }),
            column.battery({
                Cell: ({ value }) => {
                    return typeof value === 'number' ? value + '%' : nullToHyphen(value);
                },
                width: 100,
            }),
            // 조치 상태 업체 협의될 때 까지 숨김처리
            // column.actionContents({
            //     className: 'py-6px',
            //     Cell: ({ value, row: { original } }) => {
            //         const { dangerStatus } = original;
            //         return dangerStatus === 'NORMAL' ? (
            //             '-'
            //         ) : (
            //             <Button
            //                 className={cx('height-1_5rem', value ? 'btn-secondary' : 'btn-brand')}
            //                 onClick={() => actionButtonClick(original)}
            //             >
            //                 {value ? statusT('Action Completed') : statusT('Take Action')}
            //             </Button>
            //         );
            //     },
            //     width: 120,
            // }),
            column.workday({
                Cell: ({ value }) => {
                    return nullToHyphen(value);
                },
                width: 100,
            }),
        ],
        statusT,
    );

    return (
        <>
            <Search getWorkerStatus={getWorkerStatus} handleWorkerStatus={handleWorkerStatus} />
            <Card
                className="report-container sensor-card-height-15"
                header={{
                    title: workerStatusHeader({
                        t,
                        headerName: 'Worker Status',
                        enclosedWorkerCount,
                        workerCount,
                    }),
                }}
            >
                <Table data={{ rows }} columns={workerStatusColumns} paging={false} />
            </Card>
            {/*<ActionModal*/}
            {/*    data={trInfo}*/}
            {/*    initModal={actionModal}*/}
            {/*    okCallback={actionModalOkCallback}*/}
            {/*    cancelCallback={toggleActionModal}*/}
            {/*/>*/}
        </>
    );
};

export default RealTimeWorkerStatus;
