export const CustomNav = [
    {
        id: 'dashboards',
        icon: 'icon-menu-first',
        label: 'Dashboards',
        content: [
            {
                label: 'Large Monitor Control',
                to: '#/dashboard/monitor',
            },
            {
                label: 'Real-time steel mill status',
                to: '#/dashboard/status',
            },
            {
                label: 'Real-time worker status',
                to: '#/dashboard/worker',
            },
        ],
    },
    {
        id: 'report',
        icon: 'icon-menu-second',
        label: 'Report',
        content: [
            {
                label: 'Alarm Log',
                to: '#/report/alarm',
            },
        ],
    },
    {
        id: 'wmsConnection',
        icon: 'icon-menu-fourth',
        label: 'Settings',
        to: '',
    },
];

export const SettingsNav = [
    {
        id: 'wmsConnection',
        icon: 'icon-menu-fourth',
        label: 'Move to WMS',
        to: '',
    },
];
