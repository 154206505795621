import React from 'react';
import Card from '../Components/WidgetCard';
import { Row, Col, Table } from 'reactstrap';
import { useSettings } from '../util/useSettings';
import useMonitor from '../util/useMonitor';
import ReactResizeDetector from 'react-resize-detector';
import ResponsivePieChart from '../../Common/Charts/PieChart';
import _ from 'lodash';
import { fetchIotItemCount } from '../../../api/asset';
import { useTopCategory } from '../util/useTopCategory';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

const defaultData = {
    rows: [
        {
            categoryCode: 'child2',
            categoryName: 'PNT ASSET',
            categoryCodePath: 'Example2>child2',
            targetCnt: _.random(9, 10),
        },
        {
            categoryCode: 'Example1',
            categoryName: 'Example1',
            categoryCodePath: 'Example1',
            targetCnt: _.random(30, 35),
        },
        {
            categoryCode: 'child3',
            categoryName: 'PNT ASSET',
            categoryCodePath: 'Example3>child3',
            targetCnt: _.random(10, 11),
        },
    ],
};

const sumTargetCnt = (acc, curr) => {
    acc += curr.targetCnt;
    return acc;
};

const IotItemCountStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const { t } = useTranslation();
    const settings = useSettings(config);
    const categoryList = useTopCategory();

    const { data } = useMonitor({
        config,
        defaultData,
        fetchData: fetchIotItemCount,
        makeData: ({ newData }) => {
            const iotItemCountList = newData.rows;
            const chartData = [];
            let parentCodeList = [];

            if (iotItemCountList) {
                for (let i = 0; i < iotItemCountList.length; i++) {
                    if (iotItemCountList[i].categoryCodePath.indexOf('>') < 0) {
                        chartData.push({
                            key: iotItemCountList[i].categoryCode,
                            name: iotItemCountList[i].categoryName,
                            value: iotItemCountList[i].targetCnt,
                        });
                    } else {
                        const parentCode = iotItemCountList[i].categoryCodePath.split('>')[0];
                        parentCodeList.push(parentCode);
                    }
                }
                parentCodeList = [...new Set(parentCodeList)];

                for (let i = 0; i < parentCodeList.length; i++) {
                    const name = categoryList.filter(v => v.value === parentCodeList[i]);
                    chartData.push({
                        key: parentCodeList[i],
                        name: (name && name.label) || parentCodeList[i],
                        value: iotItemCountList
                            .filter(v => v.categoryCodePath.indexOf(parentCodeList[i]) === 0)
                            .reduce(sumTargetCnt, 0),
                    });
                }
            }
            return chartData;
        },
    });

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <div className={'scrollbar-container'}>
                <PerfectScrollbar>
                    <Row style={{ height: '15rem' }}>
                        <ResponsivePieChart data={data} settings={settings} />
                    </Row>
                    <Row>
                        <ReactResizeDetector handleHeight>
                            <Col>
                                {t('IotItemCountStatus;Current / Count')}
                                <div style={{ width: '100%', overflowX: 'auto' }}>
                                    <Table className={'mb-0'}>
                                        <thead>
                                            <tr>
                                                {data.map(v => {
                                                    return (
                                                        <th key={v.key} className={'text-center'}>
                                                            {v.key}
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                {data.map(v => {
                                                    return (
                                                        <th key={v.key} className={'text-center'}>
                                                            {v.value}
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </ReactResizeDetector>
                    </Row>
                </PerfectScrollbar>
            </div>
            {children}
        </Card>
    );
};

export default IotItemCountStatus;
