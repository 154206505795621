// input(객체)를 parameter로 받아서 각 인풋의 존재여부 판별
// 인풋이 존재하지 않을시 false
// return: 각 input의 validation 값을 가진 객체 및 false값을 가진 갯수

export const formValidationCheck = input => {
    const inputList = Object.entries(input);
    const inputValidation = {};
    let validationLength = 0;

    for (let i = 0; i < inputList.length; i++) {
        const [key, value] = inputList[i];
        if (!value) {
            inputValidation[key] = true;
            validationLength++;
        }
    }

    return { inputValidation, validationLength };
};

// select에 보여지는 placeholder
// return ~외 1
export const displaySelectPlaceholder = (list, t, label = 'label') => {
    if (list.length > 1) {
        return `${list[0][label]} ${t('and')} ${list.length - 1} ${t('more')}`;
    } else {
        return `${list[0][label]}`;
    }
};

//phone number 입력 포맷 체크
export const checkPhoneNumberFormat = phoneNum => {
    const phoneRG = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/;

    return phoneNum.match(phoneRG);
};

//email 입력 포맷 체크
export const checkEmailFormat = email => {
    const emailRE = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

    return email.toLowerCase().match(emailRE);
};

//사업장 등록 - 사업장 코드 포맷 체크
export const checkWorkPlaceCodeFormat = workPlaceCode => {
    const workPlaceCodeRE = /^[A-Za-z0-9 ]+$/;

    return workPlaceCode.toLowerCase().match(workPlaceCodeRE);
};

//인풋 텍스트 길이 체크
export const checkValidInputLength = (input, validInputLength) => {
    return input.length > validInputLength;
};

export const nullToZero = value => {
    return value ? value : 0;
};
