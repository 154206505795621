import React, { useContext } from 'react';

import cx from 'classnames';

import { TiWarning } from 'react-icons/ti';
import { AiFillAlert } from 'react-icons/ai';

import useTranslation from '@hooks/useTranslation';

import { WorkStatusContext } from 'Components/MainPages/WorkerSafetyDashboard';
import { nullishToHyphen } from '../../../../Components/utils';

const WorkerList = ({ person }) => {
    const t = useTranslation('Work Status');

    const { selectedWorker } = useContext(WorkStatusContext);

    const { sos, manDown, teamId, name, phoneNumber, healthGrade } = person;

    return (
        <div
            className={'overview_tab_list mb-2 pb-2'}
            key={person.targetNum}
            // onClick={handleSelectedWorker}
        >
            <div
                className={cx(
                    'overview_tab_list__content',
                    selectedWorker && person.targetNum === selectedWorker.targetNum && 'selected',
                )}
            >
                <div style={{ width: '100%' }}>
                    {sos && (
                        <div className={'emergency-label bg-danger'}>
                            <AiFillAlert color="white" />
                            <div className={'color-white ml-1 pnt-txt txt-small'}>{t('SOS')}</div>
                        </div>
                    )}

                    {manDown && (
                        <div className={'emergency-label bg-danger'}>
                            <TiWarning color="white" />
                            <div className={'color-white ml-1 pnt-txt txt-small'}>{t('Man Down')}</div>
                        </div>
                    )}

                    {/*{isGasAlert && (*/}
                    {/*    <div className={'emergency-label bg-warning'}>*/}
                    {/*        <TiWarning color="white" />*/}
                    {/*        <div className={'color-black ml-1 mr-1 pnt-txt txt-small'}>{'가스위험'}</div>*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    <div className={'list-name'}>
                        <div>{name}</div>
                        <div>{`${t('Company')}: ${teamId ? teamId : '-'}`}</div>
                        <div className="birthday font-size-md">
                            ({nullishToHyphen(healthGrade)},{nullishToHyphen(phoneNumber)})
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkerList;
