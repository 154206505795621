import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { Card } from '@components';
import { useTranslation } from '@hooks';

import { WorkStatusContext, WorkStatusDispatchContext } from 'Components/MainPages/WorkerSafetyDashboard';
import { setSelectedFloor } from 'Components/MainPages/WorkerSafetyDashboard/workerStatusReducer';

import styled from 'styled-components';
import cx from 'classnames';

const StatusByFloor = () => {
    const t = useTranslation('FloorStatus');

    const dispatch = useContext(WorkStatusDispatchContext);
    const { smartSafeFloorList } = useSelector(State => State.FloorInfo);
    const { floorInfo, selectedFloor } = useContext(WorkStatusContext);

    return (
        <Card
            header={{
                title: t('Status by Floor'),
            }}
            className="status-by-floor"
            bodyClassName="p-1"
        >
            <div
                className={cx(
                    'd-flex flex-column h-100',
                    !floorInfo.length && 'align-items-center justify-content-center',
                )}
            >
                {/* 층 정보 역순으로 배열 */}
                {floorInfo.length ? (
                    floorInfo
                        .slice()
                        .reverse()
                        .map(floor => {
                            const { floorName, floorId, enclosedWorker, worker } = floor;

                            const selectedFloorInfo = smartSafeFloorList.filter(floorInfo => {
                                return floorInfo.floorId === floorId;
                            });

                            const handleFloorClick = () => {
                                if (selectedFloorInfo[0]) {
                                    dispatch(setSelectedFloor(selectedFloorInfo[0]));
                                }
                            };

                            return (
                                <StyledFloor
                                    key={floorId}
                                    selected={floorId === selectedFloor.floorId}
                                    onClick={handleFloorClick}
                                >
                                    <div title={floorName}>
                                        <div className="text-ellipsis">{floorName}</div>
                                    </div>
                                    <div
                                        title={`${t('Worker (All)')} : ${worker}\n${t(
                                            'Worker (Enclosed Area)',
                                        )} : ${enclosedWorker}`}
                                    >
                                        <div className="overflow-hidden">
                                            <div className="text-ellipsis">{`${t('Worker (A)')} : ${worker}`}</div>
                                            <div className="text-ellipsis">{`${t(
                                                'Worker (C)',
                                            )} : ${enclosedWorker}`}</div>
                                        </div>
                                    </div>
                                </StyledFloor>
                            );
                        })
                ) : (
                    <div>{t('Floor does not exist.')}</div>
                )}
            </div>
        </Card>
    );
};

const StyledFloor = styled.div`
    display: flex;
    flex: 1;
    border: 0.5px solid rgba(198, 198, 231, 0.5);
    border-top: 0;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: ${({ selected }) => (selected ? 'rgba(198, 198, 231, 0.5)' : 'initial')};
    &:hover {
        background-color: rgba(198, 198, 231, 0.7);
        cursor: pointer;
    }
    &:first-child {
        border-top: 0.5px solid rgba(198, 198, 231, 0.5);
    }
    > div {
        display: flex;
        align-items: center;
        overflow: hidden;
    }
    > div:first-child {
        flex: 4;
        font-size: 1.2rem;
    }
    > div:nth-child(2) {
        display: flex;
        flex: 3;
        font-size: 0.8rem;
    }
`;

export default StatusByFloor;
