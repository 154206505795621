import TagStatus from './TagStatus';
import TagStatusSetting from './TagStatus/TagStatusSetting';
import TagStateAlarmList from './TagStateAlarmList';
import TemporaryWidget from './TemporaryWidget';
import CategoryChart from './CategoryChart';
import CategoryChartSetting from './CategoryChart/CategoryChartSetting';
import TagStateAlarmListSetting from './TagStateAlarmList/TagStateAlarmListSetting';
import FloorSummary from './FloorSummary';
import FloorSummarySetting from './FloorSummary/FloorSummarySetting';
import GeofenceStatus from './GeofenceStatus';
import PatientAbnormalSigns from './PatientAbnormalSigns';
import PatientState from './PatientState';
import GeofenceStatusSetting from './GeofenceStatus/GeofenceStatusSetting';
import IotItemStatus from './IotItemStatus';
import AssetLocationStatus from './AssetLocationStatus';
import AssetStatusGrid from './AssetStatusGrid';
import AssetChart from './AssetChart';
import AssetChartSetting from './AssetChart/AssetChartSetting';
import TagBatteryStatus from './TagBatteryStatus';
import ScannerStatus from './ScannerStatus';
import IotItemCountStatus from './IotItemCountStatus';
import ItemDetailStatus from './ItemDetailStatus';
import FloorPermissionStatus from './FloorPermissionStatus';
import ItemDetailStatusSetting from './ItemDetailStatus/ItemDetailStatusSetting';
import GeofenceCongestionStatus from './GeofenceCongestionStatus';
import TriggeredAlertStatus from './TriggeredAlertStatus';
import RealTimeLocationStatus from './RealTimeLocationStatus';
import RealTimeLocationSetting from './RealTimeLocationStatus/RealTimeLocationSetting';
import RealTimeSensorMonitoring from './RealTimeSensorMonitoring';
import RealTimeTagMonitoringSetting from './RealTimeTagMonitoring/RealTimeTagMonitoringSetting';
import RealTimeTagMonitoring from './RealTimeTagMonitoring';
import MassRealTimeLocationStatus from './MassRealTimeLocationStatus';
import CongestionStatus from './CongestionStatus';
import CongestionStatusSetting from './CongestionStatus/Component/CongestionStatusSetting';
import TopFiveCongestedGeofence from './TopFiveCongestedGeofence';
import TopFiveCongestedGeofenceSetting from './TopFiveCongestedGeofence/Component/TopFiveCongestedGeofenceSetting';
import HourlyCongestionStatus from './HourlyCongestionStatus';
import HourlyCongestionStatusSetting from './HourlyCongestionStatus/Component/HourlyCongestionStatusSetting';
import IotItemStatusSetting from './IotItemStatus/IotItemStatusSetting';
import SensorStatus from './SensorStatus';
import SensorStatusSetting from './SensorStatus/SensorStatusSetting';
import AnalysisHeatMap from './AnalysisHeatMap';
import { WIDGET_CATEGORY_DASHBOARD, WIDGET_CATEGORY_MANUAL_SEARCH, WIDGET_CATEGORY_MONITORING } from './staticInfo';

export const tagStatus = {
    type: 'tagStatus',
    category: WIDGET_CATEGORY_DASHBOARD,
    name: 'Overview Of Tag Status',
    // name: 'Tag Status',
    desc:
        'The graph shows the beacon in use, the lost signal beacon, the beacon in the SOS state, the beacon in the low battery state, and the beacon that has not moved for more than 1 minute.',
    thumbnailURL: null,
    Component: TagStatus,
    SettingComponent: TagStatusSetting,
    config: {
        defaultSettings: {
            name: 'Overview Of Tag Status',
            // intervalTime: 10000,
        },
    },
    // config: { defaultSettings: { name: 'Tag Status', intervalTime: 10000 } },
    layout: {
        minW: 6,
        w: 10,
        maxW: 13,
        minH: 10,
        h: 12,
        maxH: 14,
    },
};
export const categoryChart = {
    type: 'categoryChart',
    category: WIDGET_CATEGORY_DASHBOARD,
    name: 'Category Chart',
    // name: 'Category Chart',
    desc: 'It shows the ratio of registered targets by category in charts.',
    thumbnailURL: null,
    Component: CategoryChart,
    SettingComponent: CategoryChartSetting,
    config: {
        defaultSettings: {
            name: 'Category Chart',
            // intervalTime: 10000,
            chartType: 'pie',
        },
    },
    // config: { defaultSettings: { name: 'Category Chart', intervalTime: 10000, chartType: 'pie' } },
    layout: {
        minW: 2,
        w: 4,
        maxW: 5,
        minH: 15,
        h: 18,
        maxH: 20,
    },
};
export const tagStateAlarmList = {
    type: 'tagStateAlarmList',
    category: WIDGET_CATEGORY_DASHBOARD,
    name: 'List Of Triggered Event',
    // name: 'Tag State Alarm',
    desc: 'It monitors the status of tags.',
    thumbnailURL: null,
    Component: TagStateAlarmList,
    SettingComponent: TagStateAlarmListSetting,
    config: {
        defaultSettings: {
            name: 'List Of Triggered Event',
            // , intervalTime: 10000
        },
    },
    // config: { defaultSettings: { name: 'Tag State Alarm', intervalTime: 10000 } },
    layout: {
        minW: 6,
        w: 8,
        maxW: 11,
        minH: 18,
        h: 22,
        maxH: 26,
    },
};
export const floorSummary = {
    type: 'floorSummary',
    category: WIDGET_CATEGORY_DASHBOARD,
    name: 'Summary Of Floor',
    // name: 'Floor Status',
    desc:
        'It shows the list of registered floors. If you choose a specific floor, the map will appear for the selected floor.',
    thumbnailURL: null,
    Component: FloorSummary,
    SettingComponent: FloorSummarySetting,
    config: {
        defaultSettings: {
            name: 'Summary Of Floor',
            // intervalTime: 10000,
            floor: '',
        },
    },
    // config: { defaultSettings: { name: 'Floor Status', intervalTime: 10000, floor: '' } },
    layout: {
        w: 6,
        h: 21,
        minW: 4,
        minH: 15,
    },
};

export const geofenceStatus = {
    type: 'geofenceStatus',
    category: WIDGET_CATEGORY_DASHBOARD,
    name: 'Geofence Status',
    // name: 'Geofence Status',
    desc: 'The map shows the geofence for the selected floor.',
    thumbnailURL: null,
    Component: GeofenceStatus,
    SettingComponent: GeofenceStatusSetting,
    config: {
        defaultSettings: {
            name: 'Geofence Status',
            // intervalTime: 10000
        },
    },
    // config: { defaultSettings: { name: 'Geofence Status', intervalTime: 10000 } },
    layout: {
        minW: 4,
        w: 6,
        maxW: 9,
        minH: 21,
        h: 27,
        maxH: 35,
    },
};

// 가장 혼잡합 지오펜스 Top 5
export const geofenceCongestionStatus = {
    type: 'geofenceCongestionStatus',
    category: WIDGET_CATEGORY_DASHBOARD,
    name: 'Top 5 Most Congested Geofence',
    desc: 'It shows the chart and the table describing the top 5 geofence with most number of entry event today.',
    thumbnailURL: null,
    Component: GeofenceCongestionStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Top 5 Most Congested Geofence', intervalTime: 20000 } },
    layout: {
        minW: 4,
        w: 5,
        maxW: 6,
        minH: 25,
        h: 30,
        maxH: 33,
    },
};
export const patientAbnormalSigns = {
    type: 'patientAbnormalSigns',
    name: '환자 이상 징후',
    // name: 'Patient Abnormal Signs',
    desc: '환자 이상 징후를 감시합니다.',
    thumbnailURL: null,
    Component: PatientAbnormalSigns,
    SettingComponent: null,
    config: { defaultSettings: { name: '환자 이상 징후', intervalTime: 10000 } },
    layout: {
        w: 6,
        h: 27,
        minW: 5,
        minH: 21,
    },
};
export const patientState = {
    type: 'patientState',
    name: '환자 상태 현황',
    desc: '환자 상태를 감시합니다.',
    thumbnailURL: null,
    Component: PatientState,
    SettingComponent: null,
    config: { defaultSettings: { name: '환자 상태 현황', intervalTime: 10000 } },
    layout: {
        w: 6,
        h: 27,
        minW: 5,
        minH: 21,
    },
};
//자산 현황
export const iotItemStatus = {
    type: 'iotItemStatus',
    category: WIDGET_CATEGORY_MONITORING,
    name: 'Iot Item Status',
    desc: 'It shows the current status of the assets selecting all of them or some of them by their status.',
    thumbnailURL: null,
    Component: IotItemStatus,
    SettingComponent: IotItemStatusSetting,
    config: { defaultSettings: { name: 'Iot Item Status', intervalTime: 10000, categoryCode: 'ASSET' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};
export const assetLocationStatus = {
    type: 'assetLocationStatus',
    name: '자산 위치 현황',
    desc: '자산 위치 현황을 감시합니다.',
    thumbnailURL: null,
    Component: AssetLocationStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: '자산 위치 현황', intervalTime: 10000 } },
    layout: {
        w: 6,
        h: 27,
        minW: 4,
        minH: 15,
    },
};
export const assetStatusGrid = {
    type: 'assetStatusGrid',
    name: '자산 상태 상세 현황',
    desc: '자산 상태 상세 현황을 감시합니다.',
    thumbnailURL: null,
    Component: AssetStatusGrid,
    SettingComponent: null,
    config: { defaultSettings: { name: '자산 상태 상세 현황', intervalTime: 10000 } },
    layout: {
        w: 6,
        h: 25,
        minW: 4,
        minH: 15,
    },
};
export const assetChart = {
    type: 'assetChart',
    name: '자산 현황 차트',
    desc: '자산 현황을 차트 형태로 감시합니다.',
    thumbnailURL: null,
    Component: AssetChart,
    SettingComponent: AssetChartSetting,
    config: { defaultSettings: { name: 'Asset Status Chart', intervalTime: 10000, dataType: 'scanner' } },
    layout: {
        w: 4,
        h: 21,
        minW: 2,
        minH: 12,
    },
};

export const tagBatteryStatus = {
    type: 'tagBatteryStatus',
    category: WIDGET_CATEGORY_DASHBOARD,
    name: 'Tag Battery Status',
    desc: 'It shows battery information of all tags with icons and tables.',
    thumbnailURL: null,
    Component: TagBatteryStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Tag Battery Status', intervalTime: 20000 } },
    layout: {
        minW: 3,
        w: 4,
        maxW: 6,
        minH: 23,
        h: 25,
        maxH: 29,
    },
};
export const scannerStatus = {
    type: 'scannerStatus',
    category: WIDGET_CATEGORY_DASHBOARD,
    name: 'Scanner Status',
    desc: 'It shows the number of all the online or the offline registered scanners.',
    thumbnailURL: null,
    Component: ScannerStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Scanner Status', intervalTime: 20000 } },
    layout: {
        minW: 3,
        w: 4,
        maxW: 6,
        minH: 20,
        h: 23,
        maxH: 28,
    },
};
export const iotItemCountStatus = {
    type: 'iotItemCountStatus',
    category: WIDGET_CATEGORY_DASHBOARD,
    name: 'Number Of All IoT Item',
    desc: 'It shows the number of IoT Items that the user has registered in graphs and tables.',
    thumbnailURL: null,
    Component: IotItemCountStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Number Of All IoT Item', intervalTime: 20000 } },
    layout: {
        minW: 3,
        w: 4,
        maxW: 6,
        minH: 20,
        h: 23,
        maxH: 26,
    },
};

// WMS상 카테고리별 자산 , 사용자 상세 정보
export const itemDetailStatus = {
    type: 'itemDetailStatus',
    category: WIDGET_CATEGORY_DASHBOARD,
    name: 'IoT Item Details By Category',
    desc: 'The chart and the table shows the current Top 5 by category of assets and people.',
    thumbnailURL: null,
    Component: ItemDetailStatus,
    SettingComponent: ItemDetailStatusSetting,
    config: { defaultSettings: { name: 'IoT Item Details By Category', intervalTime: 20000, categoryCode: 'ASSET' } },
    layout: {
        minW: 4,
        w: 5,
        maxW: 6,
        minH: 25,
        h: 30,
        maxH: 33,
    },
};
export const floorPermissionStatus = {
    type: 'floorPermissionStatus',
    category: WIDGET_CATEGORY_DASHBOARD,
    name: 'Permitted/Restricted Floor Graph of Tag',
    // name: 'Tag Status',
    desc: 'The graph shows the permitted/restricted number of beacons for each floor.',
    thumbnailURL: null,
    Component: FloorPermissionStatus,
    SettingComponent: null,
    config: {
        defaultSettings: {
            name: 'Permitted/Restricted Floor Graph of Tag', //, intervalTime: 10000
        },
    },
    // config: { defaultSettings: { name: 'Tag Status', intervalTime: 10000 } },
    layout: {
        minW: 7,
        w: 12,
        maxW: 16,
        minH: 14,
        h: 16,
        maxH: 18,
    },
};

export const triggeredAlertStatus = {
    type: 'triggeredAlertStatus',
    category: WIDGET_CATEGORY_DASHBOARD,
    name: 'Triggered Alarm Status',
    desc: 'It shows the type and the number of notifications that occurred today in graphs and tables.',
    thumbnailURL: null,
    Component: TriggeredAlertStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Triggered Alarm Status', intervalTime: 20000 } },
    layout: {
        minW: 5,
        w: 7,
        maxW: 12,
        minH: 22,
        h: 23,
        maxH: 25,
    },
};

//실시간 위치현황
export const realTimeLocationStatus = {
    type: 'realTimeLocationStatus',
    category: WIDGET_CATEGORY_MONITORING,
    name: 'Realtime Location Status',
    desc:
        'The map shows the targets that are located in the selected floor or in the geofence. You can also search a specific target and get detailed information about that specific target.',
    thumbnailURL: null,
    Component: RealTimeLocationStatus,
    SettingComponent: RealTimeLocationSetting,
    config: { defaultSettings: { name: 'Realtime Location Status', targetOption: 'ALL TARGET', floor: '' } },
    layout: {
        minW: 6,
        w: 8,
        maxW: 12,
        minH: 23,
        h: 30,
        maxH: 45,
    },
};

//실시간 센서 모니터링
export const realTimeSensorMonitoring = {
    type: 'realTimeSensorMonitoring',
    category: WIDGET_CATEGORY_MONITORING,
    name: 'Realtime Sensor Monitoring',
    desc: 'It monitors the registered sensors.',
    thumbnailURL: null,
    Component: RealTimeSensorMonitoring,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Realtime Sensor Monitoring' } },
    layout: {
        minW: 6,
        w: 8,
        maxW: 12,
        minH: 23,
        h: 27,
        maxH: 39,
    },
};

// 실시간 태그 모니터링
export const realTimeTagMonitoring = {
    type: 'realTimeTagMonitoring',
    category: WIDGET_CATEGORY_MONITORING,
    name: 'Realtime Tag Monitoring',
    desc: 'It monitors the registered tags in a line chart format.',
    thumbnailURL: null,
    Component: RealTimeTagMonitoring,
    SettingComponent: RealTimeTagMonitoringSetting,
    config: { defaultSettings: { name: 'Realtime Tag Monitoring', tagOption: 20, DataInteger: false } },
    layout: {
        minW: 8,
        w: 10,
        maxW: 12,
        minH: 20,
        h: 26,
        maxH: 38,
    },
};
//대량 실시간 위치현황
export const massRealTimeLocationStatus = {
    type: 'massRealTimeLocationStatus',
    category: WIDGET_CATEGORY_MONITORING,
    name: 'Mass RealTime Location Status',
    desc: 'It marks the map with a large number of positioning targets on the currently selected layer or geofence.',
    thumbnailURL: null,
    Component: MassRealTimeLocationStatus,
    SettingComponent: RealTimeTagMonitoringSetting,
    config: { defaultSettings: { name: 'Mass RealTime Location Status', targetOption: 'ALL TARGET', floor: '' } },
    layout: {
        minW: 8,
        w: 10,
        maxW: 12,
        minH: 20,
        h: 26,
        maxH: 38,
    },
};
//분석 - 히트맵
export const heatMap = {
    type: 'heatMap',
    category: 'Monitoring',
    name: 'Heat Map',
    desc: 'It monitors the registered tags in a line chart format.',
    thumbnailURL: null,
    Component: AnalysisHeatMap,
    //SettingComponent: RealTimeTagMonitoringSetting,
    config: { defaultSettings: { name: 'Heat Map', tagOption: 20, DataInteger: false } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 20,
        h: 40,
        maxH: 38,
    },
};

// 혼잡도 위젯
export const congestionStatus = {
    type: 'congestionStatus',
    category: WIDGET_CATEGORY_MANUAL_SEARCH,
    name: 'Congestion Status',
    desc: 'It displays congestion of each geofence',
    thumbnailURL: null,
    Component: CongestionStatus,
    SettingComponent: CongestionStatusSetting,
    config: { defaultSettings: { name: 'Congestion Status' } },
    layout: {
        minW: 8,
        w: 10,
        maxW: 12,
        minH: 40,
        h: 40,
        maxH: 50,
    },
};

// 혼잡한 지오펜스 top 5 위젯
export const topFiveCongestedGeofence = {
    type: 'topFiveCongestedGeofence',
    category: WIDGET_CATEGORY_MANUAL_SEARCH,
    name: 'Top five congested geofence',
    desc: 'It shows the top five crowded geofence by category or authorization',
    thumbnailURL: null,
    Component: TopFiveCongestedGeofence,
    SettingComponent: TopFiveCongestedGeofenceSetting,
    config: { defaultSettings: { name: 'Top five congested geofence', type: 'category' } },
    layout: {
        minW: 8,
        w: 10,
        maxW: 12,
        minH: 20,
        h: 20,
        maxH: 50,
    },
};

// 시간별 혼잡도
export const hourlyCongestionStatus = {
    type: 'hourlyCongestionStatus',
    category: WIDGET_CATEGORY_MANUAL_SEARCH,
    name: 'Hourly congestion status',
    desc: 'This widget shows congestion status hourly',
    thumbnailURL: null,
    Component: HourlyCongestionStatus,
    SettingComponent: HourlyCongestionStatusSetting,
    config: { defaultSettings: { name: 'Hourly congestion status' } },
    layout: {
        minW: 8,
        w: 10,
        maxW: 12,
        minH: 50,
        h: 55,
        maxH: 60,
    },
};

// 센서 현황
export const sensorStatus = {
    type: 'sensorStatus',
    category: 'Dashboard',
    name: 'Sensor Status',
    desc: 'This widget shows the status of the sensors',
    thumbnailURL: null,
    Component: SensorStatus,
    SettingComponent: SensorStatusSetting,
    config: { defaultSettings: { name: 'Sensor Status', intervalTime: 10000 } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};
// export const testComponent = {
//     type: 'testComponent',
//     // name: '테스트 위젯',
//     name: '테스트 위젯',
//     desc: '테스트 위젯',
//     thumbnailURL: null,
//     Component: TestComponent,
//     SettingComponent: null,
//     config: { defaultSettings: { name: '테스트 위젯' } },
//     layout: {
//         w: 6,
//         h: 27,
//         minW: 4,
//         minH: 21,
//     },
// };
export const temp = {
    type: 'temp',
    name: 'Widget Name',
    desc: '',
    thumbnailURL: null,
    Component: TemporaryWidget,
    SettingComponent: null,
    config: { defaultSettings: { name: 'New widget' } },
    layout: {
        w: 4,
        h: 15,
        minW: 2,
        minH: 9,
    },
};

const widgetList = {
    iotItemStatus,
    // assetLocationStatus,
    // assetStatusGrid,
    // assetChart,
    tagStatus,
    categoryChart,
    tagStateAlarmList,
    // floorSummary,
    geofenceStatus,
    geofenceCongestionStatus,
    // patientAbnormalSigns,
    // patientState,
    tagBatteryStatus,
    scannerStatus,
    iotItemCountStatus,
    itemDetailStatus,
    floorPermissionStatus,
    triggeredAlertStatus,
    realTimeLocationStatus,
    realTimeSensorMonitoring,
    congestionStatus,
    topFiveCongestedGeofence,
    hourlyCongestionStatus,
    sensorStatus,
    heatMap,
    // testComponent,
    temp,
    realTimeTagMonitoring,
    massRealTimeLocationStatus,
};

export default widgetList;
