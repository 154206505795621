import moment from 'moment';

import { getLsUserInfo } from '@util/common/util';

import envKeys from 'environment';
import { createSSOForm } from 'Components/Layout/AppNav/CustomLink';

const { cryptoIv } = envKeys;

export const getDate = value => {
    return value ? moment(value).format('MM.DD HH:mm:ss') : '-';
};

export const postLogin = targetNum => {
    const serviceCode = cryptoIv.split('_')[2];
    // wmsLogin({ serviceCode, enOAuthInfo: encodeInfo(oAuthInfo) });
    const userInfo = getLsUserInfo();
    if (userInfo) {
        const location = `/beacon/targetinfo/mform/worker.do?targetNum=${targetNum}`;
        createSSOForm(serviceCode, userInfo.oAuthInfo, location);
    }
};

export const sensorHeader = ({ t, headerName, mappedCount, totalCount }) => {
    return `${t(headerName)} (${mappedCount}${t('Count')} ${t('Mapped')} / ${t('Total')} ${totalCount}${t('Count')})`;
};

export const nullToHyphen = value => {
    return value !== 0 && !value ? '-' : value;
};

export const nullishToHyphen = value => {
    return value ? value : '-';
};

export const workerStatusHeader = ({ t, headerName, enclosedWorkerCount, workerCount }) => {
    return `${t(headerName)} ( ${t('Sealed')} ${enclosedWorkerCount}${t('Count')} / ${t('Total')} ${workerCount}${t(
        'Count',
    )} )`;
};
