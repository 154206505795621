import React, { useContext, useEffect, useRef, useState } from 'react';
import { Map, Card, Label, RotatedImageOverlay, GeofenceLayer } from '../../../Common';
import styled from 'styled-components';
import { useTranslation, useAsync } from '../../../../util/hooks';
import { IotItemStatusStateContext } from '../index';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { fetchGeofenceList } from '../../../../api/common';
import { useSelector } from 'react-redux';

const Container = styled.div`
    display: grid;
    height: 100%;
    grid-gap: 10px;
    grid-template-columns: 30% 70%;
`;

const IotItemStatusDetails = () => {
    const t = useTranslation('IotItemStatus');
    const mapRef = useRef();

    const { categoryPropertiesList } = useSelector(state => state.CategoryInfo);

    const [geofenceInfo, setGeofenceInfo] = useState([]);

    const { selectedIotItem, floorInfo } = useContext(IotItemStatusStateContext);
    const { promise: getGeofenceList } = useAsync({
        promise: fetchGeofenceList,
        resolve: res => {
            const geofenceList = res.rows.map(geofence => {
                return {
                    ...geofence,
                    bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])],
                };
            });
            setGeofenceInfo(geofenceList);
        },
    });

    useEffect(() => {
        if (selectedIotItem) {
            getGeofenceList({ floor: selectedIotItem.floorId });
        }
    }, [selectedIotItem]);

    return (
        <Container>
            <Card>
                <div className={'flx-col gap-4 pnt-label-5'}>
                    <Label name={t('ID')} value={selectedIotItem.targetId} />
                    <Label name={t('Name')} value={selectedIotItem.targetName} />
                    <Label name={t('Category')} value={selectedIotItem.categoryCode} />
                    {selectedIotItem.properties &&
                        Object.entries(selectedIotItem.properties).map(([name, value]) => {
                            const propertyInfo = categoryPropertiesList.find(({ propertyId }) => propertyId === name);
                            if (propertyInfo.isListProperty === 'Y') {
                                return <Label key={name} name={propertyInfo.displayName} value={value} />;
                            }
                            return null;
                        })}
                </div>
            </Card>
            <Card bodyClassName={'p-1'}>
                <Map ref={mapRef}>
                    {floorInfo && floorInfo.imgURL && (
                        <RotatedImageOverlay
                            key={floorInfo.floorId}
                            url={floorInfo.imgURL}
                            deg={floorInfo.deg}
                            bounds={floorInfo.bounds}
                            onLoad={() => {
                                const map = mapRef.current.leafletElement;
                                if (map) {
                                    map.setView([selectedIotItem.lat, selectedIotItem.lng]);
                                }
                            }}
                        />
                    )}

                    <Marker
                        key={selectedIotItem.targetId}
                        position={[selectedIotItem.lat, selectedIotItem.lng]}
                        icon={L.divIcon({
                            className: 'custom-asset-marker',
                            html: `<div></div>`,
                        })}
                    />

                    {selectedIotItem.floorId && geofenceInfo.length > 0 && (
                        <GeofenceLayer geofenceList={geofenceInfo} />
                    )}
                </Map>
            </Card>
        </Container>
    );
};

export default IotItemStatusDetails;
