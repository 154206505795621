export const categoryLabel = {
    ASSET: 'Asset',
    PEOPLE: 'People',
    SENSOR: 'Sensor',
};

// 발생 타입
export const occurStateInfo = {
    sos: { SOS_CHANGE_ON: true, SOS_KEEP_ON: true },
    battery: { BATTERY_CHANGE_LOW: true, BATTERY_KEEP_LOW: true },
    leaveAlone: { LEAVEALONE_CHANGE_ON: true },
    longStay: { LONGSTAY_CHANGE_ON: true, LONGSTAY_KEEP_ON: true },
    lostSignal: { LOSTSIGNAL_ON: true },
    fireAlarm: { FIREALRM_CHANGE_ON: true, FIREALRM_KEEP_ON: true },
    restriction: { RESTRICTION_CHANGE_ON: true, RESTRICTION_KEEP_ON: true },
    nobodyAround: { NOBODY_AROUND_ON: true },
    fall: { FALL_KEEP_ON: true },
    heartAttack: { HEARTATTACK_ON: true },
    //worker Safety
    manDown: { MAN_DOWN_CHANGE_ON: true, MAN_DOWN_KEEP_ON: true },
    loneWorker: { LONE_WORKER_CHANGE_ON: true, LONE_WORKER_KEEP_ON: true },
    restrictedEntry: { RESTRICTED_ENTRY_CHANGE_ON: true, RESTRICTED_ENTRY_KEEP_ON: true },
    lowBattery: { LOW_BATTERY_CHANGE_ON: true, LOW_BATTERY_KEEP_ON: true },
    rtlsErr: { RTLS_ERROR_CHANGE_ON: true, RTLS_ERROR_KEEP_ON: true },
    beaconErr: { BEACON_ERROR_CHANGE_ON: true, BEACON_ERROR_KEEP_ON: true },
    sensingErr: { SENSING_ERROR_CHANGE_ON: true, SENSING_ERROR_KEEP_ON: true },
    biosignalAbnormality: { BIOSIGNAL_ABNORMALITY_CHANGE_ON: true, BIOSIGNAL_ABNORMALITY_KEEP_ON: true },
};

// 해제 타입
export const recoveryStateInfo = {
    sos: { SOS_CHANGE_OFF: true, SOS_KEEP_OFF: true },
    battery: { BATTERY_UNKNOW: true, BATTERY_CHANGE_NORMAL: true, BATTERY_KEEP_NORMAL: true },
    leaveAlone: { LEAVEALONE_CHANGE_OFF: true, LEAVEALONE_KEEP_OFF: true },
    longStay: { LONGSTAY_CHANGE_OFF: true, LONGSTAY_KEEP_OFF: true },
    lostSignal: { LOSTSIGNAL_OFF: true, LOSTSIGNAL_KEEP_OFF: true },
    fireAlarm: { FIREALRM_CHANGE_OFF: true, FIREALRM_KEEP_OFF: true },
    restriction: { RESTRICTION_CHANGE_OFF: true, RESTRICTION_KEEP_OFF: true },
    nobodyAround: { NOBODY_AROUND_OFF: true },
    fall: { FALL_KEEP_OFF: true },
    heartAttack: { HEARTATTACK_OFF: true },
    //worker Safety
    manDown: { MAN_DOWN_CHANGE_OFF: true, MAN_DOWN_KEEP_OFF: true },
    loneWorker: { LONE_WORKER_CHANGE_OFF: true, LONE_WORKER_KEEP_OFF: true },
    restrictedEntry: { RESTRICTED_ENTRY_CHANGE_OFF: true, RESTRICTED_ENTRY_KEEP_OFF: true },
    lowBattery: { LOW_BATTERY_CHANGE_OFF: true, LOW_BATTERY_KEEP_OFF: true },
    rtlsErr: { RTLS_ERROR_CHANGE_OFF: true, RTLS_ERROR_KEEP_OFF: true },
    beaconErr: { BEACON_ERROR_CHANGE_OFF: true, BEACON_ERROR_KEEP_OFF: true },
    sensingErr: { SENSING_ERROR_CHANGE_OFF: true, SENSING_ERROR_KEEP_OFF: true },
    biosignalAbnormality: { BIOSIGNAL_ABNORMALITY_CHANGE_OFF: true, BIOSIGNAL_ABNORMALITY_KEEP_OFF: true },
};

export const tagStateLabel = {
    sos: 'SOS',
    battery: 'Low Battery',
    leaveAlone: 'Leave Alone',
    longStay: 'Long Stay',
    lostSignal: 'Lost Signal',
    fireAlarm: 'Fire Alarm',
    restriction: 'Restriction',
    fall: 'Fall',
    nobodyAround: 'Nobody Around',
};

export const tagAlarmLabel = {
    sos: 'SOS',
    restricted: 'Restricted',
    longStay: 'Long Stay',
    outOfRange: 'Out Of Range',
    lostSignal: 'Lost Signal',
    battery: 'Low Battery',
    nobodyAround: 'Nobody Around',
};

export const isIn = state => {
    switch (state) {
        case 'OUT_PROCESSING':
        case 'IN':
        case 'STAY_PROCESSING':
        case 'STAY':
        case 'STAYING':
            return true;
        default:
            return false;
    }
};

export const isDanger = type => {
    return [
        'FLOOR_OUT',
        'GEOFENCE_OUT',
        'RESTRICTION_IN',
        'LOW_BATTER',
        'LOST_SIGNAL',
        'SOS_ON',
        'MANDOWN_ON',
        'MAN_DOWN_ON',
        'LEAVEALONE_ON',
        'LONGSTAY_ON',
        'SENSOR_WARNING',
        'SENSOR_CRITICAL',
        'SENSOR_OUTOFRANGE',
        'HEARTATTACK_ON',
        'SOS_CHANGE_ON',
        'SOS_KEEP_ON',
        'MAN_DOWN_CHANGE_ON',
        'MAN_DOWN_KEEP_ON',
        'RESTRICTED_ENTRY_CHANGE_ON',
        'RESTRICTED_ENTRY_KEEP_ON',
        'LONE_WORKER_CHANGE_ON',
        'LONE_WORKER_KEEP_ON',
        'BIOSIGNAL_ABNORMALITY_CHANGE_ON',
        'BIOSIGNAL_ABNORMALITY_KEEP_ON',
        'LOW_BATTERY_CHANGE_ON',
        'LOW_BATTERY_KEEP_ON',
        'RTLS_ERROR_CHANGE_ON',
        'RTLS_ERROR_KEEP_ON',
        'BEACON_ERROR_CHANGE_ON',
        'BEACON_ERROR_KEEP_ON',
        'SENSING_ERROR_CHANGE_ON',
        'SENSING_ERROR_KEEP_ON',
    ].includes(type);
};

export const getPermittedState = permitted => {
    return permitted ? 'authorized' : 'unAuthorized';
};
