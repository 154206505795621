import { reqGet, reqDelete, reqPatch, reqPost } from './index';

////층 목록 조회
export const getFloorListApi = param => reqGet('/v3/api/ws01/floor', { isAll: 'Y', isSelf: 'Y', ...param });

////구역 관리
//구역 단일 조회
export const getWorkAreaApi = param => reqGet(`/v3/api/ws01/geofence/${param.fcNum}`, param);
//구역 목록 조회
export const getWorkAreaListApi = param => reqGet(`/v3/api/ws01/geofence`, param);
//구역 등록
export const createWorkAreaApi = param => reqPost('/v3/api/ws01/geofence/', param);
//구역 수정
export const updateWorkAreaApi = param => reqPatch(`/v3/api/ws01/geofence/${param.fcNum}`, param);
//구역 삭제
export const deleteWorkAreaApi = param => reqDelete(`/v3/api/ws01/geofence/${param.fcNum}`);
