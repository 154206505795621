const EVT_TYPE = {
    FLOOR_IN: 'Floor In',
    FLOOR_OUT: 'Floor Out',
    GEOFENCE_IN: 'Geofence In',
    GEOFENCE_OUT: 'Geofence Out',
    RESTRICTED_ENTRY: 'Restricted Entry',
    RESTRICTION_IN: 'Restriction In',
    RESTRICTION_OUT: 'Restriction Out',
    LONE_WORKER: 'Lone Worker',
    LOW_BATTERY: 'Low Battery',
    LOST_SIGNAL: 'Lost Signal',
    SOS_ON: 'SOS',
    SOS_OFF: 'SOS Off',
    SOS_CHANGE_ON: 'SOS On',
    SOS_CHANGE_OFF: 'SOS Off',
    MANDOWN_ON: 'ManDown',
    MAN_DOWN_CHANGE_ON: 'Man Down On',
    MAN_DOWN_CHANGE_OFF: 'Man Down Off',
    RESTRICTED_ENTRY_CHANGE_ON: 'Restricted Entry On',
    RESTRICTED_ENTRY_CHANGE_OFF: 'Restricted Entry Off',
    LONE_WORKER_CHANGE_ON: 'Lone Worker On',
    LONE_WORKER_CHANGE_OFF: 'Lone Worker Off',
    BIOSIGNAL_ABNORMALITY_CHANGE_ON: 'Biosignal Abnormality On',
    BIOSIGNAL_ABNORMALITY_CHANGE_OFF: 'Biosignal Abnormality Off',
    LOW_BATTERY_CHANGE_ON: 'Low Battery On',
    LOW_BATTERY_CHANGE_OFF: 'Low Battery Off',
    RTLS_ERROR_CHANGE_ON: 'Rtls Error On',
    RTLS_ERROR_CHANGE_OFF: 'Rtls Error Off',
    BEACON_ERROR_CHANGE_ON: 'Beacon Error On',
    BEACON_ERROR_CHANGE_OFF: 'Beacon Error Off',
    SENSING_ERROR_CHANGE_ON: 'Sensing Error On',
    SENSING_ERROR_CHANGE_OFF: 'Sensing Error Off',
    FLOOR_STAY: 'Floor Stay',
    GEOFENCE_STAY: 'Geofence stay',
    RESTRICTION_STAY: 'Restriction Stay',
    LOCATION_CHANGE: 'Location Change',
    EXIT: 'Exit',
    FIRE_ON: 'Fire On',
    FIRE_OFF: 'Fire Off',
    SOS_ON2: 'Sos on',
    LEAVEALONE_ON: 'Leave Alone On',
    LEAVEALONE_OFF: 'Leave Alone Off',
    SENSING_INFO: 'Sensing Info',
    SENSOR_NORMAL: 'Sensor Normal',
    SENSOR_WARNING: 'Sensor Warning',
    SENSOR_CRITICAL: 'Sensor Critical',
    SENSOR_OUTOFRANGE: 'Sensor Out of Range',
    SENSOR_LOST_SIGNAL: 'Sensor Lost Signal',
    SCANNER_NORMAL: 'Scanner Normal',
    SCANNER_ABNORMAL: 'Scanner Normal',
};

export default EVT_TYPE;
