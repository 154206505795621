import React, { useContext, useEffect, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { useAsync, useTranslation } from '@hooks';
import { fetchWorkerList } from '@api/smartSafeWorker';

import { WorkStatusContext, WorkStatusDispatchContext } from 'Components/MainPages/WorkerSafetyDashboard';
import { setActiveTabId, setDefaultPeopleList } from 'Components/MainPages/WorkerSafetyDashboard/workerStatusReducer';

import WorkerSearch from './Components/WorkerSearch';
import WorkersByGeofence from './WorkersByGeofence';
import WorkersByDefault from './WorkersByDefault';
import WorkersByCompany from './WorkersByCompany';

import Tab from './Components/CustomTab';

const makeWorkerByCompanyList = list => {
    return list.reduce((acc, cur) => {
        return acc.concat(cur.workers);
    }, []);
};

const makeGeofenceWorkersList = (geofenceWorkers = {}) => {
    const { common, enclosed } = geofenceWorkers;

    const list = [];
    const pushToGeofenceWorkerList = type => {
        !!type.length &&
            type.forEach(geofence => {
                geofence.workers.forEach(worker => {
                    list.push(worker);
                });
            });
    };
    pushToGeofenceWorkerList(common);
    pushToGeofenceWorkerList(enclosed);
    return list;
};

const INTERVAL_TIME = 5 * 1000;

const WorkerStatusList = () => {
    const t = useTranslation('Work Status');
    const intervalRef = useRef(null);

    const dispatch = useContext(WorkStatusDispatchContext);
    const { defaultList } = useContext(WorkStatusContext);

    const { promise: getWorkerList } = useAsync({
        promise: fetchWorkerList,
        resolve: res => {
            const { workers, geofenceWorkers, teamWorkers } = res;

            dispatch(
                setDefaultPeopleList({
                    defaultPeopleList: workers,
                    defaultGeofencePeopleList: geofenceWorkers,
                    defaultCompanyPeopleList: teamWorkers,
                }),
            );
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        getWorkerList();

        intervalRef.current = setInterval(() => {
            getWorkerList();
        }, INTERVAL_TIME);

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, []);

    return (
        <Tab
            handleTabChange={tabId => {
                dispatch(setActiveTabId(tabId));
            }}
            styles={{
                header: { justifyContent: 'center' },
                headerItem: { width: '33%' },
            }}
            tabs={[
                {
                    tabId: 'all',
                    title: t('All'),
                    content: (
                        <>
                            <div>
                                <WorkerSearch searchPeopleOptions={defaultList.defaultPeopleList} type={'all'} />
                            </div>
                            <div className="tab-content">
                                <PerfectScrollbar>
                                    <WorkersByDefault />
                                </PerfectScrollbar>
                            </div>
                        </>
                    ),
                },
                {
                    tabId: 'geofence',
                    title: t('District'),
                    content: (
                        <>
                            <div>
                                <WorkerSearch
                                    searchPeopleOptions={makeGeofenceWorkersList(defaultList.defaultGeofencePeopleList)}
                                    type={'geofence'}
                                />
                            </div>
                            <div className="tab-content">
                                <PerfectScrollbar>
                                    <WorkersByGeofence />
                                </PerfectScrollbar>
                            </div>
                        </>
                    ),
                },
                {
                    tabId: 'company',
                    title: t('Company'),
                    content: (
                        <>
                            <div>
                                <WorkerSearch
                                    searchPeopleOptions={makeWorkerByCompanyList(defaultList.defaultCompanyPeopleList)}
                                    type={'company'}
                                />
                            </div>
                            <div className="tab-content">
                                <PerfectScrollbar>
                                    <WorkersByCompany />
                                </PerfectScrollbar>
                            </div>
                        </>
                    ),
                },
            ]}
        />
    );
};

export default WorkerStatusList;
