import React from 'react';
import cx from 'classnames';
import Button from '@components/Button';
import withPortal from './withPortal';

const CardModal = ({
    initModal = false,
    toggleModal = function () {
        initModal = !initModal;
    },
    className = '',
    okCallback,
    cancelCallback,
    cancelCallbackParam,
    callbackParam,
    header = { title: 'Confirm', subTitle: '', icon: '' },
    cancelButton = { text: 'Cancel', className: '', icon: '' },
    okButton = { text: 'Ok', className: '', icon: '' },
    removeCancel,
    headerClassName,
    bodyClassName,
    buttonClassName,
    popupTop,
    children,
}) => {
    return (
        <div className={cx(`${popupTop ? 'pnt-popup__top' : 'pnt-popup__center'} ${initModal && 'active'}`, className)}>
            <div className="center__inner">
                <div className={'card'}>
                    <div className={cx(`card-header ${headerClassName}`)}>
                        <div className={'card-header__title'}>
                            {!!header.icon && <span className={header.icon} />}
                            <div className="title-wrap">
                                <h3 className={'title__main'}>{header.title}</h3>
                                {!!header.subTitle && <p className={'title__sub'}>{header.subTitle}</p>}
                            </div>
                        </div>
                        {/*{!removeCancel && (*/}
                        {/*    <div className="card-header__function">*/}
                        {/*        <Button*/}
                        {/*            className={'pnt-btn btn-icon-only'}*/}
                        {/*            onClick={e => {*/}
                        {/*                if (typeof cancelCallback === 'function') {*/}
                        {/*                    cancelCallback(callbackParam);*/}
                        {/*                }*/}
                        {/*                toggleModal();*/}
                        {/*            }}*/}
                        {/*            iconClassName={'icon-close'}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>

                    <div className={cx('card-body', bodyClassName)}>{children}</div>

                    <div className={cx('card-button', buttonClassName)}>
                        {!removeCancel && (
                            <Button
                                className={cx(`${cancelButton.className}`)}
                                onClick={e => {
                                    if (typeof cancelCallback === 'function') {
                                        cancelCallback(cancelCallbackParam);
                                    }
                                    toggleModal();
                                }}
                                iconClassName={cancelButton.icon}
                            >
                                {cancelButton.text}
                            </Button>
                        )}
                        <Button
                            className={cx(`${okButton.className}`)}
                            onClick={e => {
                                if (typeof okCallback === 'function') {
                                    okCallback(callbackParam);
                                }

                                // toggleModal();
                            }}
                            iconClassName={okButton.icon}
                        >
                            {okButton.text}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withPortal(CardModal);
