import { createSlice } from '@reduxjs/toolkit';
import { calcCategoryStatus, setWorkerListByFloor } from './processLocationSocketData';
import { convertState, setTagStatus } from '../TagInfo/processSocketData';

const initialState = {
    workerList: {},
    workerListByFloor: {},
    categoryStatus: {
        PEOPLE: [],
        SENSOR: [],
        ASSET: [],
    },
    tagStatus: {
        sos: [],
        manDown: [],
        loneWorker: [],
        restrictedEntry: [],
        battery: [],
        leaveAlone: [],
        longStay: [],
        lostSignal: [],
        fireAlarm: [],
        restriction: [],
        fall: [],
        heartAttack: [],
        rtlsErr: [],
        beaconErr: [],
        sensingErr: [],
        biosignalAbnormality: [],
    },
};

const updateWorkerMarker = (state, workerInfo) => {
    const {
        workerNum,
        workerName,
        comNum,
        states,
        floorId,
        lat,
        lng,
        inGeofenceSummaryMap,
        workPlaceCode,
    } = workerInfo;

    const tagState = {
        sos: states['SOS'].stateType,
        manDown: states['MAN_DOWN'].stateType,
        loneWorker: states['LONE_WORKER'].stateType,
        restrictedEntry: states['RESTRICTED_ENTRY'].stateType,
        battery: states['LOW_BATTERY'].stateType,
        lostSignal: states['LOST_SIGNAL'].stateType,
        rtlsErr: states['RTLS_ERROR'].stateType,
        beaconErr: states['BEACON_ERROR'].stateType,
        sensingErr: states['SENSING_ERROR'].stateType,
        biosignalAbnormality: states['BIOSIGNAL_ABNORMALITY'].stateType,
    };

    const compactTagInfo = {
        location: { floor: floorId, lat, lng, latLng: [lat, lng] },
        target: { targetNum: workerNum, targetName: workerName, comNum },
        geofence: inGeofenceSummaryMap,
        states,
        state: convertState(tagState),
        workPlaceCode,
    };

    state.workerList[workerNum] = compactTagInfo;
    setTagStatus(state.tagStatus, compactTagInfo);
    calcCategoryStatus(state.categoryStatus, compactTagInfo);
};

const { actions, reducer } = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setWorkerLocation: (state, action) => {
            const workerLocationInfo = action.payload;
            for (let i in workerLocationInfo) {
                const workerInfo = workerLocationInfo[i];
                updateWorkerMarker(state, workerInfo);
            }

            setWorkerListByFloor(state);
        },
    },
});

export const { setWorkerLocation } = actions;
export default reducer;
