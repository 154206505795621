import React, { useState, useEffect } from 'react';
import { Map, RotatedImageOverlay, GeofenceLayer } from '../../Common';
import Card from '../Components/WidgetCard';
import Control from 'react-leaflet-control';
import FloorSelect from '../Components/FloorSelect';
import { useSelector } from 'react-redux';
import useAsync from '../../../util/hooks/useAsync';
import { fetchGeofenceList } from '../../../api/common';
import { useSettings } from '../util/useSettings';
import _ from 'lodash';
import useMonitor from '../util/useMonitor';
import { Marker } from 'react-leaflet';
import L from 'leaflet';

const nwLatLng = {
    lat: 37.50740602213598,
    lng: 127.05365710368295,
};

const seLatLng = {
    lat: 37.5072902561217,
    lng: 127.05384866266718,
};

const makeData = () => {
    const data = [];
    for (let i = 0; i < 56; i++) {
        const latLng = {
            lat: _.random(seLatLng.lat, nwLatLng.lat),
            lng: _.random(nwLatLng.lng, seLatLng.lng),
        };
        data.push({ id: i, latLng });
    }
    return data;
};

const defaultData = makeData();

const generateData = () => {
    return new Promise(function (resolve, reject) {
        resolve({
            data: makeData(),
        });
    });
};

const AssetLocationStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const [selectedFloor, setSelectedFloor] = useState(settings.floor);
    const [floorInfo, setFloorInfo] = useState({});
    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const { geofenceInOutState } = useSelector(state => state.TagInfo);
    const { promise: getGeofenceList, state: getGeofenceInfo } = useAsync({ promise: fetchGeofenceList });
    const { data } = useMonitor({
        config,
        defaultData,
        fetchData: generateData,
    });

    // const handleFloorChange = selectedNodes => {
    //     console.log({ selectedNodes });
    //     setSelectedFloor(selectedNodes[0].floorId);
    //     setFloorInfo(selectedNodes[0]);
    // };
    const handleFloorChange = selects => {
        // console.log({ selects });
        setSelectedFloor(selects[0].floorId);
        setFloorInfo(selects[0]);
    };

    // useEffect(() => {
    //     if (!selectedFloor && floorList.length) {
    //         for (let i in floorList) {
    //             const floor = floorList[i];
    //             if (!floorList.find(v => v.upperFloorId === floor.floorId)) {
    //                 handleFloorChange([floor]);
    //                 break;
    //             }
    //         }
    //     }
    // }, [floorList]);

    useEffect(() => {
        if (selectedFloor) {
            getGeofenceList({ floor: selectedFloor });
        }
    }, [selectedFloor]);

    useEffect(() => {
        const { response } = getGeofenceInfo;
        if (response) {
            setGeofenceInfo(
                response.rows.map(geofence => {
                    const inOutInfo = geofenceInOutState[geofence.fcNum] || {};
                    return {
                        ...geofence,
                        bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])],
                        authorized: inOutInfo.authorized,
                        unAuthorized: inOutInfo.unAuthorized,
                    };
                }),
            );
        }
    }, [getGeofenceInfo, geofenceInOutState]);

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <Map>
                <Control position="topleft">
                    {/*<FloorTreeSelect
                        floorList={floorList}
                        selectedFloorStr={selectedFloor}
                        mode={'radioSelect'}
                        handleChange={handleFloorChange}
                    />*/}
                    <FloorSelect selectedFloorId={selectedFloor} handleChange={handleFloorChange} />
                </Control>
                {floorInfo.imgURL && (
                    <RotatedImageOverlay
                        key={floorInfo.floorId}
                        url={floorInfo.imgURL}
                        deg={floorInfo.deg}
                        bounds={floorInfo.bounds}
                        onLoad={e => {
                            const { target: layer } = e;
                            layer._map.fitBounds(layer.getBounds());
                        }}
                    />
                )}
                {geofenceInfo.length > 0 && <GeofenceLayer geofenceList={geofenceInfo} />}
                {data.map(v => (
                    <Marker
                        key={v.id}
                        position={v.latLng}
                        icon={L.divIcon({
                            className: 'custom-asset-marker',
                            html: `<div></div>`,
                        })}
                    />
                ))}
            </Map>
            {children}
        </Card>
    );
};

export default AssetLocationStatus;
