import React from 'react';
import moment from 'moment';
import cx from 'classnames';

export const checkbox = ({ ...restProps } = {}) => {
    return {
        width: 50,
        accessor: 'id',
        ...restProps,
    };
};

//Work Management
export const work = ({ ...restProps } = {}) => {
    return {
        Header: 'Work',
        accessor: 'workName',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const supervisor = ({ ...restProps } = {}) => {
    return {
        Header: 'Supervisor',
        className: 'text-ellipsis',
        accessor: 'workerManagerName',
        ...restProps,
    };
};
export const numberOfWorker = ({ ...restProps } = {}) => {
    return {
        Header: 'Number of Worker',
        className: 'text-ellipsis',
        accessor: 'workerCnt',
        ...restProps,
    };
};
export const type = ({ ...restProps } = {}) => {
    return {
        Header: 'Work Type',
        className: 'text-ellipsis',
        accessor: 'processName',
        ...restProps,
    };
};
export const status = ({ ...restProps } = {}) => {
    return {
        Header: 'Access Status',
        accessor: 'state',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        Cell: ({ value }) => {
            if (value) {
                return <div className="text-ellipsis">{value}</div>;
            } else {
                return <div>-</div>;
            }
        },
        ...restProps,
    };
};
export const start = ({ ...restProps } = {}) => {
    return {
        Header: 'Start date',
        className: 'text-ellipsis',
        accessor: 'expectedStartDate',
        Cell: ({ value }) => {
            if (value !== 0) {
                return <div>{moment.unix(value).format('YYYY-MM-DD HH:MM')}</div>;
            } else {
                return <div>-</div>;
            }
        },
        ...restProps,
    };
};
export const end = ({ ...restProps } = {}) => {
    return {
        Header: 'End date',
        className: 'text-ellipsis',
        accessor: 'endDate',
        Cell: ({ value }) => {
            if (value !== 0) {
                return <div>{moment.unix(value).format('YYYY-MM-DD HH:MM')}</div>;
            } else {
                return <div>-</div>;
            }
        },
        ...restProps,
    };
};
export const totalTime = ({ ...restProps } = {}) => {
    return {
        Header: 'Total elapsed time',
        className: 'text-ellipsis',
        accessor: 'timeTaken',
        Cell: ({ value }) => {
            if (value) {
                return <div>{moment.unix(value).format('YYYY-MM-DD HH:MM')}</div>;
            } else {
                return <div>-</div>;
            }
        },
        ...restProps,
    };
};

//User Management Table
export const id = ({ ...restProps } = {}) => {
    return {
        Header: 'ID',
        accessor: 'userID',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const userName = ({ ...restProps } = {}) => {
    return {
        Header: 'Name',
        accessor: 'userName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const name = ({ ...restProps } = {}) => {
    return {
        Header: 'Name',
        className: 'text-ellipsis',
        accessor: 'workerName',
        ...restProps,
    };
};

export const dateOfBirth = ({ ...restProps } = {}) => {
    return {
        Header: 'Date of Birth',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'dateOfBirth',
        Cell: ({ value }) => {
            if (value) {
                return <div>{moment.unix(value).format('YYMMDD')}</div>;
            } else {
                return <div>-</div>;
            }
        },
        ...restProps,
    };
};

export const phone = ({ ...restProps } = {}) => {
    return {
        Header: 'Phone',
        className: 'text-ellipsis',
        accessor: data => {
            return data.contactNumber || data.comContactNumber || data.phoneNum;
        },
        Cell: ({ value }) => {
            if (value) {
                return <div>{value}</div>;
            } else {
                return <div>-</div>;
            }
        },
        ...restProps,
    };
};

export const company = ({ ...restProps } = {}) => {
    return {
        Header: 'Company',
        className: 'text-ellipsis',
        accessor: data => {
            return data.managerComName || data.workerComName;
        },
        Cell: ({ value }) => {
            if (value) {
                return <div>{value}</div>;
            } else {
                return <div>-</div>;
            }
        },
        ...restProps,
    };
};

export const site = ({ ...restProps } = {}) => {
    return {
        Header: 'Site',
        className: 'text-ellipsis',
        accessor: 'workPlaceName',
        Cell: ({ value }) => {
            if (value) {
                return <div className={'text-ellipsis'}>{value}</div>;
            } else {
                return <div>-</div>;
            }
        },
        ...restProps,
    };
};

export const unit = ({ ...restProps } = {}) => {
    return {
        Header: 'Unit',
        className: 'text-ellipsis',
        accessor: 'teamName',
        Cell: ({ value }) => {
            return <div>{value ? value : '-'}</div>;
        },
        ...restProps,
    };
};

export const contactNumber = ({ ...restProps } = {}) => {
    return {
        Header: 'Phone',
        className: 'text-ellipsis',
        accessor: 'contactNumber',
        ...restProps,
    };
};

export const addedOn = ({ ...restProps } = {}) => {
    return {
        Header: 'Added on',
        className: 'text-ellipsis',
        accessor: 'regDate',
        Cell: ({ value }) => {
            return <div>{moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}</div>;
        },
        ...restProps,
    };
};

export const attendedOn = ({ ...restProps } = {}) => {
    return {
        Header: 'Attended on',
        className: 'text-ellipsis',
        accessor: 'recentWorkDate',
        Cell: ({ value }) => {
            if (value) {
                return <div>{moment.unix(value).format('YYYY-MM-DD')}</div>;
            } else {
                return <div>-</div>;
            }
        },
        ...restProps,
    };
};

export const role = ({ ...restProps } = {}) => {
    return {
        Header: 'Role',
        className: 'text-ellipsis',
        accessor: data => {
            return data.roleName || data.workerType;
        },
        Cell: ({ value }) => {
            if (value) {
                return <div>{value}</div>;
            } else {
                return <div>-</div>;
            }
        },
        ...restProps,
    };
};

export const equipmentName = ({ ...restProps } = {}) => {
    return {
        Header: 'Name',
        className: 'text-ellipsis',
        accessor: 'deviceName',
        ...restProps,
    };
};

export const equipmentType = ({ ...restProps } = {}) => {
    return {
        Header: 'Type',
        className: 'text-ellipsis',
        accessor: 'deviceType',
        Cell: ({ value }) => {
            return value ? value : '-';
        },
        ...restProps,
    };
};

export const holder = ({ ...restProps } = {}) => {
    return {
        Header: 'Holder',
        className: 'text-ellipsis',
        accessor: 'workerName',
        Cell: ({ value }) => {
            if (value) {
                return <div>{value}</div>;
            } else {
                return <div>-</div>;
            }
        },
        ...restProps,
    };
};

export const usedOn = ({ ...restProps } = {}) => {
    return {
        Header: 'Used on',
        className: 'text-ellipsis',
        accessor: 'lastDate',
        Cell: ({ value }) => {
            if (value) {
                return <div>{moment.unix(value).format('YYYY-MM-DD')}</div>;
            } else {
                return <div>-</div>;
            }
        },
        ...restProps,
    };
};

export const date = ({ ...restProps } = {}) => {
    return {
        Header: 'Date',
        className: 'text-ellipsis',
        accessor: 'date',
        ...restProps,
    };
};

export const alarm = ({ ...restProps } = {}) => {
    return {
        Header: 'Alarm',
        className: 'text-ellipsis',
        accessor: 'alarmType',
        Cell: ({ value }) => {
            return <div>{value}</div>;
        },
        ...restProps,
    };
};

export const level = ({ ...restProps } = {}) => {
    return {
        Header: 'Level',
        className: 'text-ellipsis',
        accessor: 'level',
        Cell: ({ value }) => {
            return <div>{value}</div>;
        },
        ...restProps,
    };
};

export const zone = ({ ...restProps } = {}) => {
    return {
        Header: 'Zone',
        className: 'text-ellipsis',
        accessor: 'zone',
        ...restProps,
    };
};

export const worker = ({ ...restProps } = {}) => {
    return {
        Header: 'Worker',
        className: 'text-ellipsis',
        accessor: 'targetName',
        ...restProps,
    };
};

export const siteId = ({ ...restProps } = {}) => {
    return {
        Header: 'Site ID',
        className: 'text-ellipsis',
        accessor: 'workPlaceCode',
        ...restProps,
    };
};
export const ceo = ({ ...restProps } = {}) => {
    return {
        Header: 'CEO',
        className: 'text-ellipsis',
        accessor: 'representative',
        Cell: ({ value }) => {
            if (value) {
                return <div className={'text-ellipsis'}>{value}</div>;
            } else {
                return <div>-</div>;
            }
        },
        ...restProps,
    };
};
export const addedBy = ({ ...restProps } = {}) => {
    return {
        Header: 'Added by',
        className: 'text-ellipsis',
        accessor: 'regUserId',
        Cell: ({ value }) => {
            return <div>{value ? value : '-'}</div>;
        },
        ...restProps,
    };
};
export const modifiedOn = ({ ...restProps } = {}) => {
    return {
        Header: 'Modified on',
        className: 'text-ellipsis',
        accessor: 'modDate',
        Cell: ({ value }) => {
            return <div>{value !== 0 ? moment.unix(value).format('YYYY-MM-DD') : '-'}</div>;
        },
        ...restProps,
    };
};
export const modifiedBy = ({ ...restProps } = {}) => {
    return {
        Header: 'Modified by',
        className: 'text-ellipsis',
        accessor: 'modUserId',
        Cell: ({ value }) => {
            return <div>{value ? value : '-'}</div>;
        },
        ...restProps,
    };
};

export const partner = ({ ...restProps } = {}) => {
    return {
        Header: 'Partner',
        className: 'text-ellipsis',
        accessor: 'workerComName',
        ...restProps,
    };
};

export const riskLevel = ({ ...restProps } = {}) => {
    return {
        Header: 'Risk Level',
        className: 'text-ellipsis',
        accessor: 'riskClass',
        ...restProps,
    };
};

export const facility = ({ ...restProps } = {}) => {
    return {
        Header: 'Facility',
        className: 'text-ellipsis',
        accessor: 'facilityTypeName',
        ...restProps,
    };
};

export const count = ({ ...restProps } = {}) => {
    return {
        Header: 'Count',
        className: 'text-ellipsis',
        accessor: 'regCount',
        Cell: ({ value }) => {
            return value ? value : 0;
        },
        ...restProps,
    };
};

export const documentId = ({ ...restProps } = {}) => {
    return {
        Header: 'Document ID',
        className: 'text-ellipsis',
        accessor: 'termsConditionsCode',
        ...restProps,
    };
};

export const title = ({ ...restProps } = {}) => {
    return {
        Header: 'Title',
        className: 'text-ellipsis',
        accessor: 'termsConditionsName',
        ...restProps,
    };
};

export const version = ({ ...restProps } = {}) => {
    return {
        Header: 'Version',
        className: 'text-ellipsis',
        accessor: 'version',
        ...restProps,
    };
};

export const validation = ({ ...restProps } = {}) => {
    return {
        Header: 'Validation',
        className: 'text-ellipsis',
        accessor: 'isActive',
        // Cell: ({ value }) => {
        //     if (value === 'Y') {
        //         return <div>{t('Valid')}</div>;
        //     } else {
        //         return <div>{t('Expired')}</div>;
        //     }
        // },
        ...restProps,
    };
};

export const message = ({ ...restProps } = {}) => {
    return {
        Header: 'Message',
        className: 'text-ellipsis',
        accessor: 'messageConfigName',
        ...restProps,
    };
};

export const alert = ({ ...restProps } = {}) => {
    return {
        Header: 'Alert',
        className: 'text-ellipsis',
        accessor: 'eventType',
        ...restProps,
    };
};

export const messageType = ({ ...restProps } = {}) => {
    return {
        Header: 'Type',
        className: 'text-ellipsis',
        accessor: 'sendTypes',
        Cell: ({ value }) => {
            return <div>{value.join(', ')}</div>;
        },
        ...restProps,
    };
};

export const number = ({ ...restProps } = {}) => {
    return {
        Header: 'No.',
        className: 'text-ellipsis',
        accessor: 'number',
        ...restProps,
    };
};

export const sender = ({ ...restProps } = {}) => {
    return {
        Header: 'Sender',
        className: 'text-ellipsis',
        accessor: 'sender',
        ...restProps,
    };
};

export const recipient = ({ ...restProps }) => {
    return {
        Header: 'Recipient',
        className: 'text-ellipsis',
        accessor: 'recipient',
        ...restProps,
    };
};

export const sent = ({ ...restProps }) => {
    return {
        Header: 'Sent date',
        className: 'text-ellipsis',
        accessor: 'sent',
        ...restProps,
    };
};

// Menu Authorization
export const menuAllowed = ({ ...restProps }) => {
    return {
        Header: 'Number of menu allowed to access',
        className: 'text-ellipsis',
        accessor: 'assignMenuCount',
        ...restProps,
    };
};

export const pplAssigned = ({ ...restProps }) => {
    return {
        Header: 'Number of People Assigned',
        className: 'text-ellipsis',
        accessor: 'belongSafetyManagerCount',
        ...restProps,
    };
};

export const floor = ({ ...restProps }) => {
    return {
        Header: 'Floor',
        className: 'text-ellipsis',
        accessor: 'floorName',
        ...restProps,
    };
};

export const workZone = ({ ...restProps }) => {
    return {
        Header: 'Work Zone',
        className: 'text-ellipsis',
        accessor: 'fcName',
        ...restProps,
    };
};

export const asset = ({ ...restProps }) => {
    return {
        Header: 'Asset',
        className: 'text-ellipsis',
        accessor: 'asset',
        ...restProps,
    };
};

export const location = ({ ...restProps }) => {
    return {
        Header: 'Location',
        className: 'text-ellipsis',
        accessor: 'location',
        ...restProps,
    };
};

export const pulse = ({ ...restProps }) => {
    return {
        accessor: 'pulse',
        Header: 'Smart band value',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const contents = ({ ...restProps }) => {
    return {
        Header: 'Contents',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const receiveDate = ({ ...restProps }) => {
    return {
        Header: 'Receive Date',
        className: 'text-ellipsis',
        accessor: 'receiveDate',
        ...restProps,
    };
};
export const resultMessage = ({ ...restProps }) => {
    return {
        Header: 'Reply',
        className: 'text-ellipsis',
        accessor: 'resultMessage',
        ...restProps,
    };
};

export const internalWorker = ({ ...restProps }) => {
    return {
        Header: 'Internal worker',
        className: 'text-ellipsis',
        accessor: '',
        ...restProps,
    };
};

export const indoorCoalStorage = ({ ...restProps }) => {
    return {
        Header: 'Indoor Coal Storage',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'inGeofence',
        formatTitle: ({ value }) => {
            return value ? '옥내저탄장' : '-';
        },
        Cell: ({ value }) => {
            return value ? '옥내저탄장' : '-';
        },
        ...restProps,
    };
};

export const danger = ({ ...restProps }) => {
    return {
        Header: 'Danger',
        className: 'text-ellipsis',
        accessor: 'danger',
        ...restProps,
    };
};

export const battery = ({ ...restProps } = {}) => {
    return {
        Header: 'Battery Status',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'battery',
        Cell: ({ value }) => {
            return value ? value + '%' : '-';
        },
        formatTitle: ({ value }) => {
            return value ? value + '%' : '-';
        },
        ...restProps,
    };
};

export const gas = ({ ...restProps } = {}) => {
    return {
        Header: 'Sensing value',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'gas',
        ...restProps,
    };
};

export const sensingType = ({ ...restProps } = {}) => {
    return {
        Header: 'Sensing Type',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'sensingType',
        ...restProps,
    };
};

export const action = ({ ...restProps } = {}) => {
    return {
        Header: 'Action',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'description',
        ...restProps,
    };
};

export const actionContents = ({ ...restProps } = {}) => {
    return {
        Header: 'Action Contents',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'description',
        ...restProps,
    };
};

export const companyName = ({ ...restProps } = {}) => {
    return {
        Header: 'Company Name',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'companyName',
        ...restProps,
    };
};

export const phoneNumber = ({ ...restProps } = {}) => {
    return {
        Header: 'Phone Number',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'phoneNumber',
        ...restProps,
    };
};

export const healthRating = ({ ...restProps } = {}) => {
    return {
        Header: 'Health Rating',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'healthGrade',
        ...restProps,
    };
};

export const timeOfEntryOrExit = ({ ...restProps } = {}) => {
    return {
        Header: 'Time of Entry or Exit',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'entryDatetime',
        ...restProps,
    };
};

export const timeOfEntry = ({ ...restProps } = {}) => {
    return {
        Header: 'Time of Entry',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'entryDateTime',
        ...restProps,
    };
};

export const timeOfExist = ({ ...restProps } = {}) => {
    return {
        Header: 'Time of Exit',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'exitDateTime',
        ...restProps,
    };
};

export const workshop = ({ ...restProps } = {}) => {
    return {
        Header: 'Workshop',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'fcName',
        ...restProps,
    };
};

export const locationName = ({ ...restProps } = {}) => {
    return {
        Header: 'Location Name',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'locationName',
        ...restProps,
    };
};

export const tagId = ({ ...restProps } = {}) => {
    return {
        Header: 'Tag ID',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'beaconNum',
        ...restProps,
    };
};

export const dangerousStatus = ({ ...restProps } = {}) => {
    return {
        Header: 'Dangerous Status',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'dangerStatus',
        ...restProps,
    };
};

export const workday = ({ ...restProps } = {}) => {
    return {
        Header: 'Workday',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'workDate',
        ...restProps,
    };
};

export const timeOfAction = ({ ...restProps } = {}) => {
    return {
        Header: 'Time of Action',
        className: 'text-ellipsis',
        headerClassName: 'text-ellipsis',
        accessor: 'actDate',
        ...restProps,
    };
};
