import { reqGet, reqDelete, reqPatch, reqPost } from './index';

////메뉴 관리
//전체 메뉴 목록 조회
export const getMenuListApi = param => reqGet('/v3/api/ws01/menu', param);

////권한 & 메뉴 관리
//권한 단일 조회
export const getMenuRoleApi = param => reqGet(`/v3/api/ws01/role/menu/${param.roleNum}`, { ...param });
//권한 목록 조회
export const getMenuRoleListApi = param => reqGet(`/v3/api/ws01/role/menu`, param);
//권한 등록
export const createMenuRoleApi = param => reqPost('/v3/api/ws01/role/menu', param);
//권한 수정
export const updateMenuRoleApi = param => reqPatch(`/v3/api/ws01/role/menu/${param.roleNum}`, { ...param });
//권한 삭제
export const deleteMenuRoleApi = param => reqDelete(`/v3/api/ws01/role/menu/${param.roleNum}`);
