import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { Collapse } from 'reactstrap';

import cx from 'classnames';

import { Label } from '@components';

import { WorkStatusContext, WorkStatusDispatchContext } from 'Components/MainPages/WorkerSafetyDashboard';
import WorkerList from '../Components/WorkerList';
import NoDataComponent from '../Components/NoDataComponent';

import { useTranslation } from '@hooks';
import { setSelectedPerson } from '../../../../workerStatusReducer';

const WorkersByCompany = () => {
    const t = useTranslation('WorkerByCompany');
    const dispatch = useContext(WorkStatusDispatchContext);
    const labelRefs = useRef({});

    const {
        defaultList: { defaultCompanyPeopleList },
        activeTabId,
        searchInput,
        selectedPerson,
    } = useContext(WorkStatusContext);

    const [collapse, setCollapse] = useState({});

    const companyPeopleList = useMemo(
        () =>
            defaultCompanyPeopleList.map(companyPeopleList => {
                const filteredCompanyWorkerList = companyPeopleList.workers.length
                    ? companyPeopleList.workers.filter(({ name }) => {
                          return name.includes(searchInput);
                      })
                    : [];

                return {
                    ...companyPeopleList,
                    workers: filteredCompanyWorkerList,
                };
            }),
        [defaultCompanyPeopleList],
    );

    useEffect(() => {
        setCollapse({});
    }, [activeTabId]);

    const handleCollapseToggle = workerTeamNum => {
        setCollapse(prevState => {
            return { ...prevState, [workerTeamNum]: !prevState[workerTeamNum] };
        });
    };

    // 스크롤 내려가게 하기
    useEffect(() => {
        if (selectedPerson) {
            const totalList = companyPeopleList.reduce((map, { teamId, workers }) => {
                if (Array.isArray(workers)) {
                    map.set(teamId, workers);
                }
                return map;
            }, new Map());

            let selectedFcNum = null;

            totalList.forEach((workers, teamId) => {
                workers.forEach(worker => {
                    if (worker.targetNum === selectedPerson.targetNum) {
                        selectedFcNum = teamId;
                    }
                });
            });

            if (selectedFcNum) {
                setCollapse({ [selectedFcNum]: true });
                const selectedDOM = labelRefs.current[selectedFcNum];

                selectedDOM.scrollIntoView({
                    block: 'start',
                });

                dispatch(setSelectedPerson(null));
            }
        }
    }, [selectedPerson, companyPeopleList]);

    const addToLabelRefs = el => {
        if (el && el.id) {
            labelRefs.current[el.id] = el;
        }
    };

    return (
        <div className={cx('worker-list-height', !companyPeopleList.length && 'place-center d-grid')}>
            {!!companyPeopleList.length ? (
                companyPeopleList.map(company => {
                    const { teamId, workers } = company;
                    return (
                        <div
                            ref={addToLabelRefs}
                            className={'mb-2'}
                            key={teamId}
                            id={teamId}
                            onClick={() => handleCollapseToggle(teamId)}
                        >
                            <Label
                                name={`${t('Company')}: ${teamId} / ${t('Worker')}: ${workers.length}${t('People')}`}
                                labelGroupClassName={'collapsible-list'}
                                labelValueClassName={'label-dot'}
                                value={
                                    <span
                                        className={cx('icon-small me-3', collapse[teamId] ? 'icon-up' : 'icon-down')}
                                    />
                                }
                            />
                            <Collapse
                                isOpen={collapse[teamId]}
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                            >
                                {!!workers.length &&
                                    workers.map(person => {
                                        return <WorkerList person={person} key={person.targetNum} />;
                                    })}
                            </Collapse>
                        </div>
                    );
                })
            ) : (
                <NoDataComponent />
            )}
        </div>
    );
};

export default WorkersByCompany;
