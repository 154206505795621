import React from 'react';
import { AiFillAlert } from 'react-icons/ai';
import CardModal from './CardModal';
import useTranslation from '@hooks/useTranslation';
import { Label } from '@components';
import { BsBatteryFull, BsBatteryHalf, BsBattery } from 'react-icons/bs';
import moment from 'moment';

const AlertModal = ({ initModal, okCallback, callbackParam, cancelCallback, cancelCallbackParam, data = {} }) => {
    const t = useTranslation('SmartSafe');

    return (
        <CardModal
            className={'sos-call'}
            initModal={initModal}
            header={{
                title: (
                    <div className={'pnt-txt txt-bold color-white h5-n'}>
                        {data.type === 'SOS_ON'
                            ? t('SOS Call!!')
                            : data.type === 'MAN_DOWN_ON'
                            ? t('Man Down!!')
                            : t('Abnormality Biosignal!!')}
                    </div>
                ),
                icon: 'icon-alert icon-big',
            }}
            okButton={{
                text: t('Ok', 'Button'),
                icon: 'icon-check',
                className: 'btn-brand btn-icon',
            }}
            okCallback={okCallback}
            callbackParam={callbackParam}
            cancelCallback={cancelCallback}
            cancelButton={{ text: t('Tracing', 'TimeLine'), className: 'color-brand' }}
            cancelCallbackParam={cancelCallbackParam}
        >
            <div className={'flx-col'}>
                <div className={'flx-row sos-popup-container'}>
                    {/*<div className={'img-container'}>*/}
                    {/*    <img src={userImg} alt="profileImg" />*/}
                    {/*</div>*/}

                    <div className={'sos-mark-container'}>
                        <div className={'flx-row'}>
                            <AiFillAlert color="red" style={{ transform: 'scale(1.7)' }} />
                            <div className={'color-danger pnt-txt txt-bold ml-2 h5-n'}>
                                {data.type === 'SOS_ON'
                                    ? t('SOS Call')
                                    : data.type === 'MAN_DOWN_ON'
                                    ? t('Man Down')
                                    : t('Abnormality Biosignal')}
                            </div>
                        </div>

                        <div className={'name'}>
                            {data.workerName}
                            <span className={'pnt-txt txt-bold'}>
                                ({moment.unix(data.dateOfBirth).format('YYYY')}, {t(data.sex, 'Work Status')})
                            </span>
                        </div>
                    </div>

                    <div className={'person-info'}>
                        <div>
                            <span className="label">{t('Mobile')} : </span> {data.workerPhoneNum}
                        </div>

                        <div>
                            <span className="label">{t('Location')} : </span>
                            {data.location && Object.keys(data.location.inGeofenceSummaryMap).length
                                ? Object.values(data.location.inGeofenceSummaryMap)
                                      .map(geofence => geofence.fcName)
                                      .join(',')
                                : '-'}
                        </div>
                    </div>
                </div>

                <div className={'sos-data-container'}>
                    <div className={'bio-data'}>
                        <div>
                            <Label name={t('Biometric Data')} labelValueClassName={'label-dot txt-bold'} />
                        </div>

                        <div className={'flx-row row-space-between mt-2'}>
                            <div>{t('Heartbeat', 'Work Status')}(bpm)</div>
                            <div className={'status-data'}>
                                {data.sensingMapJsonStr && JSON.parse(data.sensingMapJsonStr).heartbeat
                                    ? JSON.parse(data.sensingMapJsonStr).heartbeat
                                    : '-'}
                            </div>
                        </div>
                        <div className={'flx-row row-space-between'}>
                            <div>{t('Oxygen Saturation', 'Work Status')}(%)</div>
                            <div className={'status-data'}>
                                {data.sensingMapJsonStr && JSON.parse(data.sensingMapJsonStr).oxygen
                                    ? JSON.parse(data.sensingMapJsonStr).oxygen
                                    : '-'}
                            </div>
                        </div>
                        <div className={'flx-row row-space-between'}>
                            <div>{t('Temperature', 'Work Status')}(ºC)</div>
                            <div className={'status-data'}>
                                {data.sensingMapJsonStr && JSON.parse(data.sensingMapJsonStr).temperature
                                    ? JSON.parse(data.sensingMapJsonStr).temperature
                                    : '-'}
                            </div>
                        </div>
                    </div>

                    <div className={'border-vert'} />

                    <div className={'safety-equipment'}>
                        <div className={'mb-2'}>
                            <Label name={t('Safety Equipment')} labelValueClassName={'label-dot txt-bold'} />
                        </div>

                        {data.devices ? (
                            data.devices.map(device => {
                                return (
                                    <div key={device.deviceId} className={'flx-row row-space-between'}>
                                        <div>{device.deviceName}</div>
                                        {device.battery ? (
                                            <div className={'status-data'}>
                                                {device.battery > 60 ? (
                                                    <BsBatteryFull
                                                        style={{ transform: 'scale(1.4) translateY(-1px)' }}
                                                    />
                                                ) : device.battery > 10 ? (
                                                    <BsBatteryHalf
                                                        style={{ transform: 'scale(1.4) translateY(-1px)' }}
                                                    />
                                                ) : (
                                                    <BsBattery
                                                        style={{
                                                            transform: 'scale(1.4) translateY(-1px)',
                                                            color: 'red',
                                                        }}
                                                    />
                                                )}
                                                <span className={'ml-2'}>{device.battery}%</span>
                                            </div>
                                        ) : (
                                            <div>-</div>
                                        )}
                                    </div>
                                );
                            })
                        ) : (
                            <div className={'flx-row row-space-between'}>
                                {t('No registered devices', 'Work Status')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </CardModal>
    );
};

export default AlertModal;
