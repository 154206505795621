import React, { useContext, useEffect } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import { WorkStatusContext, WorkStatusDispatchContext } from 'Components/MainPages/WorkerSafetyDashboard';
import {
    setPeopleList,
    setSearchInput,
    setSelectedPerson,
} from 'Components/MainPages/WorkerSafetyDashboard/workerStatusReducer';

import useTranslation from '@hooks/useTranslation';

const WorkerSearch = ({ searchPeopleOptions }) => {
    const t = useTranslation('Work Status');

    const dispatch = useContext(WorkStatusDispatchContext);
    const { activeTabId, defaultList } = useContext(WorkStatusContext);

    useEffect(() => {
        if (activeTabId === 'all') {
            dispatch(setPeopleList(defaultList.defaultPeopleList));
        }
    }, [activeTabId, defaultList.defaultPeopleList]);

    useEffect(() => {
        dispatch(setSearchInput(''));
        dispatch(setSelectedPerson(null));
    }, [activeTabId]);

    return (
        <div className={'mb-2'}>
            <AsyncTypeahead
                key={activeTabId}
                id="targetId"
                labelKey={options => {
                    const { name, phoneNum } = options;
                    return `${name}`;
                }}
                valueKey={options => {
                    return options.targetNum;
                }}
                placeholder={t('Search by name')}
                emptyLabel={t('There are no search results', 'Search')}
                minLength={1}
                onInputChange={text => {
                    if (!text) {
                        dispatch(setPeopleList(searchPeopleOptions));
                    }
                    dispatch(setSearchInput(text));
                }}
                options={searchPeopleOptions}
                onChange={selected => {
                    const selectedPerson = selected[0];
                    dispatch(setSelectedPerson(selectedPerson));
                    if (!selectedPerson) {
                        dispatch(setPeopleList(searchPeopleOptions));
                        dispatch(setSearchInput(''));
                        return;
                    }
                    const { name, phoneNum } = selectedPerson;
                    if (activeTabId === 'all') {
                        dispatch(setSearchInput(name));
                    }
                }}
                onSearch={query => {
                    const tempList = searchPeopleOptions.filter(({ name, phoneNum }) => {
                        return name.includes(query);
                        // || phoneNum.includes(query);
                    });

                    dispatch(setPeopleList(tempList));
                }}
                clearButton
                isLoading={false}
                multiple={false}
                allowNew={false}
                useCache={false}
            />
        </div>
    );
};

export default WorkerSearch;
