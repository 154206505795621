import React, { useEffect, useState } from 'react';
import { useAsync, useTranslation } from '@hooks';
import { getFloorListApi } from '@api/smartSafeCommon';
import { useDispatch, useSelector } from 'react-redux';
import { Select as ReactSelect } from '@components';
import { setFloorList, setSmartSafeFloorList } from '@reducer/Common/FloorInfo';
import { useHistory, useRouteMatch } from 'react-router-dom';

const FloorSelect = ({ onChange = () => {}, value, styles = { customControlStyles: {} }, disabled }) => {
    const t = useTranslation('SmartSafe');
    const storeDispatch = useDispatch();
    const history = useHistory();
    const { smartSafeFloorList } = useSelector(state => state.FloorInfo);
    const { smartSafeManagerInfo } = useSelector(state => state.UserInfo);
    const [options, setOptions] = useState(smartSafeFloorList);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        const selected = options.filter(v => v.floorId === value);

        if (selected[0]) {
            setSelected(selected[0]);
        } else {
            setSelected([
                {
                    floorId: '',
                    floorName: t('Select floor'),
                },
            ]);
        }
    }, [value, options]);

    const { promise: getFloorList } = useAsync({
        promise: getFloorListApi,
        fixedParam: { isAll: 'Y', isSelf: 'Y', workPlaceCode: smartSafeManagerInfo.workPlaceCode },
        resolve: res => {
            const data = res.rows;

            if (data.length) {
                setOptions(data);
            } else {
                setOptions([]);
            }

            storeDispatch(setSmartSafeFloorList(data));
        },
    });

    useEffect(() => {
        getFloorList();
    }, [history]);

    const handleSelectChange = selected => {
        setSelected(selected);
        onChange(selected);
    };

    return (
        <ReactSelect
            name={'floor'}
            onChange={handleSelectChange}
            options={options}
            value={selected}
            placeholder={t('No registered floor')}
            valueKey={'floorId'}
            labelKey={'floorName'}
            customControlStyles={{ width: '100%', ...styles.customControlStyles }}
            customOptionStyles={{ width: '100%' }}
            customMenuStyles={{ width: '100%' }}
            disabled={disabled}
        />
    );
};

export default FloorSelect;
