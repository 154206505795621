import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from 'classnames';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import { useDispatch } from 'react-redux';
import { setSelectedAlertInfo } from '../../../reducers/Notification';

const WrappedAlertInfo = styled.div`
    display: flex;
    & > div {
        cursor: ${props => (props.clickable ? 'pointer' : 'default')};
    }
`;

const DefaultItemComponent = ({ event }) => {
    const { t } = useTranslation();
    const storeDispatch = useDispatch();

    const handleSeeLocationClick = () => {
        storeDispatch(setSelectedAlertInfo(event));
    };

    return (
        <>
            <div className={'alarm-list'}>
                <b className={event.typeClassName}>{event.type}</b>
                <h4 className="timeline-title mr-2" style={{ display: 'inline-block' }}>
                    {event.targetName}
                </h4>

                {event.location && (
                    <p>
                        {t('TimeLine;Location')} : {event.location}
                    </p>
                )}
            </div>
            <div className={'alarm-tracing-button'}>
                <Button className="btn-secondary" onClick={handleSeeLocationClick}>
                    {t('TimeLine;Location')}
                </Button>
            </div>
        </>
    );
};

const TimeLine = ({ events = [], onClick, ItemComponent, withDate = true, setOpen }) => {
    const { t } = useTranslation();
    return events.length ? (
        <PerfectScrollbar>
            <VerticalTimeline layout="1-column">
                {events.map((event, i) => (
                    <VerticalTimelineElement
                        key={i}
                        style={{
                            cursor: 'default',
                        }}
                        className="vertical-timeline-item"
                        icon={
                            <i
                                className={cx(
                                    'badge badge-dot badge-dot-xl',
                                    event.level ? `badge-${[event.level]}` : 'badge-primary',
                                )}
                            >
                                {' '}
                            </i>
                        }
                        date={
                            <>
                                {withDate && (
                                    <>
                                        <span> {event.dateStr}</span>
                                        <br />
                                    </>
                                )}
                                <span> {event.timeStr}</span>
                            </>
                        }
                    >
                        <WrappedAlertInfo
                            clickable={typeof onClick === 'function'}
                            onClick={() => {
                                if (typeof onClick === 'function') {
                                    onClick(event);
                                }
                            }}
                        >
                            {ItemComponent ? (
                                <ItemComponent event={event} setOpen={setOpen} />
                            ) : (
                                <DefaultItemComponent event={event} />
                            )}
                        </WrappedAlertInfo>
                    </VerticalTimelineElement>
                ))}
            </VerticalTimeline>
        </PerfectScrollbar>
    ) : (
        <div
            style={{
                color: '#666',
                textAlign: 'center',
                paddingTop: '1.5rem',
            }}
        >
            {t('TimeLine;No new notifications')}
        </div>
    );
};

export default TimeLine;
