import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Hamburger from 'react-hamburgers';

import AppMobileMenu from '../AppMobileMenu';

import { setEnableClosedSidebar } from '@reducer/ThemeOptions';
import HyundaiLogo from 'assets/images/logo.png';

const HeaderLogo = props => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { enableClosedSidebar } = useSelector(state => state.ThemeOptions);
    const [active, setActive] = useState(false);

    const toggleActive = () => {
        setActive(!active);
    };

    const toggleEnableClosedSidebar = () => {
        dispatch(setEnableClosedSidebar(!enableClosedSidebar));
    };

    const handleLogoClick = () => {
        history.push('/dashboard/monitor');
    };

    return (
        <>
            <div className="app-header__logo">
                <div
                    className="logo-src d-flex justify-content-center align-items-center h-70 cursor-pointer"
                    onClick={handleLogoClick}
                >
                    {!enableClosedSidebar && <img src={HyundaiLogo} alt="HYUNDAI STEEL" className="h-100" />}
                </div>
                <div className="header__pane ml-auto">
                    <div onClick={toggleEnableClosedSidebar}>
                        <Hamburger active={enableClosedSidebar} type="elastic" onClick={toggleActive} />
                    </div>
                </div>
            </div>
            <AppMobileMenu />
        </>
    );
};

export default HeaderLogo;
