import reducers from '../reducers';
import { configureStore } from '@reduxjs/toolkit';

export default function createStore() {
    return configureStore({
        reducer: {
            ...reducers,
        },
        middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
        devTools: true,
    });
}
