import { reqGet, reqPost } from './index';

// 층 별 작업자 수 - 고성
// export const fetchCurrentStatistics = param => reqGet('/v3/api/app/getDailyCurrentStatistics', { ...param });

// 해당 층에 대한 작업자 수, 중장비 수, 생체 이상 수, 가스 위험 수
export const fetchFloorGeofence = param => reqGet('/v3/api/app/getTargetFloorGeofence', { ...param });

// 작업자 목록 조회
export const fetchWorkerList = param => reqGet('/v3/api/app/getWorkers', { ...param });

// 작업자 가스 센서 현황
export const fetchWorkerGasStatus = param => reqGet('/v3/api/app/getWorkerGasStatus', { ...param });

// 작업자 스마트밴드(심박수) 현황
export const fetchWorkerHealthStatus = param => reqGet('/v3/api/app/getWorkerHealthStatus', { ...param });

// 알림 이력
export const fetchAlertNotificationLog = param => reqGet('/v3/api/app/getAlertNotificationLog', { ...param });

// 알림 조치 사항 저장
export const createAlertDescription = param => reqPost('/v3/api/common/alert/description', { ...param });

//! 현대제철 추가
// 작업자 현황
export const fetchCurrentStatistics = param => reqGet('/v3/api/app/getCurrentStatistics', { ...param });

// 실시간 작업자 현황
export const fetchWorkerStatus = param => reqGet('/v3/api/app/getWorkerStatus', { ...param });

// 미조치 알람 이력
export const fetchLogNotificationEmptyDescription = param =>
    reqGet('/v3/api/app/getLogNotificationEmptyDescription', { ...param });
