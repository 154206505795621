import React from 'react';
import { useHistory } from 'react-router-dom';
import useTranslation from '../../../../util/hooks/useTranslation';
import { useDispatch } from 'react-redux';
import Button from '../../../Common/Button';
import { setSelectedAlertInfo } from '../../../../reducers/Notification';

const CustomItemComponent = ({ event, setOpen }) => {
    const t = useTranslation('TimeLine');
    const storeDispatch = useDispatch();
    const history = useHistory();

    const handleRedirectClick = () => {
        history.push(`/dashboard/status/${event.targetNum}`);
        setOpen(false);
    };

    // const handleRedirectClick = () => {
    //     history.push(`/worker/status/${event.targetNum}`);
    //     setOpen(false);
    // };

    const handleSeeLocationClick = () => {
        storeDispatch(setSelectedAlertInfo(event));
    };

    return (
        <>
            <div className={'alarm-list'}>
                <b className={event.typeClassName}>{event.type}</b>
                <h4 className="timeline-title mr-2" style={{ display: 'inline-block' }}>
                    {event.sensingType && `${event.sensingType} / `}
                    {event.targetName}
                </h4>

                {/*{event.location && (*/}
                {/*    <p>*/}
                {/*        {t('Location')} : {event.location}*/}
                {/*    </p>*/}
                {/*)}*/}
            </div>
            {/*<div className={'alarm-tracing-button'}>*/}
            {/*    <Button className="btn-secondary mr-1" onClick={handleRedirectClick}>*/}
            {/*        {t('Tracing')}*/}
            {/*    </Button>*/}
            {/*    <Button className="btn-secondary" onClick={handleSeeLocationClick}>*/}
            {/*        {t('Location')}*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </>
    );
};

export default CustomItemComponent;
