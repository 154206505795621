import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    .img-container {
        width: 30%;
    }
    .status-container {
        width: 70%;
    }
    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        .img-container {
            width: 100%;
        }
        .status-container {
            width: 100%;
            margin: 10px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
`;

export const ImageSurface = styled.div`
    background-image: url(${({ img }) => img});
    background-repeat: no-repeat;
    background-size: contain;
    height: 90%;
    min-width: 90px;
    min-height: 90px;
`;

export const makeTooltipStyles = ({ textAlign } = { textAlign: 'center' }) => {
    return {
        width: 'max-content',
        textAlign,
        color: 'rgba(0, 0, 0, 0.65)',
        fontWeight: 800,
        backgroundColor: 'rgb(255,255,255)',
        border: '1px solid rgba(0, 0, 0, 0.35)',
        borderRadius: '5px',
    };
};
