import React, { useState, useEffect } from 'react';
import Card from '../Components/WidgetCard';
import { Row, Col, Table } from 'reactstrap';
import styled from 'styled-components';
import { fetchTagBatteryCount } from '../../../api/asset';
import useMonitor from '../util/useMonitor';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

const defaultData = {
    0: 0,
    10: 0,
    30: 0,
    60: 0,
    80: 0,
    unknown: 0,
};

// const batteryStatus = {
//     0: 'lost',
//     10: 'danger',
//     30: 'warning',
//     60: 'normal',
//     80: 'normal',
//     100: 'normal',
//     lost: 0,
//     danger: 10,
//     warning: 30,
//     normal: [60, 80, 100],
// };

const BatteryContainer = styled.div`
    width: 60%;
    height: 90px;
    padding: 2px 5px;
    margin-top: 10px;
    border: 3px solid #000;
    border-radius: 5px;
    display: flex;
    flex-direction: column-reverse;
    &:before {
        content: '';
        background-color: #000;
        width: 20px;
        height: 10px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 3px 3px 0 0;
    }
`;

const BatteryBlock = () => {
    return (
        <div
            style={{
                width: '100%',
                height: '20%',
                padding: '2px 0',
            }}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#000',
                    borderRadius: '3px',
                }}
            />
        </div>
    );
};

const Battery = ({ level }) => {
    const [lengthArr, setLengthArr] = useState([]);
    useEffect(() => {
        const arr = [];
        arr.length = level;
        arr.fill(0);
        setLengthArr(arr);
        return () => {
            setLengthArr([]);
        };
    }, []);
    return (
        <BatteryContainer>
            {level ? (
                lengthArr.map((v, i) => <BatteryBlock key={i} />)
            ) : (
                <div
                    className={'flex-center'}
                    style={{
                        borderRadius: '3px',
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#000',
                    }}
                >
                    <i className="fas fa-exclamation-triangle" style={{ fontSize: '1.5rem', color: '#fff' }} />
                </div>
            )}
        </BatteryContainer>
    );
};

const TagBatteryStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const { t } = useTranslation();

    const { data } = useMonitor({
        config,
        defaultData,
        fetchData: fetchTagBatteryCount,
        makeData: ({ newData }) => {
            const batteryCountData = newData.rows;

            let batteryStatus = {
                0: 0,
                10: 0,
                30: 0,
                60: 0,
                80: 0,
                unknown: 0,
            };

            if (batteryCountData && batteryCountData.length > 0) {
                for (let i = 0; i < batteryCountData.length; i++) {
                    switch (batteryCountData[i].division) {
                        case '0':
                            batteryStatus[0] += batteryCountData[i].count;
                            break;
                        case '10':
                        case '20':
                            batteryStatus[10] += batteryCountData[i].count;
                            break;
                        case '30':
                        case '40':
                        case '50':
                            batteryStatus[30] += batteryCountData[i].count;
                            break;
                        case '60':
                        case '70':
                            batteryStatus[60] += batteryCountData[i].count;
                            break;
                        case '80':
                        case '90':
                            batteryStatus[80] += batteryCountData[i].count;
                            break;
                        case 'unknown':
                            batteryStatus['unknown'] += batteryCountData[i].count;
                            break;
                        default:
                            break;
                    }
                }
            }

            return batteryStatus;
        },
    });

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <div className={'scrollbar-container'}>
                <PerfectScrollbar>
                    <Row className={'mb-2'}>
                        <Col className={'flex-center'} style={{ flexDirection: 'column' }}>
                            <Battery level={5} />
                            <div>100% ~ 80%</div>
                            <div>{data[80]}</div>
                        </Col>
                        <Col className={'flex-center'} style={{ flexDirection: 'column' }}>
                            <Battery level={4} />
                            <div>79% ~ 60%</div>
                            <div>{data[60]}</div>
                        </Col>
                        <Col className={'flex-center'} style={{ flexDirection: 'column' }}>
                            <Battery level={3} />
                            <div>59% ~ 30%</div>
                            <div>{data[30]}</div>
                        </Col>
                    </Row>
                    <Row className={'mb-2'}>
                        <Col className={'flex-center'} style={{ flexDirection: 'column' }}>
                            <Battery level={2} />
                            <div>29% ~ 10%</div>
                            <div>{data[10]}</div>
                        </Col>
                        <Col className={'flex-center'} style={{ flexDirection: 'column' }}>
                            <Battery level={1} />
                            <div>9% ~ 0%</div>
                            <div>{data[0]}</div>
                        </Col>
                        <Col className={'flex-center'} style={{ flexDirection: 'column' }}>
                            <Battery level={0} />
                            <div>{t('TagBatteryStatus;Unknown')}</div>
                            <div>{data['unknown']}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {t('TagBatteryStatus;Current / Count')}
                            <Table className={'mb-0'}>
                                <thead>
                                    <tr>
                                        <th className={'text-center'} style={{ width: '20%' }}>
                                            {t('TagBatteryStatus;Total')}
                                        </th>
                                        <th className={'text-center'} style={{ width: '20%' }}>
                                            {t('TagBatteryStatus;Normal')}
                                        </th>
                                        <th className={'text-center'} style={{ width: '20%' }}>
                                            {t('TagBatteryStatus;Warning')}
                                        </th>
                                        <th className={'text-center'} style={{ width: '20%' }}>
                                            {t('TagBatteryStatus;Danger')}
                                        </th>
                                        <th className={'text-center'} style={{ width: '20%' }}>
                                            {t('TagBatteryStatus;Unknown')}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className={'text-center'} style={{ width: '20%' }}>
                                            {data[80] + data[60] + data[30] + data[10] + data[0] + data['unknown']}
                                        </td>
                                        <td className={'text-center'} style={{ width: '20%' }}>
                                            {data[80] + data[60]}
                                        </td>
                                        <td className={'text-center'} style={{ width: '20%' }}>
                                            {data[30] + data[10]}
                                        </td>
                                        <td className={'text-center'} style={{ width: '20%' }}>
                                            {data[0]}
                                        </td>
                                        <td className={'text-center'} style={{ width: '20%' }}>
                                            {data['unknown']}
                                        </td>
                                    </tr>
                                </thead>
                            </Table>
                        </Col>
                    </Row>
                </PerfectScrollbar>
            </div>
            {children}
        </Card>
    );
};

export default TagBatteryStatus;
