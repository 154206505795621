import React, { memo } from 'react';
import { ReactTableDefaults } from 'react-table';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FixedSizeList as List, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import styled from 'styled-components';
import cx from 'classnames';

const gridDefaultOptions = {
    className: '-striped -highlight -fixed',
    resizable: false,
    filterable: false,
    sortable: false,
    showPageSizeOptions: false,
    defaultPageSize: 100,
};

//const StyledReactTable = styled(ReactTable)`
const StyledReactTable = styled.div`
    & *::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
    }
    & *::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: #f5f5f5;
    }
    & *::-webkit-scrollbar-thumb {
        background-color: #929292;
        border: 1px solid #e9ecef;
        border-radius: 3px;
    }
`;

const StyledList = styled(List)`
    overflow-x: hidden !important;
`;

const TbodyComponent = props => {
    return (
        <PerfectScrollbar options={{ suppressScrollX: true }} style={props.style}>
            <ReactTableDefaults.TbodyComponent {...props} />
        </PerfectScrollbar>
    );
};

const addSubRows = (acc, subRows) => {
    if (subRows && Array.isArray(subRows)) {
        subRows.forEach(subRowProps => {
            acc.push({ ...subRowProps.props.children[0].props });
            addSubRows(acc, subRowProps.props.children[1]);
        });
    }
};

const WindowTbodyComponent = props => {
    const { children, style, rows, ...restProps } = props;
    const dataRowPropsArr = children[0];
    const padRowPropsArr = children[1] || [];
    const expandedRowPropsArr = dataRowPropsArr.reduce((acc, row) => {
        const rowInfo = row.props.children;
        const subRows = rowInfo[1];
        acc.push({ ...rowInfo[0].props });
        addSubRows(acc, subRows);
        // if (subRows && Array.isArray(subRows)) {
        //     subRows.forEach(subRowProps => {
        //         acc.push({ ...subRowProps.props.children[0].props });
        //     });
        // }
        return acc;
    }, []);
    padRowPropsArr.slice(0, Math.max(0, 100 - expandedRowPropsArr.length)).forEach(row => {
        expandedRowPropsArr.push({ ...row.props.children.props });
    });

    const Row = memo(({ index, style }) => {
        // console.log({ index, style });
        const rowProps = { ...expandedRowPropsArr[index] };
        const { children, className, ...rest } = rowProps;
        // console.log({ ...rowProps, rest });
        const expandableStyle = { ...style };
        // rowProps.key = key;
        expandableStyle.borderBottom = '1px solid #e9ecef';
        rowProps.style = expandableStyle;
        // console.log(rowProps);
        return <ReactTableDefaults.TrComponent {...rowProps} />;
        // return (
        //     <div className={cx('rt-tr', className)} role="row" {...rest}>
        //         {children}
        //     </div>
        // );
    }, areEqual);

    // const CustomScrollbar = ({ onScroll, forwardedRef, style, children }) => {
    //     const refSetter = useCallback(scrollbarsRef => {
    //         console.log(scrollbarsRef);
    //         if (scrollbarsRef) {
    //             forwardedRef(scrollbarsRef.view);
    //         } else {
    //             forwardedRef(null);
    //         }
    //     }, []);
    //
    //     const handleScroll = useCallback(target => {
    //         onScroll({ currentTarget: target });
    //     }, []);
    //
    //     return (
    //         <PerfectScrollbar
    //             options={{ suppressScrollX: true }}
    //             ref={refSetter}
    //             style={{ ...style, overflow: 'hidden' }}
    //             onScrollX={handleScroll}
    //             onScrollY={handleScroll}
    //         >
    //             {children}
    //         </PerfectScrollbar>
    //     );
    // };

    // const Scrollbar = forwardRef((props, ref) => {
    //     return <CustomScrollbar {...props} forwardedRef={ref} />;
    // });

    return (
        // <PerfectScrollbar options={{ suppressScrollX: true }} style={{ ...style, width: '100%', height: '100%' }}>
        <ReactTableDefaults.TbodyComponent {...restProps} style={style}>
            <AutoSizer>
                {({ height, width }) => {
                    return (
                        <StyledList
                            style={{ overflow: 'overlay' }}
                            height={height}
                            itemCount={expandedRowPropsArr.length}
                            itemSize={33}
                            width={width}
                        >
                            {Row}
                        </StyledList>
                    );
                }}
            </AutoSizer>
        </ReactTableDefaults.TbodyComponent>
        // </PerfectScrollbar>
    );
};

///////////////////////////// Deprecated /////////////////////////////
// use Table
export const WindowDataGrid = props => {
    return (
        <StyledReactTable
            {...gridDefaultOptions}
            className={cx(gridDefaultOptions.className, props.clickableRow && 'interactive-row', 'window-grid')}
            TbodyComponent={WindowTbodyComponent}
            noDataText={'항목이 존재하지 않습니다'}
            previousText={'이전'}
            nextText={'다음'}
            pageText={null}
            ofText={'/'}
            {...props}
        />
    );
};

const DataGrid = props => {
    return (
        <StyledReactTable
            {...gridDefaultOptions}
            className={cx(gridDefaultOptions.className, props.clickableRow && 'interactive-row')}
            TbodyComponent={TbodyComponent}
            {...props}
        />
    );
};

export default DataGrid;
