import React, { useState } from 'react';
import { useSettings } from '../util/useSettings';
import { useDispatch } from 'react-redux';
import { WidgetName, IntervalTime, HiddenHeader } from './WidgetSettingOptions';
import Modal from '../../Common/Modal';
import useTranslation from '../../../util/hooks/useTranslation';
import { editWidgetSettings } from '../../../reducers/Dashboards/DashboardEdit';

const WidgetSettingModal = ({
    modal = false,
    toggleModal = function () {
        modal = !modal;
    },
    widgetInfo,
    socketWidget,
    hiddenHeader,
    headerTitle,
    okCallback,
    cancelCallback,
    intervalTimeOptions,
    children,
    ...restProps
}) => {
    const dispatch = useDispatch();
    const t = useTranslation('Widget');
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings, defaultSettings } = config;

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const handleWidgetNameChange = name => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, name: name };
        });
    };

    const handleIntervalTimeChange = selectedIntervalTime => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, intervalTime: selectedIntervalTime };
        });
    };

    const handleHiddenHeaderChange = checkedHiddenHeader => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, hiddenHeader: checkedHiddenHeader };
        });
    };

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: data,
                }),
            );
        }
    };

    const handleCancelClick = () => {
        if (cancelCallback) {
            cancelCallback();
        }
        setUpdatedWidgetInfo({});
    };

    return (
        <Modal
            initModal={modal}
            toggleModal={toggleModal}
            headerTitle={headerTitle}
            okCallback={okCallback ? callbackParam => okCallback(callbackParam) : handleSubmitClick}
            callbackParam={updatedWidgetInfo}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <div className={'modal-body'}>
                <div className={'flx-col gap-4 pnt-label-5'}>
                    <WidgetName
                        value={
                            updatedWidgetInfo.name !== undefined
                                ? updatedWidgetInfo.name
                                : customSettings && customSettings.name
                                ? customSettings.name
                                : t(`${defaultSettings.name}`)
                        }
                        onChange={handleWidgetNameChange}
                    />

                    {!socketWidget && (
                        <IntervalTime
                            value={
                                updatedWidgetInfo.intervalTime ? updatedWidgetInfo.intervalTime : settings.intervalTime
                            }
                            onChange={handleIntervalTimeChange}
                            options={intervalTimeOptions ? intervalTimeOptions : null}
                        />
                    )}

                    {hiddenHeader && (
                        <HiddenHeader
                            checked={
                                typeof updatedWidgetInfo.hiddenHeader !== 'undefined'
                                    ? updatedWidgetInfo.hiddenHeader
                                    : customSettings && typeof customSettings.hiddenHeader !== 'undefined'
                                    ? customSettings.hiddenHeader
                                    : false
                            }
                            onChange={handleHiddenHeaderChange}
                        />
                    )}

                    {children}
                </div>
            </div>
        </Modal>
    );
};

export default WidgetSettingModal;
