import { reqGet, reqDelete, reqPatch, reqPost } from './index';

////사용자(안전 관리자) 관리
//안전 관리자 계정 단일 조회
export const getManagerAccountApi = param => reqGet(`/v3/api/ws01/safety/manager/${param.userNum}`);
//안전 관리자 계정 목록 조회
export const getManagerAccountListApi = param => reqGet(`/v3/api/ws01/safety/manager`, { ...param });
//안전 관리자 계정 등록
export const createManagerAccountApi = param => reqPost('/v3/api/ws01/safety/manager', { ...param });
//안전 관리자 계정 수정
export const updateManagerAccountApi = param => reqPatch(`/v3/api/ws01/safety/manager/${param.userNum}`, { ...param });
//안전 관리자 계정 삭제
export const deleteManagerAccountApi = param => reqDelete(`/v3/api/ws01/safety/manager/${param.userNum}`);

////사용자(안전 관리자) 소속(업체) 관리
export const getManagerCompanyListApi = param => reqGet(`/v3/api/ws01/safety/manager/company`, { ...param });

////사용자(안전 관리자) 부서 관리
export const getManagerDeptListApi = param => reqGet('/v3/api/ws01/safety/manager/dept', { ...param });
