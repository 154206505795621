import React, { useContext, useMemo } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { Card, Label } from '@components';
import { useTranslation } from '@hooks';

import { setSelectedWorker } from 'Components/MainPages/WorkerSafetyDashboard/workerStatusReducer';
import { WorkStatusContext, WorkStatusDispatchContext } from 'Components/MainPages/WorkerSafetyDashboard';

import { TiWarning } from 'react-icons/ti';
import { AiFillAlert } from 'react-icons/ai';

const falsyToHyphen = value => {
    return value ? value : '-';
};

const MakeGasInfo = ({ name, status, value }) => {
    const statusT = useTranslation('RealTimeSensorStatusColumn');
    return (
        <div key={name}>
            <span className="profile-label">{name} 상태 (수치) : </span>
            {`${status ? statusT(status) : '-'} (${value ? value : '-'})`}
        </div>
    );
};

const SelectedWorker = () => {
    const t = useTranslation('Work Status');
    const statusT = useTranslation('RealTimeSensorStatusColumn');
    const { selectedWorker } = useContext(WorkStatusContext);
    const dispatch = useContext(WorkStatusDispatchContext);

    const {
        isSOS,
        isHealthAlert,
        isGasAlert,
        name,
        dateOfBirth,
        sex,
        phoneNum,
        teamName,
        fcName,
        bandBatteryStatus,
        bandBatteryValue,
        heartBeatStatus,
        heartBeatValue,
    } = selectedWorker;

    const gasInfoArray = useMemo(() => {
        const {
            lelStatus,
            lelValue,
            coStatus,
            coValue,
            h2sStatus,
            h2sValue,
            o2Status,
            o2Value,
            co2Status,
            co2Value,
        } = selectedWorker;
        return [
            { name: 'LEL', status: lelStatus, value: lelValue },
            { name: 'CO', status: coStatus, value: coValue },
            { name: 'H2S', status: h2sStatus, value: h2sValue },
            { name: 'O2', status: o2Status, value: o2Value },
            { name: 'CO2', status: co2Status, value: co2Value },
        ];
    }, [selectedWorker]);

    return (
        <Card
            header={{
                title: t('Worker Info.'),
                action: (
                    <span
                        className={'icon-close cursor-pointer'}
                        onClick={() => {
                            dispatch(setSelectedWorker(null));
                        }}
                    />
                ),
            }}
            className={'overlay-card width-400px'}
        >
            <PerfectScrollbar
                options={{
                    wheelPropagation: false,
                }}
            >
                <div>
                    {selectedWorker && (
                        <div className={'worker-detail-container'}>
                            <div className={'profile-container'}>
                                <div className={'profile-content-container'}>
                                    {isSOS && (
                                        <div className={'emergency-label bg-danger'}>
                                            <AiFillAlert color="white" />
                                            <div className={'color-white ml-1 pnt-txt txt-small'}>{'위험(SOS)'}</div>
                                        </div>
                                    )}

                                    {isHealthAlert && (
                                        <div className={'emergency-label bg-danger'}>
                                            <AiFillAlert color="white" />
                                            <div className={'color-white ml-1 pnt-txt txt-small'}>{'생체이상'}</div>
                                        </div>
                                    )}

                                    {isGasAlert && (
                                        <div className={'emergency-label bg-warning'}>
                                            <TiWarning color="white" />
                                            <div className={'color-black ml-1 mr-1 pnt-txt txt-small'}>
                                                {'가스위험'}
                                            </div>
                                        </div>
                                    )}

                                    <div className={'profile-name'}>
                                        <div className={'text-ellipsis'}>{name}</div>
                                        <div>
                                            ({dateOfBirth ? dateOfBirth : '-'},{sex ? t(sex) : '-'})
                                        </div>
                                    </div>

                                    <div className={'ml-1 mt-1'}>
                                        <div className="flx-col gap-0">
                                            <div>
                                                <span className="profile-label">{t('Location')} : </span>
                                                {falsyToHyphen(fcName)}
                                            </div>
                                            <div>
                                                <span className="profile-label">{t('Mobile')} : </span>
                                                {falsyToHyphen(phoneNum)}
                                            </div>
                                            <div>
                                                <span className="profile-label">{t('Unit')} : </span>
                                                {falsyToHyphen(teamName)}
                                            </div>
                                            <div>
                                                <span className="profile-label">{'밴드 배터리 상태 / 잔여량'} : </span>
                                                {`${bandBatteryStatus ? statusT(bandBatteryStatus) : '-'} (${
                                                    bandBatteryValue ? bandBatteryValue + '%' : '-'
                                                })`}
                                            </div>
                                            <div>
                                                <span className="profile-label">{'심박 상태 / 심박수'} : </span>
                                                {`${heartBeatStatus ? statusT(heartBeatStatus) : '-'} (${
                                                    heartBeatValue ? heartBeatValue : '-'
                                                })`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={'worker-detail-content'}>
                                <div className={'detail-card'}>
                                    <div>
                                        <Label name={'가스정보'} labelValueClassName={'label-dot'} />
                                    </div>
                                    <div className={'ml-1 mt-1'}>
                                        {gasInfoArray.map(gasInfo => {
                                            return MakeGasInfo(gasInfo);
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </PerfectScrollbar>
        </Card>
    );
};

export default SelectedWorker;
