import React, { useContext } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import useTranslation from '@hooks/useTranslation';

import { WorkStatusContext } from 'Components/MainPages/WorkerSafetyDashboard';
import { nullToZero } from 'Components/MainPages/util/common/util';

import { makeTooltipStyles, Wrapper, ImageSurface } from './styled';

import userImg from 'assets/images/enclosed_worker.png';

const EnclosedOverview = () => {
    const t = useTranslation('Overview');
    const {
        totalStatus: { currentEnclosedWorkerCount },
    } = useContext(WorkStatusContext);

    return (
        <Wrapper>
            <div className={'img-container'}>
                <div className={'recharts-surface'}>
                    <ImageSurface img={userImg} alt={'userImg'} />
                </div>
            </div>
            <div className="pl-0 ml-2 status-container">
                <div className="pnt-txt txt-border txt-bold p-1 title-plate">{t('Enclosed area status')}</div>
                <div className={'flx-row'}>
                    <div className="d-flex flx-col align-items-center gap-0">
                        <span className={'txt-overview-main'}>{nullToZero(currentEnclosedWorkerCount)}</span>
                        <div className={'txt-overview-sub'} id={'lostSignal'}>
                            {t('Worker Count')}
                        </div>
                        <UncontrolledTooltip
                            hideArrow={true}
                            style={makeTooltipStyles({ textAlign: 'left' })}
                            target={'lostSignal'}
                            placement={'top'}
                        >
                            {t('Worker Count')}
                        </UncontrolledTooltip>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default EnclosedOverview;
