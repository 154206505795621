import React, { useReducer } from 'react';
import { Route } from 'react-router-dom';

import { ErrorHandleSwitch } from 'Components/MainPages/Components/Router';

import IndoorCoalStorageSiteStatus from './IndoorCoalStorageSiteStatus';
import LargeMonitorControl from './LargeMonitorControl';
import RealTimeWorkerStatus from './RealTimeWorkerStatus';

import workStatusReducer, { initialState } from 'Components/MainPages/WorkerSafetyDashboard/workerStatusReducer';

export const WorkStatusContext = React.createContext();
export const WorkStatusDispatchContext = React.createContext();

const WorkerSafetyDashboard = ({ match }) => {
    const [state, dispatch] = useReducer(workStatusReducer, initialState);

    return (
        <ErrorHandleSwitch>
            <>
                <WorkStatusDispatchContext.Provider value={dispatch}>
                    <WorkStatusContext.Provider value={state}>
                        <Route exact path={`${match.path}/monitor`} component={LargeMonitorControl} />
                        <Route exact path={`${match.path}/status`} component={IndoorCoalStorageSiteStatus} />
                    </WorkStatusContext.Provider>
                </WorkStatusDispatchContext.Provider>
                <Route exact path={`${match.path}/worker`} component={RealTimeWorkerStatus} />
            </>
        </ErrorHandleSwitch>
    );
};

export default WorkerSafetyDashboard;
