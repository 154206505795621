import { createSlice } from '@reduxjs/toolkit';

export const AUTH_SECTION_USER = 'user';
export const AUTH_SECTION_GROUP = 'group';
const DEFAULT_CATEGORY_CODE = 'ASSET';

export const makeFilterParam = filterInfo => {
    const { filterInfoCondition, categoryCode } = filterInfo;
    let dynamicCount = 0;
    return filterInfoCondition.reduce(
        (acc, { conditionId, conditionValues, conditionType }) => {
            if (conditionValues.length) {
                if (conditionType === 'dynamic') {
                    acc['targetPropertiesSearch.categoryCode'] = categoryCode ? categoryCode : DEFAULT_CATEGORY_CODE;
                    acc[`targetPropertiesSearch.propertySearchs[${dynamicCount}].propertyId`] = conditionId;
                    acc[`targetPropertiesSearch.propertySearchs[${dynamicCount}].values`] = conditionValues.join(',');
                    dynamicCount++;
                } else {
                    acc[conditionId] = conditionValues.join(',');
                }
            }
            return acc;
        },
        { categoryCode },
    );
};

export const getAuthorityGroup = (authority, filter) => {
    const { userList = [], groupList = [] } = authority;
    const accessRight = [];
    const owner = [];
    filter.filterInfoAccessRight.forEach(authInfo => {
        let auth = null;
        if (authInfo.groupNum === -1 || authInfo.userNum === -1) {
            auth = {
                name: 'All users',
                id: -1,
                section: AUTH_SECTION_GROUP,
            };
        } else {
            if (authInfo.userNum) {
                const authUser = userList.find(user => user.userNum === authInfo.userNum);
                if (authUser) {
                    auth = {
                        name: authUser.userName,
                        id: authUser.userNum,
                        section: AUTH_SECTION_USER,
                    };
                }
            } else if (authInfo.groupNum) {
                const authGroup = groupList.find(group => group.groupNum === authInfo.groupNum);
                if (authGroup) {
                    auth = {
                        name: authGroup.groupName,
                        id: authGroup.groupNum,
                        section: AUTH_SECTION_GROUP,
                    };
                }
            }
        }

        if (auth) {
            if (authInfo.accessScope === 'R') {
                accessRight.push(auth);
            } else if (authInfo.accessScope === 'W') {
                owner.push(auth);
            }
        }
    });
    return { accessRight, owner };
};

export const initialState = {
    authority: { userList: [], groupList: [] },
};

const { actions, reducer } = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setAuthority: (state, action) => {
            state.authority = action.payload;
        },
    },
});

export const { setAuthority } = actions;
export default reducer;
