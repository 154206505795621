import React, { useContext, useEffect, useState, useMemo, useRef } from 'react';
import { Collapse } from 'reactstrap';

import cx from 'classnames';

import { Label } from '@components';
import { useTranslation } from '@hooks';

import { WorkStatusContext, WorkStatusDispatchContext } from 'Components/MainPages/WorkerSafetyDashboard';
import { setSelectedPerson, setSelectedFcNum } from '../../../../workerStatusReducer';

import WorkerList from '../Components/WorkerList';
import NoDataComponent from '../Components/NoDataComponent';

const makeGeofencePeopleList = (list = [], searchInput) => {
    return list.map(geofenceList => {
        const filteredWorkerList = geofenceList.workers.length
            ? geofenceList.workers.filter(({ name }) => {
                  return name.includes(searchInput);
              })
            : [];

        return {
            ...geofenceList,
            workers: filteredWorkerList,
        };
    });
};

const WorkersByGeofence = () => {
    const t = useTranslation('WorkerStatus');
    const dispatch = useContext(WorkStatusDispatchContext);
    const labelRefs = useRef({});

    const {
        defaultList: { defaultGeofencePeopleList },
        activeTabId,
        searchInput,
        selectedPerson,
        selectedFcNum,
    } = useContext(WorkStatusContext);

    const { common, enclosed } = defaultGeofencePeopleList || {};

    const [collapse, setCollapse] = useState({});

    const geofencePeopleList = useMemo(
        () => [
            {
                list: makeGeofencePeopleList(common, searchInput),
                name: 'Common',
            },
            {
                list: makeGeofencePeopleList(enclosed, searchInput),
                name: 'Sealed',
            },
        ],
        [common],
    );

    const isGeofenceListLength = geofencePeopleList.some(peopleList => {
        return peopleList.list.length;
    });

    useEffect(() => {
        if (activeTabId !== 'geofence') {
            setCollapse({});
        }
    }, [activeTabId]);

    // 특정 작업자 검색하면 내려가게 설정
    useEffect(() => {
        if (selectedPerson || selectedFcNum) {
            const totalList = geofencePeopleList.reduce((map, { list }) => {
                if (Array.isArray(list)) {
                    list.forEach(({ fcNum, workers }) => {
                        map.set(fcNum, workers);
                    });
                }
                return map;
            }, new Map());
            let foundFcNum = null;

            if (selectedPerson) {
                totalList.forEach((list, fcNum) => {
                    list.forEach(worker => {
                        if (worker.targetNum === selectedPerson.targetNum) {
                            foundFcNum = fcNum;
                        }
                    });
                });
            }

            if (selectedFcNum) {
                foundFcNum = selectedFcNum;
            }

            if (foundFcNum) {
                setCollapse({ [foundFcNum]: true });
                const selectedDOM = labelRefs.current[foundFcNum];

                selectedDOM.scrollIntoView({
                    block: 'start',
                });

                dispatch(setSelectedPerson(null));
                dispatch(setSelectedFcNum(null));
            }
        }
    }, [selectedPerson, geofencePeopleList, selectedFcNum]);

    const handleCollapseToggle = fcNum => {
        setCollapse(prevState => {
            return { ...prevState, [fcNum]: !prevState[fcNum] };
        });
    };

    const addToLabelRefs = el => {
        if (el && el.id) {
            labelRefs.current[el.id] = el;
        }
    };

    return (
        <div className={cx('worker-list-height', !isGeofenceListLength && 'place-center d-grid')}>
            {isGeofenceListLength ? (
                <>
                    {geofencePeopleList.map(peopleList => {
                        const { list, name } = peopleList;
                        return list.map(workArea => {
                            const { fcNum, fcName, workers } = workArea;
                            return (
                                <div
                                    ref={addToLabelRefs}
                                    className={'mb-2'}
                                    key={fcNum}
                                    id={fcNum}
                                    onClick={() => handleCollapseToggle(fcNum)}
                                >
                                    <Label
                                        name={`${t(name)}: ${fcName} / ${t('Worker')}: ${workers.length}${t('Count')}`}
                                        labelGroupClassName={'collapsible-list'}
                                        labelValueClassName={'label-dot'}
                                        value={
                                            <span
                                                className={cx(
                                                    'icon-small me-3',
                                                    collapse[fcNum] ? 'icon-up' : 'icon-down',
                                                )}
                                            />
                                        }
                                    />
                                    <Collapse
                                        isOpen={collapse[fcNum] || false}
                                        onClick={e => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        {!!workers.length &&
                                            workers.map(person => {
                                                return <WorkerList person={person} key={person.targetNum} />;
                                            })}
                                    </Collapse>
                                </div>
                            );
                        });
                    })}
                </>
            ) : (
                <NoDataComponent />
            )}
        </div>
    );
};

export default WorkersByGeofence;
