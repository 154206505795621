import React, { createContext, useEffect, useReducer, useState } from 'react';
import reducer, { initialState, setInitState } from '../reducer';
import TreeSelect from '../../../../../Common/Select/TreeSelect';
import { TreeMenuList } from './Parts';

export const CustomTreeSelectContext = createContext();
export const CustomTreeSelectDispatchContext = createContext();

const TreeSelectWithOption = ({ onChange, isEditable = 'Y', selected, disableOption, ...restProps }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [selectedItem, setSelectedItem] = useState(selected);
    const handleChange = selected => {
        setSelectedItem(selected);
    };

    useEffect(() => {
        dispatch(setInitState({ isEditable, disableOption }));
    }, [isEditable, disableOption]);

    useEffect(() => {
        if (typeof onChange === 'function') {
            onChange(selectedItem, state.isEditable);
        }
    }, [selectedItem, state.isEditable]);

    return (
        <CustomTreeSelectDispatchContext.Provider value={dispatch}>
            <CustomTreeSelectContext.Provider value={state}>
                <TreeSelect
                    {...restProps}
                    selected={selectedItem}
                    onChange={handleChange}
                    MenuListComponent={TreeMenuList}
                />
            </CustomTreeSelectContext.Provider>
        </CustomTreeSelectDispatchContext.Provider>
    );
};

export default TreeSelectWithOption;
