import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    workplaceList: [],
    workAreaList: [],
};

const { actions, reducer } = createSlice({
    name: 'common/workplaceInfo',
    initialState,
    reducers: {
        setWorkplaceList: (state, action) => {
            state.workplaceList = action.payload;
        },
        setWorkAreaList: (state, action) => {
            state.workAreaList = action.payload;
        },
    },
});

export const { setWorkplaceList, setWorkAreaList } = actions;
export default reducer;
