import React, { useContext, useEffect, useState } from 'react';
import Control from 'react-leaflet-control';
import { Button, GeofenceLayer, Checkbox } from '../../../Common';
import { useTranslation } from 'react-i18next';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchIotItemList } from '../../../../api/asset';
import { setFloorInfo, setSelectedFloor, clearData } from '../massRealTimeLocationReducer';
import { MassRealTimeLocationStatusContext, MassRealTimeLocationStatusDispatchContext } from '../index';
import { useSelector } from 'react-redux';

const SingleTarget = ({ mapRef }) => {
    const { t } = useTranslation();

    const { floorInfo, geofenceInfo, selectedItem, realTimeLog } = useContext(MassRealTimeLocationStatusContext);
    const dispatch = useContext(MassRealTimeLocationStatusDispatchContext);
    const { selectableFloorList } = useSelector(state => state.FloorInfo);

    const [positionTracking, setPositionTracking] = useState(false);

    const { promise: getIotItem } = useAsync({
        promise: fetchIotItemList,
        resolve: response => {
            const { rows } = response;
            const data = rows[0];
            dispatch(setSelectedFloor(data.floorId));
            dispatch(setFloorInfo(selectableFloorList.find(floor => floor.floorId === data.floorId)));
        },
    });

    useEffect(() => {
        if (realTimeLog.data[0]) {
            const map = mapRef.current.leafletElement;
            map.setView(realTimeLog.data[0].location.latLng);
        }
    }, []);

    useEffect(() => {
        if (selectedItem.hasOwnProperty('value')) {
            getIotItem({ keyType: 'targetId', targetId: selectedItem.value });
        }
    }, [selectedItem]);

    useEffect(() => {
        if (positionTracking) {
            const map = mapRef.current.leafletElement;
            map.setView(realTimeLog.data[0].location.latLng);
        }
    }, [realTimeLog]);

    const handleRefreshClick = () => {
        dispatch(clearData());
        setPositionTracking(false);
    };

    const handleItemPositionCheck = e => {
        setPositionTracking(e.target.checked);

        if (realTimeLog.data[0]) {
            const map = mapRef.current.leafletElement;
            map.setView(realTimeLog.data[0].location.latLng);
        }
    };

    return (
        <>
            <Control position={'topleft'} className={'flx-row'}>
                <div className={'checkbox-container map-text mr-1'}>
                    <Checkbox
                        className={'form-h-small'}
                        id={'itemPosition'}
                        name={t('RealTimeLocationStatus;Always Show Item Position In Center')}
                        checked={positionTracking}
                        handleChecked={handleItemPositionCheck}
                    />
                </div>
                {Object.keys(selectedItem).length > 0 && (
                    <Button
                        iconClassName={'icon-replay'}
                        onClick={handleRefreshClick}
                        className={'btn-icon-only form-h-small btn-gray'}
                    />
                )}
            </Control>

            <Control position="topright">
                <div className={'item-container'}>
                    <span className={'map-text'}>{`${t('RealTimeLocationStatus;Current Floor')} : `}</span>
                    <span className={'map-text'}>{floorInfo && floorInfo.floorId}</span>
                </div>
            </Control>

            {geofenceInfo && geofenceInfo.length > 0 && <GeofenceLayer geofenceList={geofenceInfo} />}
        </>
    );
};

export default SingleTarget;
