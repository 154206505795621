export const setWorkerListByFloor = state => {
    const workerList = state.workerList;
    const newWorkerListByFloor = {};

    for (let workerNum in workerList) {
        if (workerList.hasOwnProperty(workerNum)) {
            const workerInfo = workerList[workerNum];
            const byFloor = newWorkerListByFloor[workerInfo.location.floor];
            if (byFloor) {
                byFloor.push(workerInfo);
            } else {
                newWorkerListByFloor[workerInfo.location.floor] = [workerInfo];
            }
        }
    }

    state.workerListByFloor = newWorkerListByFloor;
};

export const calcCategoryStatus = (categoryStatus, tagInfo) => {
    const targetNum = tagInfo.target.targetNum;
    const lostSignal = tagInfo.states.LOST_SIGNAL.isActive;
    const workPlaceCode = tagInfo.workPlaceCode;
    for (let key in categoryStatus) {
        const status = categoryStatus[key];
        const existIdx = status.indexOf(targetNum);
        if (existIdx > -1) {
            status.splice(existIdx, 1);
        }
    }
    if (lostSignal !== 'YES') {
        if (categoryStatus[workPlaceCode]) {
            categoryStatus[workPlaceCode].push(targetNum);
        } else {
            categoryStatus[workPlaceCode] = [targetNum];
        }
    }
};
