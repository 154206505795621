import React from 'react';
import { FormGroup } from 'reactstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import FloorTreeSelect from './FloorTreeSelect';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextInput, Label, Checkbox, Select as ReactSelect } from '../../Common';
import SingleSelect from '../../Common/Select/SingleSelect';

export const WidgetName = ({ value, onChange }) => {
    const { t } = useTranslation();

    return (
        <Label
            name={t('Widget;Widget Name')}
            value={
                <TextInput
                    inputGroupClassName={'w-100'}
                    type={'text'}
                    name={'widgetName'}
                    id={'widgetName'}
                    placeholder={''}
                    value={value}
                    handleChange={e => {
                        const { value } = e.target;
                        onChange(value);
                    }}
                />
            }
        />
    );
};

export const IntervalTime = ({ value, onChange, options }) => {
    const { t } = useTranslation();

    return (
        <Label
            name={t('Widget;Interval Time')}
            value={
                <SingleSelect
                    className={'w-100'}
                    options={
                        options
                            ? options
                            : [
                                  { value: 10000, label: `10${t('Widget;seconds')}` },
                                  { value: 15000, label: `15${t('Widget;seconds')}` },
                                  { value: 20000, label: `20${t('Widget;seconds')}` },
                                  { value: 30000, label: `30${t('Widget;seconds')}` },
                              ]
                    }
                    value={value}
                    onChange={selected => onChange(selected)}
                />
            }
        />
    );
};

export const HiddenHeader = ({ checked, onChange }) => {
    const { t } = useTranslation();

    return (
        <Label
            name={t('Widget;Hide Header')}
            value={
                <Checkbox
                    id={'hiddenHeader'}
                    handleChecked={e => {
                        const { checked } = e.target;
                        onChange(checked);
                    }}
                    checked={checked}
                />
            }
        />
    );
};

export const ChartType = ({ value, onChange, options }) => {
    const { t } = useTranslation();

    return (
        <Label
            name={t('CategoryChart;Type of Graph')}
            value={
                <SingleSelect
                    className={'w-100'}
                    options={
                        options
                            ? options
                            : [
                                  { label: t('CategoryChart;Pie'), value: 'pie' },
                                  { label: t('CategoryChart;Doughnut'), value: 'doughnut' },
                              ]
                    }
                    value={value}
                    onChange={selected => onChange(selected)}
                />
            }
        />
    );
};

export const CategoryCode = ({ value, onChange }) => {
    const { t } = useTranslation();
    const { topCategoryList } = useSelector(state => state.CategoryInfo);

    return (
        <Label
            name={t('CategoryChart;Type of Category')}
            value={
                <SingleSelect
                    className={'w-100'}
                    options={topCategoryList}
                    value={value}
                    onChange={selected => onChange(selected)}
                />
            }
        />
    );
};

export const TagState = ({ value, onChange, options }) => {
    const { t } = useTranslation();

    return (
        <Label
            name={t('TagStateAlarmList;Tag State')}
            value={
                <ReactSelect
                    name={'tagState'}
                    className={'w-100 react-select'}
                    options={
                        options
                            ? options
                            : [
                                  { value: 'sos', label: 'SOS' },
                                  { value: 'battery', label: 'Low Battery' },
                                  { value: 'leaveAlone', label: 'Leave Alone' },
                                  { value: 'longStay', label: 'Long Stay' },
                                  { value: 'lostSignal', label: 'Lost Signal' },
                                  { value: 'fireAlarm', label: 'Fire Alarm' },
                                  { value: 'restriction', label: 'Restriction' },
                              ]
                    }
                    value={value}
                    onChange={selected => onChange(selected)}
                    isMulti
                    customControlStyles={{ width: '100%' }}
                    customMenuStyles={{ width: '100%' }}
                />
            }
        />
    );
};

export const Floor = ({ defaultValue = '', innerRef }) => {
    const { floorList } = useSelector(state => state.FloorInfo);
    return (
        <FormGroup>
            <Label for="floor">층</Label>
            <FloorTreeSelect floorList={floorList} selectedFloorStr={defaultValue} innerRef={innerRef} />
        </FormGroup>
    );
};

export const SingleFloor = ({ value, onChange }) => {
    const { t } = useTranslation();
    const { floorList } = useSelector(state => state.FloorInfo);

    return (
        <Label
            name={t('GeofenceStatus;Floor')}
            value={
                <FloorTreeSelect
                    floorList={floorList}
                    selectedFloorStr={value}
                    mode={'radioSelect'}
                    handleChange={selected => onChange(selected)}
                />
            }
        />
    );
};

export const DataInteger = ({ checked, onChange }) => {
    const { t } = useTranslation();

    return (
        <Label
            name={t('Widget;Change The Y-Axios To an Integer')}
            value={
                <Checkbox
                    id={'dataInteger'}
                    handleChecked={e => {
                        const { checked } = e.target;
                        onChange(checked);
                    }}
                    checked={checked}
                />
            }
        />
    );
};
