import React, { useContext } from 'react';

import cx from 'classnames';

import { WorkStatusContext } from 'Components/MainPages/WorkerSafetyDashboard';
import WorkerList from '../Components/WorkerList';
import NoDataComponent from '../Components/NoDataComponent';

const WorkersByDefault = () => {
    const { peopleList, searchInput } = useContext(WorkStatusContext);

    const searchPeopleList = peopleList.filter(({ name }) => {
        return name.includes(searchInput);
    });

    return (
        <div className={cx('worker-list-height', !searchPeopleList.length && 'place-center d-grid')}>
            {searchPeopleList.length ? (
                searchPeopleList.map(person => {
                    return <WorkerList key={person.targetNum} person={person} />;
                })
            ) : (
                <NoDataComponent />
            )}
        </div>
    );
};

export default WorkersByDefault;
