import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import Card from '../Components/WidgetCard';
import AlarmDetailPopup from './AlarmDetailPopup';
import { useSelector } from 'react-redux';
import * as cols from '../Components/GridColumns';
import { useSettings } from '../util/useSettings';
import Table from '../../Common/Table';
import { useTranslation } from 'react-i18next';

const getPosition = (el, position = { top: 0, left: 0 }) => {
    if (el) {
        position.top += el.offsetTop;
        position.left += el.offsetLeft;
        getPosition(el.offsetParent, position);
    }
    return position;
};

const initialData = [
    {
        evtTime: 1645000665,
        targetName: 'example',
        tagStateLabel: 'Long Stay',
        categoryName: 'asset',
    },
];

const TagStateAlarmList = ({ children, widgetInfo, ...restProps }) => {
    const { t } = useTranslation();
    const { mode } = useSelector(state => state.DashboardFrame);
    const columns = [cols.time({ t }), cols.targetName({ t }), cols.categoryName({ t }), cols.tagState({ t })];

    const { config } = widgetInfo;
    const settings = useSettings(config);
    // console.log(config);
    // const filteredData = useCallback(
    //     ({ newData }) => {
    //         // console.log(config);
    //         const settings = useSettings(config);
    //         const tagStateFilter = settings.tagState;
    //         // console.log({ tagStateFilter, newData });
    //         if (tagStateFilter && tagStateFilter.length) {
    //             return newData.filter(v => tagStateFilter.indexOf(v.tagState) >= 0);
    //         }
    //         return newData;
    //     },
    //     [config],
    // );
    const { tagAlarmList } = useSelector(state => state.TagInfo);
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const [filteredData, setFilteredData] = useState(tagAlarmList);
    // const { data } = useMonitor({
    //     config,
    //     defaultData,
    //     fetchData: generateData,
    //     makeData: filteredData,
    // });
    const widgetRef = useRef();
    const [position, setPosition] = useState({ x: 0, y: 0 });

    // const position = useMemo(() => {
    //     const position = { x: 0, y: 0 };
    //     if (widgetRef.current) {
    //         const widget = widgetRef.current;
    //         const { offsetWidth: width, offsetHeight: height } = widget;
    //         const { top: clientTop, left: clientLeft } = widget.getBoundingClientRect();
    //         const { top, left } = getPosition(widget);
    //         console.log(
    //             widgetRef.current,
    //             { top, left, width, height },
    //             widget.getBoundingClientRect(),
    //             getPosition(widget),
    //         );
    //         position.x = clientLeft - left + width / 2;
    //         position.y = clientTop - top + height / 2;
    //     }
    //     return position;
    // }, [widgetRef]);

    const [modal, setModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState({});
    const toggleModal = () => {
        setModal(!modal);
    };
    const handleClick = useCallback((e, data) => {
        setSelectedEvent(data);
        setModal(!modal);
    }, []);

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };
        if (widgetRef.current) {
            const widget = widgetRef.current;
            const { offsetWidth: width, offsetHeight: height } = widget;
            const { top: clientTop, left: clientLeft } = widget.getBoundingClientRect();
            const { top, left } = getPosition(widget);
            // console.log(
            //     'widget position - ',
            //     widget,
            //     { top, left, width, height },
            //     widget.getBoundingClientRect(),
            //     getPosition(widget),
            // );
            widgetPosition.x = clientLeft - left;
            widgetPosition.y = clientTop - top;
            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };

    // const getTrProps = useCallback((state, rowInfo, column) => {
    //     return {
    //         onMouseDown: e => {
    //             e.stopPropagation();
    //         },
    //         onClick: e => {
    //             const widgetPosition = getWidgetPosition();
    //             setPosition({
    //                 x: widgetPosition.x + widgetPosition.width / 2 - 100 + window.scrollX,
    //                 y: widgetPosition.y + 30 + window.scrollY,
    //             });
    //             handleClick(e, rowInfo.original);
    //         },
    //     };
    // }, []);

    const handleTrClick = selected => {
        const widgetPosition = getWidgetPosition();
        setPosition({
            x: widgetPosition.x + widgetPosition.width / 2 - 100 + window.scrollX,
            y: widgetPosition.y + 30 + window.scrollY,
        });
        handleClick(null, selected);
    };

    useEffect(() => {
        if (widgetRef.current) {
            const widgetPosition = getWidgetPosition();
            // popup default width 200
            // setPosition({ x: clientLeft - left + width / 2, y: clientTop - top + height / 2 });
            setPosition({ x: widgetPosition.x + widgetPosition.width / 2 - 100, y: widgetPosition.y + 30 });
        }
    }, []);

    useEffect(() => {
        let tagStateFilter = settings.tagState;

        // 카테고리 이름 표시
        tagAlarmList.map(tagList => {
            const category = categoryList.find(category => tagList.target.categoryCode === category.categoryCode);

            return {
                ...tagList,
                categoryName: category.categoryName,
            };
        });

        if (tagStateFilter && tagStateFilter.length) {
            tagStateFilter = tagStateFilter.map(v => v.value);
            const filteredTagAlarmList = tagAlarmList.filter(v => tagStateFilter.indexOf(v.tagState) >= 0);
            setFilteredData(addCategoryName(filteredTagAlarmList));
        } else {
            setFilteredData(addCategoryName(tagAlarmList));
        }
    }, [tagAlarmList]);

    const widgetData = useMemo(() => {
        return mode === 'edit' ? initialData : filteredData;
    }, [mode, filteredData]);

    const addCategoryName = tagAlarmList => {
        return tagAlarmList.map(tagList => {
            const category = categoryList.find(category => tagList.target.categoryCode === category.categoryCode);

            return {
                ...tagList,
                categoryName: category.categoryName,
            };
        });
    };

    return (
        <>
            <Card widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
                <div className={'he-100'}>
                    <Table paging={false} columns={columns} data={{ rows: widgetData }} onTrClick={handleTrClick} />
                </div>
                {children}
            </Card>
            {position.x && (
                <AlarmDetailPopup
                    modal={modal}
                    toggleModal={toggleModal}
                    selectedEvent={selectedEvent}
                    position={position}
                />
            )}
        </>
    );
};

export default TagStateAlarmList;
