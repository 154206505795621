import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    wmsUrl: '',
    wmsApiUrl: '',
    wmsSocketUrl: '',
    oAuthUrl: '',
    clientId: '',
    clientSecret: '',
    languageOption: false,
};

const { actions, reducer } = createSlice({
    name: 'common/appInfo',
    initialState,
    reducers: {
        setInitAppInfo: (state, action) => {
            const customAppConfig = action.payload;
            Object.entries(customAppConfig).forEach(([configId, configValue]) => {
                state[configId] = configValue;
            });
        },
    },
});

export const { setInitAppInfo } = actions;
export default reducer;
