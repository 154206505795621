import React, { useEffect, useReducer, useState } from 'react';
import { Button, RotatedImageOverlay, GeofenceLayer, Map, Card, Table } from '../../Common';
import GridLayoutCard from '../Components/GridLayoutCard';
import { useTranslation } from 'react-i18next';
import CongestionFilterGroup from './Component/CongestionFilterGroup';
import congestionStatusReducer, {
    initialState,
    setEndDate,
    setSelectedCategory,
    setSelectedFloor,
    setSelectedFloorInfo,
    setStartDate,
} from './congestionStatusReducer';
import { useSelector } from 'react-redux';
import { useAsync, useColumns } from '../../../util/hooks';
import { fetchGeofenceList } from '../../../api/common';
import * as column from '../../MainPages/util/grid/column';
import Control from 'react-leaflet-control';
import { fetchCongestionStatusApi } from '../../../api/statistics';
import moment from 'moment';
import SelectedCongestionSummary from './Component/SelectedCongestionSummary';
import AllCongestionSummary from './Component/AllCongestionSummary';

export const CongestionStatusContext = React.createContext();
export const CongestionStatusDispatchContext = React.createContext();

const defaultCongestionInfo = {
    totalAvgStayEntryTime: 0,
    totalAvgStayUniqueTime: 0,
    totalPermittedEntryCount: 0,
    totalPermittedUniqueCount: 0,
    totalRestrictedEntryCount: 0,
    totalRestrictedUniqueCount: 0,
};

const defaultSelectedCongestionInfo = {
    avgStayEntryTime: 0,
    avgStayUniqueTime: 0,
    entryCount: 0,
    uniqueCount: 0,
};

const CongestionStatus = ({ children, widgetInfo, ...restProps }) => {
    const { t } = useTranslation();
    const [showSearchArea, setShowSearchArea] = useState(false);
    const [selectedGeofence, setSelectedGeofence] = useState({});
    const [selectedGeofenceCongestionInfo, setSelectedGeofenceCongestionInfo] = useState({ ...defaultCongestionInfo });
    const [state, dispatch] = useReducer(congestionStatusReducer, initialState);
    const { floorList } = useSelector(state => state.FloorInfo);
    const { selectedCategory, selectedFloor, selectedFloorInfo, startDate, endDate } = state;
    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const [filterParam, setFilterParam] = useState({});
    const [congestionInfo, setCongestionInfo] = useState([]);
    const { promise: getGeofenceList } = useAsync({
        promise: fetchGeofenceList,
        resolve: res => {
            if (res) {
                setGeofenceInfo(
                    res.rows.map(geofence => {
                        return {
                            ...geofence,
                            bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])],
                        };
                    }),
                );
            }
        },
    });

    const { promise: getCongestionStatus } = useAsync({
        promise: fetchCongestionStatusApi,
        resolve: res => {
            setCongestionInfo(res);
        },
    });

    const columns = useColumns([
        column.geofenceName(),
        column.countOfEntries(),
        column.countOfUnique(),
        column.averageStayOfEntry(),
        column.averageStayOfUnique(),
    ]);

    const handleSearchAreaClick = () => {
        setShowSearchArea(!showSearchArea);
    };
    const handleRefreshClick = () => {
        dispatch(setSelectedCategory(''));
        dispatch(setSelectedFloor(floorList[0] ? floorList[0].floorId : ''));
        dispatch(setStartDate(moment().startOf('day').valueOf()));
        dispatch(setEndDate(moment().startOf('day').add(1, 'days').valueOf()));
        dispatch(setSelectedFloorInfo(floorList[0] ? floorList[0] : {}));
    };

    const handleSearchClick = e => {
        if (selectedFloor && startDate && endDate) {
            getCongestionStatus({
                ...filterParam,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        }
        setShowSearchArea(false);
    };

    useEffect(() => {
        if (floorList[0]) {
            dispatch(setSelectedFloorInfo(floorList[0]));
            getGeofenceList({ floor: floorList[0].floorId });
        }
    }, []);

    useEffect(() => {
        if (selectedFloorInfo.floorName) {
            getCongestionStatus({
                floorId: selectedFloorInfo.floorId,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        }
    }, [selectedFloorInfo]);

    useEffect(() => {
        if (selectedFloor) {
            getCongestionStatus({
                ...filterParam,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
            getGeofenceList({ floor: selectedFloor });
            setSelectedGeofenceCongestionInfo({});
        }
    }, [filterParam]);

    useEffect(() => {
        const paramInfo = {};
        if (selectedCategory) {
            paramInfo.categoryCode = selectedCategory;
        }
        if (selectedFloor) {
            paramInfo.floorId = selectedFloor;
        }
        setFilterParam(paramInfo);
    }, [selectedCategory, selectedFloor]);

    useEffect(() => {
        let foundOne = {};

        if (selectedGeofence.fcNum) {
            foundOne = congestionInfo.geofenceList.find(geofence => geofence.fcNum === selectedGeofence.fcNum);
            if (foundOne) {
                setSelectedGeofenceCongestionInfo(foundOne);
            } else {
                setSelectedGeofenceCongestionInfo({ fcNum: selectedGeofence.fcNum, ...defaultSelectedCongestionInfo });
            }
        } else {
            setSelectedGeofenceCongestionInfo({});
        }
    }, [selectedGeofence]);

    return (
        <CongestionStatusDispatchContext.Provider value={dispatch}>
            <CongestionStatusContext.Provider value={state}>
                <GridLayoutCard
                    widgetInfo={widgetInfo}
                    {...restProps}
                    headerAction={
                        <>
                            <Button
                                className="btn-danger btn-icon-only"
                                iconClassName={'icon-refresh'}
                                onClick={handleRefreshClick}
                            />
                            <div className="pnt-border border-h" />
                            <Button className={'btn-secondary'} onClick={handleSearchAreaClick}>
                                {t('Button;Search')}
                            </Button>
                        </>
                    }
                    searchFilter={showSearchArea && <CongestionFilterGroup handleSearchClick={handleSearchClick} />}
                >
                    <div className={'d-grid h-100'} style={{ gridTemplateRows: 'auto 230px 250px', rowGap: '10px' }}>
                        <div className={'h-100'}>
                            <Map>
                                {selectedFloorInfo && selectedFloorInfo.floorName && (
                                    <Control position="topleft" className={'control-container'}>
                                        <div className={'item-container'}>
                                            <span className={'map-text'}>{selectedFloorInfo.floorName}</span>
                                        </div>
                                    </Control>
                                )}
                                {selectedFloorInfo.imgURL && (
                                    <RotatedImageOverlay
                                        key={selectedFloorInfo.floorId}
                                        url={selectedFloorInfo.imgURL}
                                        deg={selectedFloorInfo.deg}
                                        bounds={selectedFloorInfo.bounds}
                                        onLoad={e => {
                                            const { target: layer } = e;
                                            layer._map.fitBounds(layer.getBounds());
                                        }}
                                    />
                                )}
                                {geofenceInfo.length > 0 && (
                                    <GeofenceLayer
                                        selectedGeofence={selectedGeofence}
                                        geofenceList={geofenceInfo}
                                        handleClick={geofence => {
                                            geofence.fcNum === selectedGeofence.fcNum
                                                ? setSelectedGeofence({})
                                                : setSelectedGeofence(geofence);
                                        }}
                                    />
                                )}
                            </Map>
                        </div>
                        <Card
                            className={'mt-3'}
                            header={{
                                title: `${t('CongestionStatus;Selected geofence summary')} ${
                                    selectedGeofence && selectedGeofence.fcName ? ' - ' + selectedGeofence.fcName : ''
                                }`,
                            }}
                        >
                            {selectedGeofenceCongestionInfo.fcNum ? (
                                <SelectedCongestionSummary selectedCongestionInfo={selectedGeofenceCongestionInfo} />
                            ) : (
                                <AllCongestionSummary congestionInfo={congestionInfo} />
                            )}
                        </Card>
                        <div>
                            <Table
                                paging={false}
                                data={{
                                    rows: congestionInfo.geofenceList ? congestionInfo.geofenceList : [],
                                }}
                                columns={columns}
                            />
                        </div>
                    </div>

                    {children}
                </GridLayoutCard>
            </CongestionStatusContext.Provider>
        </CongestionStatusDispatchContext.Provider>
    );
};

export default CongestionStatus;
