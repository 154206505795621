import React, { useState, useMemo, useEffect, useRef } from 'react';
import FilterSearchGroup from 'Components/MainPages/Components/FilterSearchGroup';
import {
    InputGroup,
    InputWrap,
    SearchWrap,
    SelectGroup,
} from 'Components/MainPages/Components/FilterSearchGroup/Components/Part';
import SearchGroup from 'Components/MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import { Select as ReactSelect, Button, TextInput } from '@components';
import SearchButtonArea from '../../../Components/FilterSearchGroup/Components/SearchButtonArea';
import { useTranslation } from '@hooks';
import cx from 'classnames';

const Search = ({ getWorkerStatus, handleWorkerStatus }) => {
    const t = useTranslation('WorkerStatusColumn');
    const overviewT = useTranslation('Overview');

    const sealedOptions = useMemo(() => {
        return [
            { value: 'all', label: t('Whole Area') },
            { value: 'sealed', label: t('Enclosed Area') },
        ];
    }, [t]);

    const dangerStatusOptions = useMemo(() => {
        return [
            { value: 'all', label: overviewT('All') },
            { value: 'normal', label: overviewT('Normal') },
            { value: 'sos', label: overviewT('SOS') },
            { value: 'mandown', label: overviewT('Man Down') },
        ];
    }, [overviewT]);

    const searchOptions = useMemo(() => {
        return [
            { value: 'all', label: t('All') },
            { value: 'entryStatus', label: t('Access Status') },
            { value: 'targetName', label: t('Name') },
            { value: 'companyName', label: t('Company Name') },
        ];
    }, [t]);

    const [searchValues, setSearchValues] = useState({
        sealedValue: sealedOptions[0],
        dangerStatus: dangerStatusOptions[0],
        searchValue: searchOptions[0],
        inputValue: '',
    });
    const { searchValue, sealedValue, inputValue, dangerStatus } = searchValues;

    useEffect(() => {
        if (searchValues.searchValue.value === 'all') {
            setSearchValues(prev => {
                return {
                    ...prev,
                    inputValue: '',
                };
            });
        }
    }, [searchValues.searchValue]);

    const handleSelect = (value, type) => {
        setSearchValues(prevState => {
            return {
                ...prevState,
                [type]: value,
            };
        });
    };

    const handleInputChange = ({ target: { value } }) => {
        setSearchValues(prevState => {
            return {
                ...prevState,
                inputValue: value,
            };
        });
    };

    const handleSearch = () => {
        const result = {
            inEnclosedYn: sealedValue.value === 'all' ? null : 'Y',
            dangerStatus: dangerStatus.value === 'all' ? null : dangerStatus.value,
            keyword: inputValue ? inputValue : null,
            opt: searchValue.value === 'all' ? null : searchValue.value,
        };
        getWorkerStatus(result);
        handleWorkerStatus(result);
    };

    return (
        <FilterSearchGroup label={t('Search', 'Search')}>
            <SearchGroup className={'bg-brand'} label={t('Search', 'Search')}>
                {/* 작업 구역 */}
                <ReactSelect
                    value={sealedValue}
                    options={sealedOptions}
                    onChange={value => handleSelect(value, 'sealedValue')}
                />
                {/* 위험 상태 */}
                <ReactSelect
                    value={dangerStatus}
                    options={dangerStatusOptions}
                    onChange={value => handleSelect(value, 'dangerStatus')}
                />

                <SearchWrap>
                    <SelectGroup>
                        <ReactSelect
                            value={searchValue}
                            options={searchOptions}
                            onChange={value => handleSelect(value, 'searchValue')}
                            customControlStyles={{ width: '100%' }}
                            customMenuStyles={{ width: '100%' }}
                            customOptionStyles={{ width: '100%' }}
                        />
                    </SelectGroup>
                    <InputWrap>
                        <InputGroup>
                            <TextInput
                                name="input"
                                value={inputValue}
                                inputGroupClassName={cx(searchValues.searchValue.value === 'all' && 'form-disable')}
                                placeholder={t('Enter Search Condition', 'Search')}
                                handleChange={handleInputChange}
                            />
                        </InputGroup>
                    </InputWrap>
                </SearchWrap>
                <SearchButtonArea>
                    <Button className="btn-brand btn-icon" iconClassName="icon-search" onClick={handleSearch}>
                        {t('Search', 'Search')}
                    </Button>
                </SearchButtonArea>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
