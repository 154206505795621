import React, { useEffect, useState, useContext } from 'react';
import { FeatureGroup, Polygon, Tooltip } from 'react-leaflet';
import cx from 'classnames';

import { WorkStatusDispatchContext } from '../../../../index';
import { setSelectedFcNum } from '../../../../workerStatusReducer';

import WORKER from 'assets/images/worker.png';
import SOS from 'assets/images/emergencySOS.svg';
import MAN_DOWN from 'assets/images/man_down.png';

const WorkerLabel = ({ count }) => {
    return count ? (
        <div
            className="font-weight-bold d-flex align-items-center justify-content-center geofence-font-size"
            style={{ margin: '-10px 0 -15px 0' }}
        >
            <img src={WORKER} alt="worker" style={{ height: '45px', width: '45px', marginRight: '2px' }} />
            <div className="color-black">{count}</div>
        </div>
    ) : null;
};

const WarningLabel = ({ sosCount, manDownCount }) => {
    return sosCount || manDownCount ? (
        <div className="d-flex font-weight-bold align-items-center geofence-font-size">
            <img src={SOS} alt="SOS" style={{ height: '30px', width: '30px', marginRight: '2px' }} />
            <div className={cx(sosCount ? 'color-danger' : 'color-black')}>{sosCount}</div>/
            <img src={MAN_DOWN} alt="man down" style={{ height: '45px', width: '45px', marginRight: '2px' }} />
            <div className={cx(manDownCount ? 'color-danger' : 'color-black')}>{manDownCount}</div>
        </div>
    ) : null;
};

const GeofenceLayer = ({ geofenceList, selectedGeofence = {}, showGeofenceDraw = false }) => {
    const [geofence, setGeofence] = useState(geofenceList);

    const dispatch = useContext(WorkStatusDispatchContext);

    useEffect(() => {
        if (showGeofenceDraw && selectedGeofence.fcNum) {
            setGeofence(prev => prev.filter(geofence => geofence.fcNum !== selectedGeofence.fcNum));
        } else {
            setGeofence(geofenceList);
        }
    }, [showGeofenceDraw, geofenceList]);

    return (
        <FeatureGroup>
            {geofence.length
                ? geofence.map(geofence => {
                      const {
                          fcNum,
                          fcName,
                          geofenceLatLngs,
                          workerCount,
                          mandownCount,
                          sosCount,
                          closedZone,
                      } = geofence;
                      return (
                          geofenceLatLngs.length > 0 && (
                              <Polygon
                                  className={cx(closedZone === 'Y' && 'sealed-geofence')}
                                  key={fcNum}
                                  positions={geofenceLatLngs.map(v => {
                                      return [v.lat, v.lng];
                                  })}
                                  onClick={() => {
                                      dispatch(setSelectedFcNum(fcNum));
                                  }}
                              >
                                  <Tooltip
                                      key={`tooltip-${fcNum}`}
                                      permanent={true}
                                      interactive={true}
                                      direction={'center'}
                                      className={'geofence-name-label'}
                                  >
                                      <div className={'map-status-box'}>
                                          <div className="geofence-name color-black">{fcName}</div>
                                          <WorkerLabel count={workerCount} />
                                          <WarningLabel sosCount={sosCount} manDownCount={mandownCount} />
                                      </div>
                                  </Tooltip>
                              </Polygon>
                          )
                      );
                  })
                : null}
        </FeatureGroup>
    );
};
export default GeofenceLayer;
