import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import cx from 'classnames';

import UserBox from './Components/UserBox';
import HeaderDots from './Components/HeaderDots';

import HeaderLogo from '../AppLogo';
import { CustomNav, SettingsNav } from '../AppNav/NavItems';

import useTranslation from '../../../util/hooks/useTranslation';

const initialGnbMenu = { menuName: '', subMenuName: '' };

const Header = () => {
    const t = useTranslation('Menu');
    const location = useLocation();
    const match = useRouteMatch(['/dashboards/:menuNum([0-9]+)', '/dashboards/edit/:menuNum([0-9]+)']);
    const realtimeLocationTracingMatch = useRouteMatch('/worker/status/:targetNum([0-9]+)');
    const realtimeCoalStorageMatch = useRouteMatch('/dashboard/status/:targetNum([0-9]+)');
    const filterMatch = useRouteMatch(['/filter/:menuNum([0-9]+)', '/filter/create']);
    const menuRightsMatch = useRouteMatch('/setting/menu');
    const [gnbMenu, setGnbMenu] = useState(initialGnbMenu);
    const { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow } = useSelector(
        state => state.ThemeOptions,
    );
    const { bookmarkedMenu } = useSelector(state => state.CustomMenu);

    useEffect(() => {
        let pathname = location.pathname;
        if (pathname.includes('/dashboards/edit/')) {
            pathname = pathname.replace('/edit', '');
        }

        if (filterMatch) {
            setGnbMenu({
                menuName: 'Filters',
                subMenuName: 'Filter Detail',
            });
        } else if (menuRightsMatch) {
            setGnbMenu({
                menuName: 'Settings',
                subMenuName: SettingsNav.find(v => v.id === 'menuRights').label,
            });
        } else if (realtimeLocationTracingMatch) {
            setGnbMenu({
                menuName: 'MN_WS01_WORK_REPORT',
                subMenuName: 'BF_WS01_WORKER_REALTIME',
            });
        } else if (realtimeCoalStorageMatch) {
            setGnbMenu({
                menuName: 'Dashboards',
                subMenuName: 'Real-time indoor coal storage site status',
            });
        } else {
            let foundGnbMenu = null;
            if (match && match.params.menuNum) {
                const menuNum = Number(match.params.menuNum);
                bookmarkedMenu.forEach(menu => {
                    if (menu.menuNum === menuNum) {
                        foundGnbMenu = menu;
                        setGnbMenu({
                            menuName: 'Dashboards',
                            subMenuName: menu.menuName,
                            isCustom: true,
                        });
                        return false;
                    }
                });
            } else {
                CustomNav.forEach(menu => {
                    if (menu.content) {
                        const subMenu = menu.content;
                        const foundMenu = subMenu.find(menu => menu.to === `#${pathname}`);
                        if (foundMenu) {
                            foundGnbMenu = foundMenu;
                            setGnbMenu({
                                menuName: menu.label,
                                subMenuName: foundMenu.label,
                                isCustom:
                                    foundMenu.id &&
                                    (foundMenu.id.includes('customDashboard_') || foundMenu.id.includes('filterMenu_')),
                            });
                            return false;
                        }
                    }
                });
            }
            if (!foundGnbMenu) {
                setGnbMenu(initialGnbMenu);
            }
        }
    }, [location, CustomNav, bookmarkedMenu]);

    return (
        <ReactCSSTransitionGroup
            component="div"
            className={cx('app-header', headerBackgroundColor, { 'header-shadow': enableHeaderShadow })}
            transitionName="HeaderAnimation"
            transitionAppear={true}
            transitionAppearTimeout={1500}
            transitionEnter={false}
            transitionLeave={false}
        >
            <HeaderLogo />

            <div className={cx('app-header__content', { 'header-mobile-open': enableMobileMenuSmall })}>
                <div className="app-header-left">
                    {/*<SearchBox />*/}
                    {/*<MegaMenu />*/}
                    <span className={'menu-sub'}>{gnbMenu.menuName ? t(gnbMenu.menuName) : ''}</span>
                    {gnbMenu.menuName ? <span className="icon-next icon-small" /> : ''}
                    <span className={'menu-main'}>
                        {gnbMenu.subMenuName && (gnbMenu.isCustom ? gnbMenu.subMenuName : t(gnbMenu.subMenuName))}
                    </span>
                </div>
                <div className="app-header-right">
                    {/*<Weather />*/}
                    <HeaderDots />
                    <UserBox />
                    {/*<HeaderRightDrawer />*/}
                </div>
            </div>
        </ReactCSSTransitionGroup>
    );
};

export default Header;
