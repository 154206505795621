import React, { useState } from 'react';
import moment from 'moment';

import { Button, Card, Page } from '@components';
import { useAsync, useColumns, useTranslation } from '@hooks';
import Search from './Components/Search';
import ActionModal from 'Components/MainPages/Components/ActionModal';
import { fetchAlertInfo } from '@api/alert';
import { createAlertDescription } from '@api/smartSafeWorker';
import Table from '../../WorkerSafetyDashboard/Components/Table';

import * as column from 'Components/MainPages/util/grid/columns';
import { nullToHyphen, nullishToHyphen } from '../../WorkerSafetyDashboard/Components/utils';
import cx from 'classnames';

const PAGE_SIZE = 20;

const convertToTimeFormat = value => {
    return value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '-';
};

const AlarmLog = () => {
    const t = useTranslation('Vital Signs');
    const columnT = useTranslation('WorkerStatusColumn');

    const [statusLogData, setStatusLogData] = useState({
        totalCount: 0,
        totalPage: 1,
        rows: [],
    });

    const [tableKey, setTableKey] = useState(0);

    const changeTableKey = () => {
        setTableKey(prevState => prevState + 1);
    };

    const [trInfo, setTrInfo] = useState(null);

    const [actionModal, setActionModal] = useState(false);
    const toggleActionModal = () => {
        setActionModal(!actionModal);
    };

    const actionButtonClick = trData => {
        toggleActionModal();
        setTrInfo(trData);
    };

    const actionModalOkCallback = message => {
        postAlertDescription({
            logNum: trInfo.logNum,
            description: message,
        });
        toggleActionModal();
    };

    const { promise: getAlertNotificationLog, state: alarmListInfo } = useAsync({
        promise: fetchAlertInfo,
        fixedParam: { pageSize: PAGE_SIZE, isActive: 'Y' },
        resolve: res => {
            setStatusLogData(res);
        },
    });

    const { promise: postAlertDescription } = useAsync({
        promise: createAlertDescription,
        resolve: () => {
            getAlertNotificationLog({ ...alarmListInfo.request });
        },
    });

    const columns = useColumns(
        [
            column.dangerousStatus({
                accessor: 'interfaceCommandType',
                width: 100,
                Cell: ({ value }) => {
                    return columnT(value);
                },
                formatTitle: ({ value }) => {
                    return columnT(value);
                },
            }),
            column.addedOn({
                Header: 'Time of Occurrence',
                accessor: 'regDate',
                formatTitle: ({ value }) => {
                    return convertToTimeFormat(value);
                },
                Cell: ({ value }) => {
                    return convertToTimeFormat(value);
                },
                width: 140,
            }),
            column.status({
                width: 80,
                formatTitle: () => {
                    return columnT('In');
                },
                Cell: () => {
                    return columnT('In');
                },
            }),
            column.name({
                accessor: 'targetName',
                width: 80,
            }),
            column.companyName({
                Cell: ({ value }) => {
                    return nullToHyphen(value);
                },
            }),
            column.phone({
                Header: 'Phone Number',
                accessor: 'phoneNumber',
                width: 120,
            }),
            column.healthRating({
                width: 80,
                Cell: ({ value }) => {
                    return nullToHyphen(value);
                },
            }),
            column.timeOfEntry({
                width: 140,
                formatTitle: ({ value }) => {
                    return nullToHyphen(value);
                },
                Cell: ({ value }) => {
                    return nullToHyphen(value);
                },
                accessor: 'entryDatetime',
            }),
            column.workshop({
                Cell: ({ value }) => {
                    return nullToHyphen(value);
                },
            }),
            column.workday({
                width: 140,
                formatTitle: ({ value }) => {
                    return nullishToHyphen(value);
                },
                Cell: ({ value }) => {
                    return nullishToHyphen(value);
                },
            }),
            column.actionContents({
                className: 'py-6px',
                Cell: ({ value, row: { original } }) => {
                    return (
                        <Button
                            className={cx('height-1_5rem', value ? 'btn-secondary' : 'btn-brand')}
                            onClick={() => actionButtonClick(original)}
                        >
                            {value ? columnT('Action Completed') : columnT('Take Action')}
                        </Button>
                    );
                },
            }),
            column.timeOfAction({
                width: 140,
                formatTitle: ({ value }) => {
                    return convertToTimeFormat(value);
                },
                Cell: ({ value }) => {
                    return convertToTimeFormat(value);
                },
            }),
        ],
        columnT,
    );

    const handlePageChange = pageIndex => {
        if (alarmListInfo.request) {
            if (
                alarmListInfo.request.keyword ||
                alarmListInfo.request.opt ||
                alarmListInfo.request.startDate ||
                alarmListInfo.request.endDate
            ) {
                getAlertNotificationLog({ ...alarmListInfo.request, page: pageIndex });
            } else {
                getAlertNotificationLog({ page: pageIndex });
            }
        }
    };

    return (
        <Page menuPath={[t('MN_WS01_LOG_MANAGE', 'Menu'), t('BF_WS01_ALARM', 'Menu')]} className={'h-100'}>
            <Search getAlarmList={getAlertNotificationLog} changeTableKey={changeTableKey} />

            <Card
                className="report-container"
                header={{
                    title: t('Alarm Log List'),
                }}
            >
                <div style={{ height: 'calc(100vh - 25rem)', minHeight: '20rem' }}>
                    <Table
                        key={tableKey}
                        data={{ ...statusLogData, pageSize: PAGE_SIZE }}
                        columns={columns}
                        onPageChange={handlePageChange}
                        loading={alarmListInfo.isLoading}
                    />
                </div>
            </Card>
            <ActionModal
                data={trInfo}
                initModal={actionModal}
                okCallback={actionModalOkCallback}
                cancelCallback={toggleActionModal}
            />
        </Page>
    );
};

export default AlarmLog;
