import React, { useEffect, useContext } from 'react';
import cx from 'classnames';
import { Card as RSCard, CardBody } from 'reactstrap';

import { WorkStatusDispatchContext, WorkStatusContext } from '../../../../index';
import { setActiveTabId } from '../../../../workerStatusReducer';

const CustomTab = ({ tabs = [{}], styles = {} }) => {
    const dispatch = useContext(WorkStatusDispatchContext);
    const { activeTabId } = useContext(WorkStatusContext);

    useEffect(() => {
        dispatch(setActiveTabId(tabs[0].tabId));
    }, []);

    return (
        <RSCard>
            <div className={'card-tab tab-list1'}>
                <ul style={{ ...{ padding: '.5rem .5rem 0', ...styles.header } }}>
                    {tabs.map((data, index) => {
                        return (
                            <li
                                id={data.tabId}
                                className={cx(activeTabId === data.tabId && 'on', 'cursor-pointer')}
                                style={{ ...styles.headerItem }}
                                key={data.tabId + '_' + index}
                                onClick={e => {
                                    e.preventDefault();
                                    dispatch(setActiveTabId(e.target.id));
                                }}
                            >
                                <a
                                    href={'#!'}
                                    id={data.tabId}
                                    onClick={e => {
                                        e.preventDefault();
                                        dispatch(setActiveTabId(e.target.id));
                                    }}
                                >
                                    {data.title}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <CardBody>
                {tabs.map((data, index) => {
                    return (
                        <div
                            style={{ ...styles.body }}
                            key={data.tabId + '_' + index}
                            className={cx(activeTabId !== data.tabId && 'd-none', 'h-100')}
                        >
                            {data.content}
                        </div>
                    );
                })}
            </CardBody>
        </RSCard>
    );
};

export default CustomTab;
