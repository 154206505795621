import React from 'react';
import { Col, Row, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';
import ResponsivePieChart from '../../Common/Charts/PieChart';
import { fetchGeofenceCongestionStatus } from '../../../api/statistics';
import { useSettings } from '../util/useSettings';
import useMonitor from '../util/useMonitor';
import _ from 'lodash';
import GridLayoutCard from '../Components/GridLayoutCard';
import PerfectScrollbar from 'react-perfect-scrollbar';

const defaultData = {
    rows: [
        { fcName: 'Example1', enterCnt: _.random(19, 20) },
        { fcName: 'Example2', enterCnt: _.random(15, 17) },
        { fcName: 'Example3', enterCnt: _.random(13, 14) },
        { fcName: 'Example4', enterCnt: _.random(5, 7) },
        { fcName: 'Example5', enterCnt: _.random(2, 3) },
    ],
};

const GeofenceCongestionStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { t } = useTranslation();

    const { data } = useMonitor({
        config,
        defaultData,
        fetchData: fetchGeofenceCongestionStatus,
        makeData: ({ newData }) => {
            const geofenceCongestionList = newData.rows || [];
            let chartData = [];
            if (geofenceCongestionList && geofenceCongestionList.length > 0) {
                const length = geofenceCongestionList.length < 5 ? geofenceCongestionList.length : 5;
                for (let i = 0; i < length; i++) {
                    chartData.push({
                        key: geofenceCongestionList[i].fcName,
                        name: geofenceCongestionList[i].fcName,
                        value: Number(geofenceCongestionList[i].enterCnt),
                    });
                }
            }

            return chartData;
        },
    });

    return (
        <GridLayoutCard widgetInfo={widgetInfo} {...restProps}>
            <div className={'scrollbar-container'}>
                <PerfectScrollbar>
                    <Row style={{ height: '15rem' }}>
                        <ResponsivePieChart data={data} settings={settings} />
                    </Row>
                    <Row>
                        <ReactResizeDetector handleHeight>
                            <Col>
                                {t('GeofenceCongestionStatus;Today / Top 5')}
                                <Table className={'mb-0'}>
                                    <thead>
                                        <tr>
                                            <th className={'text-center'} style={{ width: '33.3%' }}>
                                                {t('GeofenceCongestionStatus;Ranking')}
                                            </th>
                                            <th className={'text-center'} style={{ width: '33.3%' }}>
                                                {t('GeofenceCongestionStatus;Geofence Name')}
                                            </th>
                                            <th className={'text-center'} style={{ width: '33.3%' }}>
                                                {t('GeofenceCongestionStatus;Number of Tags Entered')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((v, i) => (
                                            <tr key={v.key}>
                                                <td className={'text-center'} style={{ width: '33.3%' }}>
                                                    {i + 1}
                                                </td>
                                                <td className={'text-center'} style={{ width: '33.3%' }}>
                                                    {v.name || '-'}
                                                </td>
                                                <td className={'text-center'} style={{ width: '33.3%' }}>
                                                    {v.value || '-'}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </ReactResizeDetector>
                    </Row>
                </PerfectScrollbar>
            </div>
            {children}
        </GridLayoutCard>
    );
};

export default GeofenceCongestionStatus;
