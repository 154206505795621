import { Portal } from 'react-overlays';
import React, { useEffect, useRef } from 'react';
import { Route, Redirect, withRouter, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import AppFooter from './AppFooter';
import LocationPopup from './AppHeader/Components/LocationPopup';

import { getLsUserInfo, getUuid } from '@util/common/util';
import {
    setAdditionalInfo,
    setOauthInfo,
    setOauthStatusModal,
    logOut,
    setLanguage,
    setSmartSafeManagerInfo,
} from '@reducer/UserInfo';
import { useUserTokenCheck } from '@hooks/utilHooks';
import ConfirmModal from '../Common/ConfirmModal';
import AlertModal from '../MainPages/Components/AlertModal';
import WorkerSafetyDashboard from '../MainPages/WorkerSafetyDashboard';
import HyundaiReport from '../MainPages/WorkerSafetyReport';
import { ErrorHandleSwitch } from '../MainPages/Components/Router';

import { getApiURL } from '../../api';
import { fetchCompany } from '@api/login';
import { setMarkerConfig } from '@reducer/Common/AppConfig';
import { deleteNotification, setAlarmProcessed, setAlert, setNotification, setSosAlert } from '@reducer/Notification';
import { fetchCategory } from '@api/asset';
import { useAsync, useTranslation } from '../../util/hooks';
import { SOCKET_CONNECTING } from '@util/symbol/window';
import { setAllCategoryList, setCategoryPropertiesList } from '@reducer/Common/CategoryInfo';
import { fetchFloorList, getCategoryPropertiesListApi } from '@api/common';
import { setFloorList } from '@reducer/Common/FloorInfo';
import socketio from 'socket.io-client';
import { setSocket } from '@reducer/SocketInfo';
import alarmSound from '../../assets/audio/mecatecAlarm.mp3';
import { getWorkerApi } from '@api/smartSafeWorkManagement';
import useSocket from '../../util/socket/hooks/useSocket';
import cx from 'classnames';

const AppMain = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const audioRef = useRef(new Audio(alarmSound));

    const { userInfo, modal } = useSelector(state => state.UserInfo);
    const { fullScreen } = useSelector(state => state.DashboardFrame);
    const { notification } = useSelector(state => state.Notification);

    const t = useTranslation('Login');
    const appMainOuterRef = useRef();
    const portal = document.body;

    const { socket, setSocketEvent, closeSocket } = useSocket();

    const { promise: getMoreUserInfo } = useAsync({
        promise: fetchCompany,
        resolve: companyInfo => {
            dispatch(
                setMarkerConfig({
                    markerTransitionActive: companyInfo.markerTransitionActive,
                    markerPulseActive: companyInfo.markerPulseActive,
                    markerBorderRadius: companyInfo.markerBorderRadius,
                    markerPulseAnimation: companyInfo.markerPulseAnimation,
                    markerPulseRssi: companyInfo.markerPulseRssi,
                    markerPulseColor: companyInfo.markerPulseColor,
                }),
            );
        },
    });

    // 화면 리프레쉬 후 유저의 정보가 사라졌을때
    // 로컬스토리지에 저장된 정보 호출 후 저장
    useEffect(() => {
        if (!userInfo.userName) {
            const { oAuthInfo, userInfo, smartSafeManagerInfo } = getLsUserInfo();
            dispatch(setOauthInfo(oAuthInfo));
            if (userInfo) {
                const { lang, ...restUserInfo } = userInfo;
                dispatch(setAdditionalInfo(restUserInfo));
                dispatch(setLanguage(lang));
            }
            if (smartSafeManagerInfo) {
                dispatch(setSmartSafeManagerInfo(smartSafeManagerInfo));
            }
            // dispatch(setAdditionalInfo(getLsUserInfo()));
        }
    }, [userInfo]);

    useEffect(() => {
        if (
            Object.keys(notification.MAN_DOWN_ON).length ||
            Object.keys(notification.SOS_ON).length ||
            Object.keys(notification.BIOSIGNAL_ABNORMALITY_ON).length
        ) {
            if (audioRef.current.paused) {
                audioRef.current.loop = true;
                audioRef.current.play();
            }
        } else {
            audioRef.current.pause();
        }

        return () => {
            audioRef.current.pause();
        };
    }, [notification]);

    useEffect(() => {
        if (socket) {
            // setSocketEvent('workerState', data => {
            //     const { stateGroupType, workerNum } = data;
            //
            //     if (
            //         stateGroupType === 'SOS' ||
            //         stateGroupType === 'MAN_DOWN' ||
            //         stateGroupType === 'BIOSIGNAL_ABNORMALITY'
            //     ) {
            //         getWorkerDetail({ workerNum, notificationData: data });
            //     }
            // });
            setSocketEvent('AlarmProcessed', data => {
                dispatch(setAlarmProcessed(data));
            });
            setSocketEvent('notification', data => {
                // console.log('noti', { data });
                // dispatch(setAlert(data));

                // 실시간 옥내저탄장 현황에서만 알람이 울리게 해달라는 요구
                if (location.pathname === '/dashboard/status') {
                    dispatch(setSosAlert(data.notificationLog));
                }
            });
        }
        return () => {
            // setSocketEvent('workerState');
            setSocketEvent('notification');
        };
    }, [socket, location]);

    const getWorkerDetail = async param => {
        const { notificationData, workerNum } = param;
        const { data: result } = await getWorkerApi({ workerNum });

        dispatch(
            setNotification({
                ...result.data,
                ...notificationData,
            }),
        );
    };

    // refresh token의 에러가 발생했을 시 토글 메뉴 실행 후 유저인포 초기화
    // 유저인포의 초기화로 인하여 로그인 페이지로 이동
    const toggleOauthStatus = () => {
        dispatch(logOut());
        dispatch(setOauthStatusModal(!modal.modalOauthStatusOpen));
    };

    const handleAlertClick = ({ workerNum, type }) => {
        dispatch(deleteNotification({ targetNum: workerNum, type }));
    };
    const handleTracingTargetButtonClick = ({ workerNum, type }) => {
        dispatch(deleteNotification({ targetNum: 'tracing', type }));
        history.push(`/dashboard/status/${workerNum}`);
        // history.push(`/worker/status/${workerNum}`);
    };

    useUserTokenCheck();

    useEffect(() => {
        getMoreUserInfo();
        if (!window[SOCKET_CONNECTING]) {
            window[SOCKET_CONNECTING] = true;
            getApiURL().then(({ wmsSocketUrl }) => {
                const uuid = getUuid();
                const ws = socketio(wmsSocketUrl, {
                    transports: ['websocket'],
                    forceNew: true,
                    reconnection: true,
                    reconnectionAttempts: 5,
                    reconnectionDelay: 5000,
                });
                ws.on('connect', function () {
                    console.log('SOCKET_CONNECTED : ', wmsSocketUrl);
                    ws.emit('join', `presence-${uuid}`);
                    ws.emit('join', `presence-ws01-${uuid}`);
                    dispatch(setSocket(ws));
                });
                ws.on('disconnect', function () {
                    console.log('SOCKET_DISCONNECT : ', wmsSocketUrl);
                    dispatch(setSocket(ws));
                });
                ws.on('reconnect', function () {
                    console.log('SOCKET_RECONNECTED : ', wmsSocketUrl);
                    ws.emit('join', `presence-${uuid}`);
                    ws.emit('join', `presence-ws01-${uuid}`);
                    dispatch(setSocket(ws));
                });
            });
        }

        return () => {
            closeSocket(socket);
        };
    }, []);

    // FloorList 받아서 Redux에 저장
    useAsync({
        promise: fetchFloorList,
        param: { isAll: 'Y' },
        resolve: response => {
            dispatch(setFloorList(response));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    // CategoryList 받아서 Redux에 저장
    useAsync({
        promise: fetchCategory,
        param: { isAll: 'Y' },
        resolve: ({ rows }) => {
            dispatch(setAllCategoryList(rows));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    useAsync({
        promise: getCategoryPropertiesListApi,
        param: { isAll: 'Y' },
        resolve: ({ rows }) => {
            dispatch(setCategoryPropertiesList(rows));
        },
        immediate: true,
        deps: [match.params.menuNum],
    });

    return (
        <>
            {/*<ThemeOptions />*/}
            <AppHeader />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer" ref={appMainOuterRef}>
                    <div className="app-main__inner">
                        <div className={cx('app-page-content', fullScreen && 'dashboard-fullscreen')}>
                            <ErrorHandleSwitch>
                                <Route
                                    exact
                                    path={'/'}
                                    render={() => {
                                        return <Redirect to={`/dashboard/monitor`} />;
                                    }}
                                />
                                {/* 현대제철 밀폐 */}
                                <Route path={'/dashboard'} component={WorkerSafetyDashboard} />
                                <Route path={'/report'} component={HyundaiReport} />
                            </ErrorHandleSwitch>

                            <ConfirmModal
                                initModal={modal.modalOauthStatusOpen}
                                toggleModal={toggleOauthStatus}
                                header={{ title: t('Authentication Error') }}
                                confirmText={
                                    <span>
                                        {t('The authentication information is incorrect. Please log in again.')}
                                    </span>
                                }
                                okCallback={toggleOauthStatus}
                                removeCancel={true}
                            />
                            <Portal container={portal}>
                                <LocationPopup appMainOuterRef={appMainOuterRef} />
                            </Portal>
                        </div>
                    </div>
                    <AppFooter />
                </div>
            </div>
            <ToastContainer />

            {!!Object.keys(notification.SOS_ON).length &&
                Object.values(notification.SOS_ON).map(data => {
                    if (data[0]) {
                        const worker = data[0];
                        return (
                            <AlertModal
                                key={worker.workerNum}
                                data={worker}
                                initModal={true}
                                okCallback={handleAlertClick}
                                callbackParam={worker}
                                cancelCallback={handleTracingTargetButtonClick}
                                cancelCallbackParam={worker}
                            />
                        );
                    }
                })}
            {!!Object.keys(notification.MAN_DOWN_ON).length &&
                Object.values(notification.MAN_DOWN_ON).map(data => {
                    if (data[0]) {
                        const worker = data[0];
                        return (
                            <AlertModal
                                key={worker.workerNum}
                                data={worker}
                                initModal={true}
                                okCallback={handleAlertClick}
                                callbackParam={worker}
                                cancelCallback={handleTracingTargetButtonClick}
                                cancelCallbackParam={worker}
                            />
                        );
                    }
                })}
            {!!Object.keys(notification.BIOSIGNAL_ABNORMALITY_ON).length &&
                Object.values(notification.BIOSIGNAL_ABNORMALITY_ON).map(data => {
                    if (data[0]) {
                        const worker = data[0];
                        return (
                            <AlertModal
                                key={worker.workerNum}
                                data={worker}
                                initModal={true}
                                okCallback={handleAlertClick}
                                callbackParam={worker}
                                cancelCallback={handleTracingTargetButtonClick}
                                cancelCallbackParam={worker}
                            />
                        );
                    }
                })}
            {/*<ToastAlert />*/}
            <div id={'selectContainer'} />
        </>
    );
};

export default withRouter(AppMain);
