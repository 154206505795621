import React, { useEffect, useReducer, useState } from 'react';
import GridLayoutCard from '../Components/GridLayoutCard';
import Button from '../../Common/Button';
import { useTranslation } from 'react-i18next';
import congestedGeofenceReducer, { initialState } from './congestedGeofenceReducer';
import CongestedGeofenceFilterGroup from './Component/TopFiveCongestedGeofenceFilterGroup';
import { setEndDate, setSelectedCategory, setSelectedFloor, setStartDate } from './congestedGeofenceReducer';
import useAsync from '../../../util/hooks/useAsync';
import CategoryBarChart from './Component/CategoryBarChart';
import PermissionBarChart from './Component/PermissionBarChart';
import { fetchMostCongestedGeofenceApi } from '../../../api/statistics';
import { useSelector } from 'react-redux';
import moment from 'moment';

export const CongestedGeofenceContext = React.createContext();
export const CongestedGeofenceDispatchContext = React.createContext();

const geofenceFakeDataByPermission = [
    {
        fcName: '회의실',
        fcNum: 10244,
        permitted: 33,
        restricted: 5,
    },
    {
        fcName: '대회의실',
        fcNum: 10240,
        permitted: 20,
        restricted: 5,
    },
    {
        fcName: '프론트엔드',
        fcNum: 10244,
        permitted: 15,
        restricted: 5,
    },
    {
        fcName: '백엔드',
        fcNum: 10244,
        permitted: 9,
        restricted: 5,
    },
];

const geofenceFakeDataByCategory = [
    {
        fcName: '회의실',
        fcNum: 10244,
        categoryInfo: [
            {
                categoryName: 'PEOPLE',
                categoryCount: 16,
            },
            {
                categoryName: 'ASSET',
                categoryCount: 10,
            },
            {
                categoryName: 'SENSOR',
                categoryCount: 5,
            },
            {
                categoryName: 'EUNJI',
                categoryCount: 1,
            },
        ],
    },
    {
        fcName: '대회의실',
        fcNum: 10244,
        categoryInfo: [
            {
                categoryName: 'PEOPLE',
                categoryCount: 15,
            },
            {
                categoryName: 'ASSET',
                categoryCount: 11,
            },
            {
                categoryName: 'SENSOR',
                categoryCount: 2,
            },
        ],
    },
    {
        fcName: '화장실',
        fcNum: 10244,
        categoryInfo: [
            {
                categoryName: 'PEOPLE',
                categoryCount: 10,
            },
            {
                categoryName: 'ASSET',
                categoryCount: 8,
            },
            {
                categoryName: 'SENSOR',
                categoryCount: 1,
            },
        ],
    },
];

const TopFiveCongestedGeofence = ({ children, widgetInfo, ...restProps }) => {
    const { t } = useTranslation();
    const { defaultSettings, settings } = widgetInfo.config;
    const [showSearchArea, setShowSearchArea] = useState(false);
    const [congestedGeofenceInfo, setCongestedGeofenceInfo] = useState([]);
    const [option, setOption] = useState('category');
    const [filterParam, setFilterParam] = useState({});
    const [state, dispatch] = useReducer(congestedGeofenceReducer, initialState);
    const { floorList } = useSelector(state => state.FloorInfo);
    const { mode } = useSelector(state => state.DashboardFrame);
    const { selectedCategory, selectedFloor, startDate, endDate } = state;

    const handleRefreshClick = e => {
        dispatch(setSelectedCategory(''));
        dispatch(setSelectedFloor(floorList[0] ? floorList[0].floorId : ''));
        dispatch(setStartDate(moment().startOf('day').valueOf()));
        dispatch(setEndDate(moment().startOf('day').add(1, 'days').valueOf()));
    };

    const handleSearchAreaClick = e => {
        setShowSearchArea(!showSearchArea);
    };

    const handleSearchClick = e => {
        if (selectedFloor) {
            getCongestedGeofence({
                ...filterParam,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        }
        setShowSearchArea(false);
    };

    const { promise: getCongestedGeofence } = useAsync({
        promise: fetchMostCongestedGeofenceApi,
        fixedParam: { opt: settings && settings.type ? settings.type : defaultSettings.type },
        resolve: res => {
            setCongestedGeofenceInfo(res.rows);
        },
    });

    useEffect(() => {
        if (settings && settings.type) {
            setOption(settings.type);
        } else {
            setOption(defaultSettings.type);
        }
    }, [widgetInfo]);

    useEffect(() => {
        if (option && floorList.length > 0) {
            getCongestedGeofence({
                floorId: floorList[0].floorId,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        }
    }, [option]);

    useEffect(() => {
        if (selectedFloor) {
            getCongestedGeofence({
                ...filterParam,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        }
    }, [filterParam]);

    useEffect(() => {
        const paramInfo = {};
        if (selectedCategory) {
            paramInfo.categoryCode = selectedCategory;
        }
        if (selectedFloor) {
            paramInfo.floorId = selectedFloor;
        }
        setFilterParam(paramInfo);
    }, [selectedCategory, selectedFloor]);

    return (
        <CongestedGeofenceDispatchContext.Provider value={dispatch}>
            <CongestedGeofenceContext.Provider value={state}>
                <GridLayoutCard
                    widgetInfo={widgetInfo}
                    {...restProps}
                    headerAction={
                        <>
                            <Button
                                className="btn-danger btn-icon-only"
                                iconClassName={'icon-refresh'}
                                onClick={handleRefreshClick}
                            />
                            <div className="pnt-border border-h" />
                            <Button className={'btn-secondary'} onClick={handleSearchAreaClick}>
                                {t('Button;Search')}
                            </Button>
                        </>
                    }
                    subTitle={
                        settings && settings.type
                            ? t(`TopFiveCongestedGeofence;${settings.type}`)
                            : t(`TopFiveCongestedGeofence;${defaultSettings.type}`)
                    }
                    searchFilter={
                        showSearchArea && <CongestedGeofenceFilterGroup handleSearchClick={handleSearchClick} />
                    }
                >
                    {option === 'category' ? (
                        <CategoryBarChart
                            congestedGeofenceInfo={mode === 'edit' ? geofenceFakeDataByCategory : congestedGeofenceInfo}
                        />
                    ) : (
                        <PermissionBarChart
                            congestedGeofenceInfo={
                                mode === 'edit' ? geofenceFakeDataByPermission : congestedGeofenceInfo
                            }
                        />
                    )}
                    {children}
                </GridLayoutCard>
            </CongestedGeofenceContext.Provider>
        </CongestedGeofenceDispatchContext.Provider>
    );
};

export default TopFiveCongestedGeofence;
